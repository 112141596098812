// react
import { MouseEvent, MouseEventHandler, ReactNode, useContext, useState } from 'react';

// types
import { CountryCode } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';
import { supportedLngs } from '../../../i18n';

// router
import { NavLink, Link as RouterLink } from 'react-router-dom';

// mui
import {
	ClassNameMap,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';

// ui
import { Flag } from '../Flag/Flag';

// icons
import {
	ChevronLeft as IconChevronLeft,
	ChevronRight as IconChevronRight,
	ClearOutlined as IconClear,
	FolderOutlined as IconFolder,
	TranslateOutlined as IconTranslate,
	ContactSupportOutlined as IconSupport,
} from '@mui/icons-material';

// img
import { LogoFundsChainAlt } from '../../img/LogoFundsChainAlt';
import { LogoFundsChainMark } from '../../img/LogoFundsChainMark';

// styles
import useStyles from './Nav.styles';

// context
import { ContextUser } from '../../../App.context';

// props
export interface NavItem {
	className?: string;
	icon: ReactNode;
	id?: string;
	label: string;
	onClick?: MouseEventHandler;
	target?: string;
	to?: string;
}

export interface NavProps {
	isOpen?: boolean;
	onClose?: MouseEventHandler;
}

export const Nav = ({ isOpen, onClose }: NavProps) => {
	// hooks
	const styles: ClassNameMap = useStyles();
	const { i18n, t } = useTranslation();

	// state
	const [anchorLang, setAnchorLang] = useState<null | HTMLElement>(null);
	const [isExpanded, setIsExpanded] = useState(false);

	// context
	const { user } = useContext(ContextUser);

	// vars
	const classes = [styles.nav];
	const classesDrawer = [styles.drawer];
	const classesLogoWrapper = [styles.logoWrapper];
	const classesOverlay = [styles.overlay];

	let guideFileName = null;

	// check user role
	if (user?.entity) {
		guideFileName = 'fundschain-user-guide-piu.pdf';
	} else if (user?.role === 'WFA') {
		guideFileName = 'fundschain-user-guide-wfa.pdf';
	} else if (user?.role === 'STAFF') {
		guideFileName = 'fundschain-user-guide-staff.pdf';
	}

	const handleClickNavItem = (e: MouseEvent<HTMLButtonElement>) => {
		setIsExpanded(false);

		if (onClose) {
			onClose(e);
		}
	};

	const handleClickLang = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchorLang(e.currentTarget);
	};

	const handleChangeLang = (e: MouseEvent<HTMLLIElement>, lang: string) => {
		// change lang
		i18n.changeLanguage(lang);

		// close lang menu
		setAnchorLang(null);

		// close nav
		if (onClose) {
			onClose(e);
		}
	};

	const items = [
		{
			icon: <IconFolder />,
			label: t('projects'),
			to: '/projects',
		},
	];

	const itemsFooter: Array<NavItem> = [
		{
			className: styles.btnItemToggle,
			icon: isExpanded ? <IconChevronLeft /> : <IconChevronRight />,
			label: isExpanded ? t('collapse') : t('expand'),
			onClick: () => {
				setIsExpanded(!isExpanded);
			},
		},
	];

	// add user guide nav item
	if (guideFileName) {
		itemsFooter.push({
			icon: <IconSupport />,
			id: 'btn-guide',
			label: t(`userGuide`),
			target: '_blank',
			to: `${process.env.PUBLIC_URL}/guides/${guideFileName}`,
		});
	}

	// add language nav item
	itemsFooter.push({
		icon: <IconTranslate />,
		id: 'btn-language',
		label: t(`languages.${i18n.language}`),
		onClick: handleClickLang,
	});

	const languageFlags: Record<string, CountryCode> = {
		en: 'US',
		es: 'ES',
		fil: 'PH',
		bn: 'BD',
		ja: 'JP',
		ro: 'MD',
		sw: 'KE',
	};

	const renderItems = (items: NavItem[]) => {
		return items.map((item, i) => {
			const { className, icon, label, ...itemProps } = item;
			const classesBtnItem = [styles.btnItem];
			const classesBtnItemText = [styles.btnItemText];

			// eslint-disable-next-line
			let Component: any = 'button';

			if (className) {
				classesBtnItem.push(className);
			}

			if (isExpanded) {
				classesBtnItem.push(styles.btnItemExpanded);
				classesBtnItemText.push(styles.btnItemTextExpanded);
			}

			if (item.to) {
				Component = NavLink;
			}

			const btn = (
				<Component
					className={classesBtnItem.join(' ')}
					key={`nav-item-${i}`}
					onClick={(e: MouseEvent<HTMLButtonElement>) => handleClickNavItem(e)}
					{...itemProps}>
					{icon}
					<Typography className={classesBtnItemText.join(' ')} component="div" fontWeight={600}>
						{label}
					</Typography>
				</Component>
			);

			if (!isExpanded) {
				return (
					<Tooltip key={`nav-item-tooltip-${i}`} placement="right" title={label}>
						{btn}
					</Tooltip>
				);
			}

			return btn;
		});
	};

	if (isExpanded) {
		classesDrawer.push(styles.drawerExpanded);
		classesLogoWrapper.push(styles.logoWrapperExpanded);
		classesOverlay.push(styles.overlayExpanded);
	}

	if (isOpen) {
		classesDrawer.push(styles.drawerOpen);
	}

	return (
		<div className={classes.join(' ')}>
			<div className={classesOverlay.join(' ')} onClick={() => setIsExpanded(false)} />
			<div className={classesDrawer.join(' ')}>
				<RouterLink className={classesLogoWrapper.join(' ')} to="/">
					{isExpanded ? (
						<LogoFundsChainAlt className={styles.logo} />
					) : (
						<LogoFundsChainMark className={styles.logomark} />
					)}
				</RouterLink>

				<nav className={styles.list}>
					<div className={styles.sublist}>{renderItems(items)}</div>
					<div className={styles.sublist}>{renderItems(itemsFooter)}</div>

					<Menu
						anchorEl={anchorLang}
						id="menu-language"
						MenuListProps={{
							'aria-labelledby': 'btn-language',
						}}
						onClose={() => setAnchorLang(null)}
						open={Boolean(anchorLang)}>
						{supportedLngs.map((lang) => {
							return (
								<MenuItem
									key={`lang-${lang}`}
									onClick={(e) => {
										handleChangeLang(e, lang);
									}}>
									<ListItemIcon>
										<Flag country={languageFlags[lang]} />
									</ListItemIcon>
									<ListItemText>{t(`languages.${lang}`)}</ListItemText>
								</MenuItem>
							);
						})}
					</Menu>
				</nav>

				<div className={styles.btnClose}>
					<IconButton color="white" onClick={onClose}>
						<IconClear />
					</IconButton>
				</div>
			</div>
		</div>
	);
};
