// react
import { FormEvent } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Button, Grid, Paper, Typography } from '@mui/material';

// icons
import { theme } from '../../../../../../theme';

// ui
import { Filters, FilterProps } from '../../../../../ui/Filters/Filters';

export interface SoeFiltersProps {
	filters: FilterProps[];
	onReset?: () => void;
	onSubmit?: (e: FormEvent) => void;
}

export const SoeFilters = ({ filters, onReset, onSubmit }: SoeFiltersProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
					},
					height: '100%',
				};
			}}>
			<form id="form-soe-filters" name="formSoeFilters" onSubmit={onSubmit}>
				<Grid container spacing={theme.spacing(2)}>
					<Grid item xs={12}>
						<Typography variant="h3">{t('filters')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Filters filters={filters} />
					</Grid>
					<Grid item xs={12}>
						<Grid item container justifyContent="flex-end" spacing={2}>
							<Grid item>
								<Button fullWidth variant="outlined" onClick={onReset}>
									{t('reset')}
								</Button>
							</Grid>
							<Grid item>
								<Button fullWidth variant="contained" type="submit">
									{t('applyFilters')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Paper>
	);
};
