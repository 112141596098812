// react
import { useContext, useEffect, useMemo, useState } from 'react';

// types
import { Account, Entity, Loan, Option, Period } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// context
import { ContextProject } from '../Project.context';

// mui
import { Container, Grid, Typography } from '@mui/material';

// pages
import { Sankey } from './Sankey/Sankey';
import { Summary } from './Summary/Summary';

// tables
import { ChartSankeyProps } from '../../../charts/ChartSankey/ChartSankey';

// ui
import { FilterChangeEvent, FilterProps } from '../../../ui/Filters/Filters';

// utils
import { getDatesByPeriod } from '../../../../utils/dates';
import { translateType } from '../../../../utils/translations';

export interface FilterValuesProps {
	entities: Option[];
}

export interface FiltersValuesDefaults {
	accounts?: string[];
	currency?: string;
	entities?: Option[];
	loans?: string[];
	period?: string;
	type?: string[];
}

export const FundFlow = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);

	// hooks
	const { t } = useTranslation();

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramAccounts = searchParams.get('accounts');
	const paramCurrency = searchParams.get('currency');
	const paramPeriod = searchParams.get('period');
	const paramEntities = searchParams.get('entities');
	const paramLoans = searchParams.get('loans');
	const paramType = searchParams.get('type');

	// defaults
	const defaultFiltersValues: FiltersValuesDefaults = useMemo(() => {
		return {
			accounts: ['all'],
			currency: '',
			entities: [],
			loans: ['all'],
			period: 'YEAR',
			type: ['all'],
		};
	}, []);

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [dataSankey, setDataSankey] = useState<ChartSankeyProps | null>(null);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [filtersValues, setFiltersValues] = useState(defaultFiltersValues);

	// fetch sankey
	let fetchSankeyUrl = `${process.env.REACT_APP_API_URL}/charts/sankey?project=${project?.id}`;

	if (paramAccounts) {
		fetchSankeyUrl += `&accounts=${decodeURIComponent(paramAccounts)}`;
	}

	if (paramCurrency) {
		fetchSankeyUrl += `&currency=${decodeURIComponent(paramCurrency)}`;
	}

	if (paramPeriod) {
		const { dateAfter, dateBefore } = getDatesByPeriod(paramPeriod as Period);

		if (dateAfter) {
			fetchSankeyUrl += `&dateAfter=${dateAfter}`;
		}

		if (dateBefore) {
			fetchSankeyUrl += `&dateBefore=${dateBefore}`;
		}
	}

	if (paramEntities) {
		fetchSankeyUrl += `&entities=${decodeURIComponent(paramEntities)}`;
	}

	if (paramLoans) {
		fetchSankeyUrl += `&loans=${decodeURIComponent(paramLoans)}`;
	}

	if (paramType) {
		fetchSankeyUrl += `&type=${decodeURIComponent(paramType)}`;
	}

	// vars
	const idFormFilters = 'form-sankey-filters';

	// fetch
	const { refetch: refetchSankey } = useFetch({
		isEnabled: Boolean(project && paramCurrency && paramPeriod),
		url: fetchSankeyUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			let dataSankeyNew = null;
			if (res.data?.links?.length && res.data?.nodes?.length) {
				dataSankeyNew = {
					data: {
						...res.data,
					},
				};
			}

			setDataSankey(dataSankeyNew);
		},
	});

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,idWbg`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,type`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// fetch accounts
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&fields=id,name,currency`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// options
	const sortByLabel = (a: Option, b: Option) => {
		return a.label > b.label ? 1 : -1;
	};

	const optionsLoans: Option[] = loans
		.map((loan) => {
			return {
				disabled: loan.status === 'DISABLED',
				label: loan.idWbg,
				value: String(loan.id),
			};
		})
		.sort(sortByLabel);

	const optionsEntities: Option[] = entities
		.map((entity) => {
			return {
				label: entity.name,
				secondary: translateType(entity.type, t),
				value: String(entity.id),
			};
		})
		.sort(sortByLabel);

	const optionsAccounts: Option[] = accounts
		.map((account) => {
			return {
				label: account.name,
				value: String(account.id),
			};
		})
		.sort(sortByLabel);

	const currencies = accounts.map((account) => account.currency);
	const optionsCurrency: Option[] = currencies
		.filter((item, index) => currencies.indexOf(item) === index)
		.map((currency) => {
			return {
				label: currency,
				value: currency,
			};
		})
		.sort(sortByLabel);

	const optionsTransactionTypes = [
		{
			label: t('payment'),
			value: 'PAYMENT',
		},
		{
			label: t('transfer'),
			value: 'TRANSFER',
		},
	];

	// handlers
	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	// filters
	const filters: FilterProps[] = [
		{
			hasAllOption: true,
			hasSearchParam: true,
			id: `${idFormFilters}-type`,
			label: t('type'),
			multiple: true,
			name: 'type',
			onChange: handleChangeFilterValue,
			options: optionsTransactionTypes,
			type: 'SELECT',
			value: filtersValues.type,
		},
		{
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-currency`,
			label: t('currency'),
			name: 'currency',
			onChange: handleChangeFilterValue,
			options: optionsCurrency,
			type: 'SELECT',
			value: filtersValues.currency,
		},
		{
			label: t('period'),
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-period`,
			name: 'period',
			onChange: handleChangeFilterValue,
			options: [
				{
					label: t('month'),
					value: 'MONTH',
				},
				{
					label: t('quarter'),
					value: 'QUARTER',
				},
				{
					label: t('year'),
					value: 'YEAR',
				},
			],
			type: 'SELECT',
			value: filtersValues.period,
		},
		{
			hasAllOption: true,
			hasSearchParam: true,
			id: `${idFormFilters}-loans`,
			label: t('financingSources'),
			multiple: true,
			name: 'loans',
			onChange: handleChangeFilterValue,
			options: optionsLoans,
			type: 'SELECT',
			value: filtersValues.loans,
		},
		{
			hasAllOption: true,
			hasSearchParam: true,
			id: `${idFormFilters}-entities`,
			label: t('entities'),
			name: 'entities',
			onChange: handleChangeFilterValue,
			options: optionsEntities,
			type: 'AUTOCOMPLETE',
			value: filtersValues.entities,
		},
		{
			hasAllOption: true,
			hasSearchParam: true,
			id: `${idFormFilters}-accounts`,
			label: t('accounts'),
			multiple: true,
			name: 'accounts',
			onChange: handleChangeFilterValue,
			options: optionsAccounts,
			type: 'SELECT',
			value: filtersValues.accounts,
		},
	];

	// refetch sankey data when search params change
	useEffect(() => {
		if (paramCurrency && paramPeriod) {
			refetchSankey();
		}
	}, [refetchSankey, paramCurrency, paramPeriod, searchParams]);

	// set default search params
	useEffect(() => {
		if (!paramCurrency || !paramPeriod) {
			if (currencies.length) {
				searchParams.set('currency', String(currencies[0]));
			}

			searchParams.set('period', String(defaultFiltersValues.period));
			setSearchParams(searchParams);
		}
	}, [currencies, defaultFiltersValues, paramCurrency, paramPeriod, searchParams, setSearchParams]);

	// set default filters
	useEffect(() => {
		const valuesNew: FiltersValuesDefaults = {};

		if (accounts) {
			let accountsNew = accounts.map((a) => String(a.id));
			if (paramAccounts) {
				accountsNew = paramAccounts.split(',');
			}
			valuesNew.accounts = accountsNew;
		}

		if (entities) {
			let entitiesSelected = entities;

			if (paramEntities) {
				const paramEntitiesArray = paramEntities.split(',');
				entitiesSelected = entities.filter((entity) =>
					paramEntitiesArray.includes(String(entity.id))
				);
			}

			valuesNew.entities = entitiesSelected.map((entity) => {
				return {
					label: entity.name,
					value: String(entity.id),
				};
			});
		}

		if (loans) {
			let loansNew = loans.map((a) => String(a.id));
			if (paramLoans) {
				loansNew = paramLoans.split(',');
			}
			valuesNew.loans = loansNew;
		}

		if (paramCurrency) {
			valuesNew.currency = paramCurrency;
		}

		if (paramType) {
			valuesNew.type = paramType.split(',');
		}

		setFiltersValues((valuesOld) => {
			return {
				...valuesOld,
				...valuesNew,
			};
		});
	}, [
		accounts,
		entities,
		loans,
		paramAccounts,
		paramCurrency,
		paramEntities,
		paramLoans,
		paramType,
	]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Typography variant="h2">{t('fundFlow')}</Typography>
				</Container>
			</Grid>
			<Grid item xs={12}>
				<Summary dataSankey={dataSankey} />
			</Grid>

			<Grid item xs={12}>
				<Sankey dataSankey={dataSankey} FiltersProps={{ filters }} />
			</Grid>
		</Grid>
	);
};
