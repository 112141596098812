// react
import { useContext } from 'react';

// context
import { ContextCashBenefits } from '../../Project.context';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableCashBenefits } from '../../../../tables/cards/TableCashBenefits/TableCashBenefits';

export const CashBenefits = () => {
	// context
	const { cashBenefits, isLoadingCashBenefits, paginationCashBenefits, refetchCashBenefits } =
		useContext(ContextCashBenefits);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableCashBenefits
						isLoading={isLoadingCashBenefits}
						pagination={paginationCashBenefits}
						refetchTransactions={refetchCashBenefits}
						transactions={cashBenefits}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
