// react
import {
	MouseEvent,
	SyntheticEvent,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

// types
import {
	Account,
	AccountBalance,
	ActionButton,
	Activity,
	Contract,
	Document,
	Entity,
	Loan,
	Pagination,
	Project,
	ProjectPayload,
	Transaction,
	User,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import {
	ContextAccounts,
	ContextActivities,
	ContextBeneficiaries,
	ContextCashBenefits,
	ContextContracts,
	ContextDocumentsBeneficiaries,
	ContextDocumentsSuppliers,
	ContextEntities,
	ContextEntitiesSummary,
	ContextHistoricalTransactions,
	ContextLoans,
	ContextOpEx,
	ContextPayments,
	ContextProject,
	ContextSuppliers,
	ContextTransfers,
	ContextUsersPermissions,
} from './Project.context';
import { ContextUser } from '../../../App.context';

// router
import {
	Link as RouterLink,
	Outlet,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// material
import { Grid, MenuItemProps } from '@mui/material';

// layouts
import { Authenticated } from '../../layouts/Authenticated/Authenticated';

// ui
import { Banner } from '../../ui/Banner/Banner';
import { Breadcrumbs, BreadcrumbsProps } from '../../ui/Breadcrumbs/Breadcrumbs';
import { Empty } from '../../ui/Empty/Empty';
import { Loading } from '../../ui/Loading/Loading';
import { Tabs, TabProps } from '../../ui/Tabs/Tabs';
import { Title } from '../../ui/Title/Title';

// dialogs
import { DialogOnboardProject } from '../../dialogs/DialogOnboardProject/DialogOnboardProject';

// icons
import { SettingsOutlined as IconSettings } from '@mui/icons-material';

// utils
import { translateStatus } from '../../../utils/translations';

// defaults
import { defaultPagination } from '../../../utils/defaults';
import { BtnFab } from '../../ui/BtnFab/BtnFab';

export const PageProject = () => {
	// hooks
	const { pathname } = useLocation();
	const { id } = useParams();
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { banner, setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// defaults
	const getTabByLocation = useCallback((pathname) => {
		if (pathname.includes('accounts')) {
			return 'accounts';
		} else if (pathname.includes('entities')) {
			return 'entities';
		} else if (pathname.includes('transactions')) {
			return 'transactions';
		} else if (pathname.includes('contracts')) {
			return 'contracts';
		} else if (pathname.includes('settings')) {
			return 'settings';
		} else if (pathname.includes('suppliers')) {
			return 'suppliers';
		} else if (pathname.includes('beneficiaries')) {
			return 'beneficiaries';
		} else if (pathname.includes('flow')) {
			return 'fundFlow';
		} else if (pathname.includes('reports')) {
			return 'reports';
		} else {
			return 'overview';
		}
	}, []);

	const defaultTabValue = useMemo(() => {
		return getTabByLocation(pathname);
	}, [getTabByLocation, pathname]);

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramBeneficiary = searchParams.get('beneficiary');
	const paramContract = searchParams.get('contract');
	const paramDialog = searchParams.get('dialog');
	const paramEntity = searchParams.get('entity');
	const paramExpenseType = searchParams.get('expenseType');
	const paramFromAccount = searchParams.get('fromAccount');
	const paramFromEntity = searchParams.get('fromEntity');
	const paramHasTransactions = searchParams.get('hasTransactions');
	const paramIsMock = searchParams.get('isMock');
	const paramIsWbg = Boolean(searchParams.get('isWbg'));
	const paramLoan = searchParams.get('loan');
	const paramPage = Number(searchParams.get('page') || 1);
	const paramQ = searchParams.get('q');
	const paramStatus = searchParams.get('status');
	const paramSubtype = searchParams.get('subtype');
	const paramSupplier = searchParams.get('supplier');
	const paramToAccount = searchParams.get('toAccount');
	const paramToEntity = searchParams.get('toEntity');
	const paramType = searchParams.get('type');
	const defaultPaginationWithPageParam = {
		...defaultPagination,
		page: paramPage,
	};

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [activities, setActivities] = useState<Activity[]>([]);
	const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
	const [cashBenefits, setCashBenefits] = useState<Transaction[]>([]);
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [documentsBeneficiaries, setDocumentsBeneficiaries] = useState<Document[]>([]);
	const [documentsSuppliers, setDocumentsSuppliers] = useState<Document[]>([]);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [entitiesSummary, setEntitiesSummary] = useState<Entity[]>([]);
	const [historicalTransactions, setHistoricalTransactions] = useState<Document[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [opEx, setOpEx] = useState<Transaction[]>([]);
	const [paginationBeneficiaries, setPaginationBeneficiaries] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationCashBenefits, setPaginationCashBenefits] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationContracts, setPaginationContracts] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationDocumentsBeneficiaries, setPaginationDocumentsBeneficiaries] =
		useState<Pagination>(defaultPaginationWithPageParam);
	const [paginationDocumentsSuppliers, setPaginationDocumentsSuppliers] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationEntities, setPaginationEntities] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationHistoricalTransactions, setPaginationHistoricalTransactions] =
		useState<Pagination>(defaultPaginationWithPageParam);
	const [paginationOpEx, setPaginationOpEx] = useState<Pagination>(defaultPaginationWithPageParam);
	const [paginationPayments, setPaginationPayments] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationSuppliers, setPaginationSuppliers] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationTransfers, setPaginationTransfers] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [paginationUsersPermissions, setPaginationUsersPermissions] = useState<Pagination>(
		defaultPaginationWithPageParam
	);
	const [payments, setPayments] = useState<Transaction[]>([]);
	const [project, setProject] = useState<Project | null>(null);
	const [suppliers, setSuppliers] = useState<Entity[]>([]);
	const [tabValue, setTabValue] = useState(defaultTabValue);
	const [transfers, setTransfers] = useState<Transaction[]>([]);
	const [usersPermissions, setUsersPermissions] = useState<User[]>([]);

	// vars
	const isOnboarded = Boolean(project && project.id) && !paramIsWbg;
	const isWfa = user?.role === 'WFA';

	let urlWbg = `${process.env.REACT_APP_API_URL}/wbg?q=${id}`;

	if (paramIsMock) {
		urlWbg += `&resource=mock`;
	}

	// fetch wbg project
	const { isLoading: isLoadingWbgProject } = useFetch({
		isEnabled: paramIsWbg,
		url: urlWbg,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				const project = res.data.find((proj: Project) => proj.idWbg === id);
				const accounts: Account[] = [];
				const entities: Entity[] = [];

				// for each loan
				for (let i = 0; i < project.loans.length; i++) {
					// for each account
					for (let a = 0; a < project.loans[i].accounts.length; a++) {
						const account = structuredClone(project.loans[i].accounts[a]);

						// check if account is added
						const accountAdded = accounts.find((a) => a.idWbg === account.idWbg);

						// format balances
						const balances = account.balances.map((balance: AccountBalance) => {
							return {
								...balance,
								loan: {
									idWbg: project.loans[i].idWbg,
								},
							};
						});

						// if account exists
						if (accountAdded) {
							// add balances to existing account balances array
							accountAdded.balances = accountAdded.balances.concat(balances);
						} else {
							// add account
							accounts.push({
								...account,
								balances,
								entities: [],
								sources: [],
								type: 'DESIGNATED',
							});
						}
					}

					// for each entity
					for (let e = 0; e < project.loans[i].entities.length; e++) {
						// check if entity is added
						const isEntityAdded = entities.find(
							(entity) => entity.idWbg === project.loans[i].entities[e].idWbg
						);

						// add entity
						if (!isEntityAdded) {
							entities.push(project.loans[i].entities[e]);
						}
					}
				}

				setProject(project);
				setLoans(project.loans);
				setAccounts(accounts);
				setEntities(entities);
			}
		},
	});

	// fetch project
	const { isLoading: isLoadingProject, refetch: refetchProject } = useFetch({
		isEnabled: !paramIsWbg,
		url: `${process.env.REACT_APP_API_URL}/projects?idWbg=${id}&populate=country,region,practice,entities,users`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setProject(res.data[0]);
			}
		},
	});

	// fetch accounts
	const { isLoading: isLoadingAccounts, refetch: refetchAccounts } = useFetch({
		isEnabled: !paramIsWbg && isOnboarded && tabValue === 'accounts',
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&populate=accounts,entities,sources.loan`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setAccounts(res.data);
			}
		},
	});

	// fetch loans
	const { isLoading: isLoadingLoans, refetch: refetchLoans } = useFetch({
		isEnabled: !paramIsWbg && isOnboarded,
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&populate=entities,accounts`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities summary
	const { isLoading: isLoadingEntitiesSummary, refetch: refetchEntitiesSummary } = useFetch({
		isEnabled: !paramIsWbg && isOnboarded && tabValue === 'entities',
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,type&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntitiesSummary(res.data);
			}
		},
	});

	// fetch entities
	let fetchEntitiesUrl = `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&page=${paramPage}&limit=${paginationEntities.perPage}&populate=wallet`;

	if (paramQ) {
		fetchEntitiesUrl += `&q=${paramQ}`;
	}

	if (paramType) {
		fetchEntitiesUrl += `&type=${paramType}`;
	} else {
		fetchEntitiesUrl += `&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`;
	}

	const {
		fetchRequest: fetchEntities,
		isLoading: isLoadingEntities,
		refetch: refetchEntities,
	} = useFetch({
		isLazy: true,
		url: fetchEntitiesUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}

			if (res.pagination) {
				setPaginationEntities(res.pagination);
			}
		},
	});

	// fetch opex
	let fetchOpExUrl = `${process.env.REACT_APP_API_URL}/transactions?project=${project?.id}&page=${paramPage}&limit=${paginationPayments.perPage}&type=OPEX&&populate=expenseType,fromAccount,fromEntity,payments.loan&orderBy=created&order=DESC`;

	if (paramFromEntity) {
		fetchOpExUrl += `&fromEntity=${paramFromEntity}`;
	}

	if (paramQ) {
		fetchOpExUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchOpEx,
		isLoading: isLoadingOpEx,
		refetch: refetchOpEx,
	} = useFetch({
		isLazy: true,
		url: fetchOpExUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setOpEx(res.data);
			}

			if (res.pagination) {
				setPaginationOpEx(res.pagination);
			}
		},
	});

	// fetch payments
	let fetchPaymentsUrl = `${process.env.REACT_APP_API_URL}/transactions?project=${project?.id}&page=${paramPage}&limit=${paginationPayments.perPage}&type=PAYMENT&populate=expenseType,fromAccount,contract,payments.loan,toEntity&orderBy=created&order=DESC`;

	if (paramExpenseType) {
		fetchPaymentsUrl += `&expenseType=${paramExpenseType}`;
	}
	if (paramContract) {
		fetchPaymentsUrl += `&contract=${paramContract}`;
	}
	if (paramLoan) {
		fetchPaymentsUrl += `&loan=${paramLoan}`;
	}
	if (paramStatus) {
		fetchPaymentsUrl += `&status=${paramStatus}`;
	}
	if (paramToEntity) {
		fetchPaymentsUrl += `&toEntity=${paramToEntity}`;
	}
	if (paramQ) {
		fetchPaymentsUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchPayments,
		isLoading: isLoadingPayments,
		refetch: refetchPayments,
	} = useFetch({
		isLazy: true,
		url: fetchPaymentsUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setPayments(res.data);
			}

			if (res.pagination) {
				setPaginationPayments(res.pagination);
			}
		},
	});

	// fetch cash benefits
	let fetchCashBenefitsUrl = `${process.env.REACT_APP_API_URL}/transactions?project=${project?.id}&page=${paramPage}&limit=${paginationPayments.perPage}&type=CASH_BENEFIT&populate=expenseType,fromAccount,fromEntity,payments.loan,toEntity&orderBy=created&order=DESC`;

	if (paramLoan) {
		fetchCashBenefitsUrl += `&loan=${paramLoan}`;
	}
	if (paramStatus) {
		fetchCashBenefitsUrl += `&status=${paramStatus}`;
	}
	if (paramToEntity) {
		fetchCashBenefitsUrl += `&toEntity=${paramToEntity}`;
	}
	if (paramQ) {
		fetchCashBenefitsUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchCashBenefits,
		isLoading: isLoadingCashBenefits,
		refetch: refetchCashBenefits,
	} = useFetch({
		isLazy: true,
		url: fetchCashBenefitsUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setCashBenefits(res.data);
			}

			if (res.pagination) {
				setPaginationCashBenefits(res.pagination);
			}
		},
	});

	// fetch transfers
	let fetchTransfersUrl = `${process.env.REACT_APP_API_URL}/transactions?project=${project?.id}&page=${paramPage}&limit=${paginationTransfers.perPage}&type=TRANSFER&type=TRANSFER&populate=fromAccount,fromAccount.entities,toAccount,toAccount.entities,payments.loan&orderBy=created&order=DESC`;

	if (paramQ) {
		fetchTransfersUrl += `&q=${paramQ}`;
	}
	if (paramStatus) {
		fetchTransfersUrl += `&status=${paramStatus}`;
	}
	if (paramFromAccount) {
		fetchTransfersUrl += `&fromAccount=${paramFromAccount}`;
	}
	if (paramToAccount) {
		fetchTransfersUrl += `&toAccount=${paramToAccount}`;
	}

	const {
		fetchRequest: fetchTransfers,
		isLoading: isLoadingTransfers,
		refetch: refetchTransfers,
	} = useFetch({
		isLazy: true,
		url: fetchTransfersUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setTransfers(res.data);
			}

			if (res.pagination) {
				setPaginationTransfers(res.pagination);
			}
		},
	});

	// fetch historical transactions
	let fetchHistoricalTransactionsUrl = `${process.env.REACT_APP_API_URL}/documents?project=${project?.id}&page=${paramPage}&limit=${paginationHistoricalTransactions.perPage}&category=HISTORICAL_TRANSACTIONS&orderBy=created&order=DESC&populate=user`;

	if (paramQ) {
		fetchHistoricalTransactionsUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchHistoricalTransactions,
		isLoading: isLoadingHistoricalTransactions,
		refetch: refetchHistoricalTransactions,
	} = useFetch({
		isLazy: true,
		url: fetchHistoricalTransactionsUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setHistoricalTransactions(res.data);
			}

			if (res.pagination) {
				setPaginationHistoricalTransactions(res.pagination);
			}
		},
	});

	// fetch suppliers
	let fetchSuppliersUrl = `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&page=${paramPage}&limit=${paginationEntities.perPage}&type=SUPPLIER&populate=wallet`;

	if (paramQ) {
		fetchSuppliersUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchSuppliers,
		isLoading: isLoadingSuppliers,
		refetch: refetchSuppliers,
	} = useFetch({
		isLazy: true,
		url: fetchSuppliersUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSuppliers(res.data);
			}

			if (res.pagination) {
				setPaginationSuppliers(res.pagination);
			}
		},
	});

	// fetch beneficiaries
	let fetchBeneficiariesUrl = `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&page=${paramPage}&limit=${paginationBeneficiaries.perPage}&type=BENEFICIARY&populate=wallet`;

	if (paramHasTransactions) {
		fetchBeneficiariesUrl += `&hasTransactions=${paramHasTransactions}`;
	}

	if (paramQ) {
		fetchBeneficiariesUrl += `&q=${paramQ}`;
	}

	if (paramSubtype) {
		fetchBeneficiariesUrl += `&subtype=${paramSubtype}`;
	}

	const {
		fetchRequest: fetchBeneficiaries,
		isLoading: isLoadingBeneficiaries,
		refetch: refetchBeneficiaries,
	} = useFetch({
		isLazy: true,
		url: fetchBeneficiariesUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiaries(res.data);
			}

			if (res.pagination) {
				setPaginationBeneficiaries(res.pagination);
			}
		},
	});

	// fetch documents beneficiaries
	let fetchDocumentsBeneficiariesUrl = `${process.env.REACT_APP_API_URL}/documents?project=${project?.id}&entityType=BENEFICIARY&page=${paramPage}&limit=${paginationBeneficiaries.perPage}&populate=type,user,user.entity`;

	if (paramQ) {
		fetchDocumentsBeneficiariesUrl += `&q=${paramQ}`;
	}

	if (paramType) {
		fetchDocumentsBeneficiariesUrl += `&type=${paramType}`;
	}

	if (paramEntity) {
		fetchDocumentsBeneficiariesUrl += `&entity=${paramEntity}`;
	}

	const {
		fetchRequest: fetchDocumentsBeneficiaries,
		isLoading: isLoadingDocumentsBeneficiaries,
		refetch: refetchDocumentsBeneficiaries,
	} = useFetch({
		isLazy: true,
		url: fetchDocumentsBeneficiariesUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocumentsBeneficiaries(res.data);
			}

			if (res.pagination) {
				setPaginationDocumentsBeneficiaries(res.pagination);
			}
		},
	});

	// fetch documents suppliers
	let fetchDocumentsSuppliersUrl = `${process.env.REACT_APP_API_URL}/documents?project=${project?.id}&entityType=SUPPLIER&page=${paramPage}&limit=${paginationBeneficiaries.perPage}&populate=type,user,user.entity`;

	if (paramQ) {
		fetchDocumentsSuppliersUrl += `&q=${paramQ}`;
	}

	if (paramType) {
		fetchDocumentsSuppliersUrl += `&type=${paramType}`;
	}

	if (paramEntity) {
		fetchDocumentsSuppliersUrl += `&entity=${paramEntity}`;
	}

	if (paramContract) {
		fetchDocumentsSuppliersUrl += `&contract=${paramContract}`;
	}

	const {
		fetchRequest: fetchDocumentsSuppliers,
		isLoading: isLoadingDocumentsSuppliers,
		refetch: refetchDocumentsSuppliers,
	} = useFetch({
		isLazy: true,
		url: fetchDocumentsSuppliersUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocumentsSuppliers(res.data);
			}

			if (res.pagination) {
				setPaginationDocumentsSuppliers(res.pagination);
			}
		},
	});

	// fetch contracts
	let fetchContractsUrl = `${process.env.REACT_APP_API_URL}/contracts?project=${project?.id}&page=${paramPage}&limit=${paginationContracts.perPage}&populate=supplier`;

	if (paramQ) {
		fetchContractsUrl += `&q=${paramQ}`;
	}

	if (paramSupplier) {
		fetchContractsUrl += `&supplier=${paramSupplier}`;
	}

	const {
		fetchRequest: fetchContracts,
		isLoading: isLoadingContracts,
		refetch: refetchContracts,
	} = useFetch({
		isLazy: true,
		url: fetchContractsUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setContracts(res.data);
			}

			if (res.pagination) {
				setPaginationContracts(res.pagination);
			}
		},
	});

	// fetch activities
	const {
		fetchRequest: fetchActivities,
		isLoading: isLoadingActivities,
		refetch: refetchActivities,
	} = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/activities?project=${project?.id}&limit=10&populate=account,fromAccount,toAccount,contract,document,entity,loan,project,user,transaction&orderBy=created`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setActivities(res.data);
			}
		},
	});

	// fetch users permissions
	const {
		fetchRequest: fetchUsersPermissions,
		isLoading: isLoadingUsersPermissions,
		refetch: refetchUsersPermissions,
	} = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/users?project=${project?.id}&permissions=beneficiary.read`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsersPermissions(res.data);
			}

			if (res.pagination) {
				setPaginationUsersPermissions(res.pagination);
			}
		},
	});

	const tabs: TabProps[] = [
		{
			component: RouterLink,
			label: t('overview'),
			to: `/project/${id}/overview`,
			value: 'overview',
		},
		{
			component: RouterLink,
			label: t('entities'),
			to: `/project/${id}/entities`,
			value: 'entities',
		},
		{
			component: RouterLink,
			label: t('accounts'),
			to: `/project/${id}/accounts`,
			value: 'accounts',
		},
	];

	if (isOnboarded) {
		tabs.push(
			{
				component: RouterLink,
				label: t('transactions'),
				to: `/project/${id}/transactions`,
				value: 'transactions',
			},
			{
				component: RouterLink,
				label: t('suppliers'),
				to: `/project/${id}/suppliers`,
				value: 'suppliers',
			},
			{
				component: RouterLink,
				label: t('contracts'),
				to: `/project/${id}/contracts`,
				value: 'contracts',
			},
			{
				component: RouterLink,
				label: t('beneficiaries'),
				to: `/project/${id}/beneficiaries`,
				value: 'beneficiaries',
			},
			{
				component: RouterLink,
				label: t('fundFlow'),
				to: `/project/${id}/flow`,
				value: 'fundFlow',
			},
			{
				component: RouterLink,
				label: t('reports'),
				to: `/project/${id}/reports`,
				value: 'reports',
			},
			{
				component: RouterLink,
				icon: <IconSettings />,
				label: t('settings'),
				sx: {
					marginLeft: 'auto',
				},
				to: `/project/${id}/settings`,
				value: 'settings',
			}
		);
	} else {
		// add isWbg param to each tab link indicating project has not yet been onboarded
		tabs.forEach((tab) => {
			tab.to = `${tab.to}?isWbg=true`;
		});
	}

	const activeTab = tabs.find((tab) => tab.value === tabValue);

	const handleCloseDialogOnboardProject = (
		e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLFormElement>
	) => {
		searchParams.delete('dialog');
		setSearchParams(searchParams);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	const handleOnboardingSuccess = () => {
		// remove isWbg param
		searchParams.delete('isWbg');
		setSearchParams(searchParams);

		// set onboard success banner
		setBanner({
			hasClose: true,
			message: t('alert.onboardSuccess'),
			severity: 'success',
		});
	};

	const titleActions: ActionButton[] = [];

	if (isOnboarded) {
		if (tabValue === 'accounts' && user?.permissions?.account?.create) {
			titleActions.push({
				children: t('addAccount'),
				onClick: () => {
					searchParams.set('dialog', 'addAccount');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		} else if (tabValue === 'contracts' && user?.permissions?.contract?.create) {
			titleActions.push({
				children: t('addContract'),
				onClick: () => {
					searchParams.set('dialog', 'addContract');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		} else if (tabValue === 'entities' && user?.permissions?.entity?.create) {
			titleActions.push({
				children: t('addEntity'),
				onClick: () => {
					searchParams.set('dialog', 'addEntity');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		} else if (tabValue === 'fundReplenishment' && user?.permissions?.transaction?.create) {
			titleActions.push({
				children: t('recordReplenishment'),
				onClick: () => {
					searchParams.set('dialog', 'recordReplenishment');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		} else if (tabValue === 'suppliers' && user?.permissions?.entity?.create) {
			titleActions.push(
				{
					children: t('uploadSuppliers'),
					onClick: () => {
						searchParams.set('dialog', 'uploadSuppliers');
						setSearchParams(searchParams);
					},
					variant: 'outlined',
				},
				{
					children: t('addSupplier'),
					onClick: () => {
						searchParams.set('dialog', 'addSupplier');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				}
			);
		} else if (tabValue === 'beneficiaries' && user?.permissions?.entity?.create) {
			const options: ActionButton[] = [];

			if (user?.permissions?.entity?.create) {
				options.push({
					children: t('addBeneficiary'),
					onClick: () => {
						searchParams.set('dialog', 'addBeneficiary');
						setSearchParams(searchParams);
					},
					variant: 'outlined',
				});
			}

			if (user?.permissions?.transaction?.create) {
				options.push({
					children: t('uploadTransactions'),
					onClick: () => {
						searchParams.set('dialog', 'uploadTransactions');
						setSearchParams(searchParams);
					},
					variant: 'outlined',
				});
			}

			if (user?.permissions?.entity?.create) {
				options.push({
					children: t('uploadBeneficiaries'),
					onClick: () => {
						searchParams.set('dialog', 'uploadBeneficiaries');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			}

			if (options.length) {
				titleActions.push({
					options,
				});
			}
		} else if (tabValue === 'settings') {
			if (pathname.includes('form/')) {
				titleActions.push({
					children: t('saveForm'),
					onClick: () => {
						searchParams.set('dialog', 'updateForm');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			} else if (!pathname.includes('permissions')) {
				titleActions.push({
					children: t('saveExpenseTypes'),
					onClick: () => {
						searchParams.set('dialog', 'updateExpenseTypes');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			}
		} else if (tabValue === 'transactions' && user?.permissions?.transaction?.create) {
			if (pathname.includes('benefits')) {
				titleActions.push({
					children: t('recordBenefit'),
					onClick: () => {
						searchParams.set('dialog', 'recordBenefit');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			} else if (pathname.includes('historical')) {
				titleActions.push({
					children: t('uploadHistoricalTransactions'),
					onClick: () => {
						searchParams.set('dialog', 'uploadHistoricalTransactions');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			} else if (pathname.includes('opex')) {
				titleActions.push({
					children: t('recordOpEx'),
					onClick: () => {
						searchParams.set('dialog', 'recordOpEx');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			} else if (pathname.includes('transfers')) {
				titleActions.push({
					children: t('recordFundTransfer'),
					onClick: () => {
						searchParams.set('dialog', 'recordFundTransfer');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			} else {
				titleActions.push({
					children: t('recordPayment'),
					onClick: () => {
						searchParams.set('dialog', 'recordPayment');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			}
		}
	}

	const emptyActions: ActionButton[] = [];

	if (isWfa) {
		emptyActions.push({
			component: RouterLink,
			children: t('onboardProject'),
			to: '/projects/onboard',
			variant: 'contained',
		});
	}

	// set onboard banner
	useEffect(() => {
		if (paramIsWbg && project?.status === 'PENDING') {
			setBanner({
				actions: [
					{
						component: RouterLink,
						color: 'error',
						children: t('cancel'),
						to: '/projects/onboard',
						variant: 'contained',
					},
					{
						color: 'primary',
						children: t('onboard'),
						onClick: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
							searchParams.set('dialog', 'onboardProject');
							setSearchParams(searchParams);
						},
						variant: 'contained',
					},
				],
				message: t('alert.onboardToEnableLoans'),
			});
		}
	}, [paramIsWbg, project, searchParams, setSearchParams, setBanner, t]);

	// fetch paginated resources
	useEffect(() => {
		if (project?.id) {
			if (tabValue === 'overview') {
				fetchActivities();
			} else if (tabValue === 'entities') {
				fetchEntities();
			} else if (tabValue === 'transactions') {
				if (pathname.includes('benefits')) {
					fetchCashBenefits();
				} else if (pathname.includes('historical')) {
					fetchHistoricalTransactions();
				} else if (pathname.includes('opex')) {
					fetchOpEx();
				} else if (pathname.includes('transfers')) {
					fetchTransfers();
				} else {
					fetchPayments();
				}
			} else if (tabValue === 'contracts') {
				fetchContracts();
			} else if (tabValue === 'suppliers') {
				if (pathname.includes('documents')) {
					fetchDocumentsSuppliers();
				} else {
					fetchSuppliers();
				}
			} else if (tabValue === 'beneficiaries') {
				if (pathname.includes('documents')) {
					fetchDocumentsBeneficiaries();
				} else {
					fetchBeneficiaries();
				}
			} else if (tabValue === 'settings') {
				fetchUsersPermissions();
			}
		}
	}, [
		fetchActivities,
		fetchBeneficiaries,
		fetchCashBenefits,
		fetchContracts,
		fetchDocumentsBeneficiaries,
		fetchDocumentsSuppliers,
		fetchEntities,
		fetchOpEx,
		fetchPayments,
		fetchSuppliers,
		fetchTransfers,
		fetchHistoricalTransactions,
		fetchUsersPermissions,
		pathname,
		paramBeneficiary,
		paramContract,
		paramEntity,
		paramExpenseType,
		paramFromAccount,
		paramFromEntity,
		paramHasTransactions,
		paramLoan,
		paramPage,
		paramQ,
		paramStatus,
		paramSubtype,
		paramSupplier,
		paramToAccount,
		paramToEntity,
		paramType,
		project,
		tabValue,
	]);

	// set active tab
	useEffect(() => {
		const tabValueByLocation = getTabByLocation(pathname);
		setTabValue(tabValueByLocation);
	}, [getTabByLocation, pathname]);

	// remove banner when component is unmounted
	useEffect(() => {
		return () => {
			setBanner(null);
		};
	}, [setBanner]);

	const isLoadingProjectOrWbgProject = isLoadingProject || isLoadingWbgProject;

	const renderTemplate = () => {
		if (isLoadingProjectOrWbgProject) {
			return <Loading variant="page" />;
		}

		const segments = pathname.split('/');
		const outletOnlySegments = ['entity', 'supplier'];

		if (outletOnlySegments.filter((segment) => segments.includes(segment)).length > 0) {
			return <Outlet />;
		}

		if (project) {
			let urlProject = `/project/${project.idWbg}`;

			if (paramIsWbg) {
				urlProject += `?isWbg=true`;
			}

			const breadcrumbsProps: BreadcrumbsProps = {
				crumbs: [
					{
						children: t('projects'),
						to: '/projects',
					},
					{
						children: project.idWbg,
						to: urlProject,
					},
				],
				page: String(activeTab?.label),
			};

			// set breadcrumbs for sub tabs
			if (pathname.includes('beneficiaries/')) {
				breadcrumbsProps.crumbs.push({
					children: t('beneficiaries'),
					to: `${urlProject}/beneficiaries`,
				});

				if (pathname.includes('details')) {
					breadcrumbsProps.page = t('details');
				} else if (pathname.includes('documents')) {
					breadcrumbsProps.page = t('documents');
				}
			} else if (pathname.includes('settings/')) {
				breadcrumbsProps.crumbs.push({
					children: t('settings'),
					to: `${urlProject}/settings`,
				});

				if (pathname.includes('form')) {
					breadcrumbsProps.page = t('forms');
				} else if (pathname.includes('permissions')) {
					breadcrumbsProps.page = t('permissions');
				} else if (pathname.includes('traceability')) {
					breadcrumbsProps.page = t('traceability');
				}
			} else if (pathname.includes('suppliers/')) {
				breadcrumbsProps.crumbs.push({
					children: t('suppliers'),
					to: `${urlProject}/suppliers`,
				});

				if (pathname.includes('details')) {
					breadcrumbsProps.page = t('details');
				} else if (pathname.includes('documents')) {
					breadcrumbsProps.page = t('documents');
				}
			} else if (pathname.includes('transactions/')) {
				breadcrumbsProps.crumbs.push({
					children: t('transactions'),
					to: `${urlProject}/transactions`,
				});

				if (pathname.includes('historical')) {
					breadcrumbsProps.page = t('historicalTransactions');
				} else if (pathname.includes('opex')) {
					breadcrumbsProps.page = t('operatingExpenses');
				} else if (pathname.includes('payments')) {
					breadcrumbsProps.page = t('payments');
				} else if (pathname.includes('transfers')) {
					breadcrumbsProps.page = t('transfers');
				}
			} else if (pathname.includes('reports/')) {
				breadcrumbsProps.crumbs.push({
					children: t('reports'),
					to: `${urlProject}/reports`,
				});
				if (pathname.includes('soe')) {
					breadcrumbsProps.page = t('soe');
				}
			}

			// construct fab items using title actions
			const btnFabItems: MenuItemProps[] = [];

			if (titleActions.length) {
				// for each title action
				for (let i = 0; i < titleActions.length; i++) {
					const action = titleActions[i];

					if (action.options) {
						// for each option
						for (let o = 0; o < action.options.length; o++) {
							const option = action.options[o];

							btnFabItems.push({
								children: option.children,
								onClick: option.onClick,
							});
						}
					} else {
						btnFabItems.push({
							children: action.children,
							onClick: action.onClick,
						});
					}
				}
			}

			return (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Breadcrumbs {...breadcrumbsProps} />
						</Grid>

						<Grid item xs={12}>
							<Title
								actions={titleActions}
								chip={{
									color: project.status === 'PENDING' ? 'warning' : 'success',
									label: translateStatus(project.status, t),
									variant: 'twoTone',
								}}
								color="purple"
								id={id}
								overline={t('project')}
								title={project.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Tabs onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						</Grid>
						<Grid item xs={12}>
							<Outlet />
						</Grid>
					</Grid>
					{!isOnboarded && (
						<DialogOnboardProject
							isOpen={paramDialog === 'onboardProject'}
							onClose={handleCloseDialogOnboardProject}
							onSuccess={handleOnboardingSuccess}
							project={project as unknown as ProjectPayload}
						/>
					)}
					{btnFabItems.length > 0 && (
						<BtnFab
							items={btnFabItems}
							color="primary"
							sx={(theme) => {
								return {
									display: {
										md: 'none',
									},
									position: 'fixed',
									bottom: theme.spacing(3),
									right: theme.spacing(3),
								};
							}}
						/>
					)}
				</>
			);
		}

		return (
			<Empty
				actions={emptyActions}
				content={{ children: t('empty.project.content') }}
				title={{ children: t('empty.project.title') }}
				IconCircleProps={{ type: 'project' }}
				variant="page"
			/>
		);
	};

	return (
		<Authenticated>
			<ContextProject.Provider
				value={{
					isLoadingProject: isLoadingProjectOrWbgProject,
					isOnboarded,
					project,
					refetchProject,
					setProject,
				}}>
				<ContextActivities.Provider
					value={{ activities, isLoadingActivities, refetchActivities, setActivities }}>
					<ContextLoans.Provider value={{ isLoadingLoans, loans, refetchLoans, setLoans }}>
						<ContextEntitiesSummary.Provider
							value={{
								entitiesSummary,
								isLoadingEntitiesSummary,
								refetchEntitiesSummary,
								setEntitiesSummary,
							}}>
							<ContextEntities.Provider
								value={{
									entities,
									isLoadingEntities,
									paginationEntities,
									refetchEntities,
									setEntities,
									setPaginationEntities,
								}}>
								<ContextSuppliers.Provider
									value={{
										isLoadingSuppliers,
										paginationSuppliers,
										refetchSuppliers,
										setPaginationSuppliers,
										setSuppliers,
										suppliers,
									}}>
									<ContextBeneficiaries.Provider
										value={{
											isLoadingBeneficiaries,
											paginationBeneficiaries,
											refetchBeneficiaries,
											setPaginationBeneficiaries,
											setBeneficiaries,
											beneficiaries,
										}}>
										<ContextAccounts.Provider
											value={{ accounts, isLoadingAccounts, refetchAccounts, setAccounts }}>
											<ContextContracts.Provider
												value={{
													contracts,
													isLoadingContracts,
													paginationContracts,
													refetchContracts,
													setContracts,
													setPaginationContracts,
												}}>
												<ContextPayments.Provider
													value={{
														isLoadingPayments,
														payments,
														paginationPayments,
														refetchPayments,
														setPayments,
														setPaginationPayments,
													}}>
													<ContextCashBenefits.Provider
														value={{
															cashBenefits,
															isLoadingCashBenefits,
															paginationCashBenefits,
															refetchCashBenefits,
															setCashBenefits,
															setPaginationCashBenefits,
														}}>
														<ContextTransfers.Provider
															value={{
																isLoadingTransfers,
																transfers,
																paginationTransfers,
																refetchTransfers,
																setTransfers,
																setPaginationTransfers,
															}}>
															<ContextOpEx.Provider
																value={{
																	isLoadingOpEx,
																	opEx,
																	paginationOpEx,
																	refetchOpEx,
																	setOpEx,
																	setPaginationOpEx,
																}}>
																<ContextHistoricalTransactions.Provider
																	value={{
																		historicalTransactions,
																		isLoadingHistoricalTransactions,
																		paginationHistoricalTransactions,
																		refetchHistoricalTransactions,
																		setHistoricalTransactions,
																		setPaginationHistoricalTransactions,
																	}}>
																	<ContextDocumentsBeneficiaries.Provider
																		value={{
																			documentsBeneficiaries,
																			isLoadingDocumentsBeneficiaries,
																			paginationDocumentsBeneficiaries,
																			refetchDocumentsBeneficiaries,
																			setDocumentsBeneficiaries,
																			setPaginationDocumentsBeneficiaries,
																		}}>
																		<ContextDocumentsSuppliers.Provider
																			value={{
																				documentsSuppliers,
																				isLoadingDocumentsSuppliers,
																				paginationDocumentsSuppliers,
																				refetchDocumentsSuppliers,
																				setDocumentsSuppliers,
																				setPaginationDocumentsSuppliers,
																			}}>
																			<ContextUsersPermissions.Provider
																				value={{
																					isLoadingUsersPermissions,
																					paginationUsersPermissions,
																					refetchUsersPermissions,
																					setUsersPermissions,
																					setPaginationUsersPermissions,
																					usersPermissions,
																				}}>
																				{banner && <Banner {...banner} />}
																				{renderTemplate()}
																			</ContextUsersPermissions.Provider>
																		</ContextDocumentsSuppliers.Provider>
																	</ContextDocumentsBeneficiaries.Provider>
																</ContextHistoricalTransactions.Provider>
															</ContextOpEx.Provider>
														</ContextTransfers.Provider>
													</ContextCashBenefits.Provider>
												</ContextPayments.Provider>
											</ContextContracts.Provider>
										</ContextAccounts.Provider>
									</ContextBeneficiaries.Provider>
								</ContextSuppliers.Provider>
							</ContextEntities.Provider>
						</ContextEntitiesSummary.Provider>
					</ContextLoans.Provider>
				</ContextActivities.Provider>
			</ContextProject.Provider>
		</Authenticated>
	);
};
