// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Loan, Metadata } from '../../../@types';

// mui
import { Grid, Typography, Paper } from '@mui/material';

// ui
import { BlockchainHash } from '../../ui/BlockchainHash/BlockchainHash';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// utils
import { truncate } from '../../../utils/strings';
import { translateType } from '../../../utils/translations';

// props
export interface CardEntityDetailsProps {
	loans: Loan[];
	entity: Entity;
}

export const CardEntityDetails = ({ loans, entity }: CardEntityDetailsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const associatedEntities: Entity[] = [];
	const addressEntries: string[] = [];
	const entityMetadata = entity.metadata as Metadata;

	if (entityMetadata?.address1) {
		addressEntries.push(String(entityMetadata.address1));
	}
	if (entityMetadata?.address2) {
		addressEntries.push(String(entityMetadata.address2));
	}

	const fullAddress = addressEntries.length > 0 ? addressEntries.join(' ') : t('na');
	const isSupplier = entity.type === 'SUPPLIER';

	// for each loan
	for (let i = 0; i < loans.length; i++) {
		const entityLoan = loans[i].entities.find((e) => e.id === entity.id);

		if (entityLoan && entityLoan.parent) {
			const entityExists = associatedEntities.find((e) => e.id === entityLoan.parent?.id);

			if (!entityExists) {
				associatedEntities.push(entityLoan.parent);
			}
		}
	}

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
					},
					height: '100%',
				};
			}}>
			<Grid container spacing={4}>
				{!isSupplier && (
					<Grid item xs={6} md={4}>
						<Typography variant="h3" color="brand.grey.500">
							{t('type')}
						</Typography>
						<Typography>{translateType(entity.type, t)}</Typography>
					</Grid>
				)}
				<Grid item xs={6} md={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('country')}
					</Typography>
					<Typography>
						{entityMetadata?.country
							? new Intl.DisplayNames(['en'], { type: 'region' }).of(String(entityMetadata.country))
							: t('na')}
					</Typography>
				</Grid>
				<Grid item xs={6} md={isSupplier ? 8 : 4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('associatedEntities')}
					</Typography>
					<Grid alignItems="center" container spacing={1}>
						{associatedEntities.length ? (
							<>
								<Grid item flex={1}>
									{associatedEntities.map((entityParent, i) => {
										const isLast = i === associatedEntities.length - 1;
										return (
											<>
												<LinkEntity
													entity={{
														...entityParent,
														name: truncate({ end: 20, str: entityParent.name, suffix: '...' }),
													}}
													key={`entity-${i}`}
												/>
												{!isLast ? ', ' : ''}
											</>
										);
									})}
								</Grid>
							</>
						) : (
							<Grid item>
								<Typography>{t('na')}</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item xs={6} md={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('city')}
					</Typography>
					<Typography>{entityMetadata?.city ? entityMetadata.city : t('na')}</Typography>
				</Grid>
				<Grid item xs={6} md={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('address')}
					</Typography>
					<Typography>{fullAddress}</Typography>
				</Grid>
				<Grid item xs={6} md={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('wallet')}
					</Typography>
					<BlockchainHash hash={entity.wallet?.address} />
				</Grid>
			</Grid>
		</Paper>
	);
};
