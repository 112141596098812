// mui
import { Typography, TypographyProps } from '@mui/material';

// utils
import { formatDate, toUTC } from '../../../utils/dates';

// props
export interface TimeProps {
	date: Date | null | string | undefined;
	TypographyProps?: TypographyProps;
}

export const Time = ({ date, TypographyProps }: TimeProps) => {
	if (!date) {
		return <></>;
	}

	const dateUtc = toUTC(date);

	return (
		<Typography color="brand.grey.500" component="span" {...TypographyProps}>
			<time dateTime={dateUtc.toISOString()}>{formatDate({ date: dateUtc })}</time>
		</Typography>
	);
};
