// react
import { ChangeEvent, useState } from 'react';

// mui
import {
	Box,
	Radio as MuiRadio,
	RadioProps as MuiRadioProps,
	Grid,
	InputLabel,
	Typography,
	TypographyProps,
	InputLabelProps,
	RadioGroup,
	RadioGroupProps,
	BoxProps,
} from '@mui/material';

// props
export interface RadioProps extends MuiRadioProps {
	description?: TypographyProps;
	label?: InputLabelProps;
}

export interface RadioBoxesProps extends RadioGroupProps {
	BoxProps?: BoxProps;
	label?: TypographyProps;
	options: RadioProps[];
}

export const RadioBoxes = ({ BoxProps = {}, label, options, ...props }: RadioBoxesProps) => {
	// state
	const [value, setValue] = useState(options[0].value);

	const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	return (
		<RadioGroup name="scope" onChange={handleChangeRadio} value={value} {...props}>
			<Grid container spacing={2}>
				{options.map((option) => {
					const { description, disabled, id, label, required, ...radioProps } = option;
					const isChecked = value === radioProps.value;

					return (
						<Grid item key={`radio-box-${id}`} xs={12}>
							<Box
								sx={(theme) => {
									return {
										transition: 'all 200ms',
										backgroundColor: isChecked ? theme.palette.brand.grey[100] : 'transparent',
										borderColor: isChecked ? theme.palette.brand.blue[500] : theme.palette.divider,
										borderRadius: 2,
										borderStyle: 'solid',
										borderWidth: 1,
										padding: theme.spacing(2, 1.5, 2, 2),
										position: 'relative',
										'&:hover': {
											backgroundColor: theme.palette.brand.grey[100],
										},
										...{ ...BoxProps },
									};
								}}>
								<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
									<Grid item>
										<InputLabel
											disabled={disabled}
											htmlFor={id}
											required={required}
											sx={{
												position: 'static',
												margin: 0,
												'&::after': {
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													content: '""',
												},
											}}
											{...label}
										/>
										{description && (
											<Typography color="brand.grey.500" variant="caption" {...description} />
										)}
									</Grid>
									<Grid item>
										<MuiRadio
											checked={isChecked}
											disabled={disabled}
											id={id}
											required={required}
											{...radioProps}
										/>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					);
				})}
			</Grid>
		</RadioGroup>
	);
};
