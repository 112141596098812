// react
import { MouseEvent, ReactNode } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import {
	Box,
	Button,
	ButtonProps,
	Grid,
	GridProps,
	IconButton,
	Popover as MuiPopover,
	PopoverProps as MuiPopoverProps,
	Typography,
	TypographyProps,
} from '@mui/material';

// icons
import { ClearOutlined as IconClear } from '@mui/icons-material';

// theme
import { theme } from '../../../theme';

// types
export interface PopoverButton extends ButtonProps {
	GridProps?: GridProps;
}

export interface PopoverProps {
	actions: PopoverButton[];
	children: ReactNode;
	hasClose?: boolean;
	PopoverProps: MuiPopoverProps;
	title?: TypographyProps;
}

export const Popover = ({
	actions,
	children,
	hasClose = true,
	PopoverProps,
	title,
}: PopoverProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const { onClose, ...propsPopover } = PopoverProps;

	const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
		if (onClose) {
			onClose(e, 'backdropClick');
		}
	};

	return (
		<MuiPopover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			disablePortal={true}
			onClose={onClose}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...propsPopover}>
			<Box sx={{ position: 'relative', padding: theme.spacing(2), width: '350px' }}>
				<Grid container spacing={theme.spacing(2)}>
					{title && (
						<Grid item xs={12}>
							<Typography {...title} variant="h3" />
						</Grid>
					)}
					<Grid item xs={12}>
						{children}
					</Grid>
					{actions.length > 0 && (
						<Grid item xs={12}>
							<Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
								{actions.map((action, i) => {
									const { GridProps = {}, ...btnProps } = action;
									return (
										<Grid item key={`action-${i}`} {...GridProps}>
											<Button {...btnProps} />
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					)}
				</Grid>
			</Box>
			{hasClose && (
				<IconButton
					aria-label={t('close')}
					onClick={handleClose}
					size="small"
					sx={(theme) => {
						return {
							position: 'absolute',
							top: theme.spacing(1),
							right: theme.spacing(1),
						};
					}}>
					<IconClear />
				</IconButton>
			)}
		</MuiPopover>
	);
};
