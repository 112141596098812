export const accountEntityRelationships = ['ASSOCIATE', 'OWNER'] as const;

export const accountStatuses = ['ACTIVE', 'DEACTIVATED'] as const;

export const accountTypes = ['DESIGNATED', 'LOCAL'] as const;

export const blkTransactionStatuses = ['DONE', 'FAILED', 'STARTED'] as const;

export const countryCodes = [
	'AF',
	'AL',
	'DZ',
	'AS',
	'AD',
	'AO',
	'AI',
	'AQ',
	'AG',
	'AR',
	'AM',
	'AW',
	'AU',
	'AT',
	'AZ',
	'BS',
	'BH',
	'BD',
	'BB',
	'BY',
	'BE',
	'BZ',
	'BJ',
	'BM',
	'BT',
	'BO',
	'BQ',
	'BA',
	'BW',
	'BV',
	'BR',
	'IO',
	'BN',
	'BG',
	'BF',
	'BI',
	'CV',
	'KH',
	'CM',
	'CA',
	'KY',
	'CF',
	'TD',
	'CL',
	'CN',
	'CX',
	'CC',
	'CO',
	'KM',
	'CD',
	'CG',
	'CK',
	'CR',
	'HR',
	'CU',
	'CW',
	'CY',
	'CZ',
	'CI',
	'DK',
	'DJ',
	'DM',
	'DO',
	'EC',
	'EG',
	'SV',
	'GQ',
	'ER',
	'EE',
	'SZ',
	'ET',
	'FK',
	'FO',
	'FJ',
	'FI',
	'FR',
	'GF',
	'PF',
	'TF',
	'GA',
	'GM',
	'GE',
	'DE',
	'GH',
	'GI',
	'GR',
	'GL',
	'GD',
	'GP',
	'GU',
	'GT',
	'GG',
	'GN',
	'GW',
	'GY',
	'HT',
	'HM',
	'VA',
	'HN',
	'HK',
	'HU',
	'IS',
	'IN',
	'ID',
	'IR',
	'IQ',
	'IE',
	'IM',
	'IL',
	'IT',
	'JM',
	'JP',
	'JE',
	'JO',
	'KZ',
	'KE',
	'KI',
	'KP',
	'KR',
	'KW',
	'KG',
	'LA',
	'LV',
	'LB',
	'LS',
	'LR',
	'LY',
	'LI',
	'LT',
	'LU',
	'MO',
	'MG',
	'MW',
	'MY',
	'MV',
	'ML',
	'MT',
	'MH',
	'MQ',
	'MR',
	'MU',
	'YT',
	'MX',
	'FM',
	'MD',
	'MC',
	'MN',
	'ME',
	'MS',
	'MA',
	'MZ',
	'MM',
	'NA',
	'NR',
	'NP',
	'NL',
	'NC',
	'NZ',
	'NI',
	'NE',
	'NG',
	'NU',
	'NF',
	'MP',
	'NO',
	'OM',
	'PK',
	'PW',
	'PS',
	'PA',
	'PG',
	'PY',
	'PE',
	'PH',
	'PN',
	'PL',
	'PT',
	'PR',
	'QA',
	'MK',
	'RO',
	'RU',
	'RW',
	'RE',
	'BL',
	'SH',
	'KN',
	'LC',
	'MF',
	'PM',
	'VC',
	'WS',
	'SM',
	'ST',
	'SA',
	'SN',
	'RS',
	'SC',
	'SL',
	'SG',
	'SX',
	'SK',
	'SI',
	'SB',
	'SO',
	'ZA',
	'GS',
	'SS',
	'ES',
	'LK',
	'SD',
	'SR',
	'SJ',
	'SE',
	'CH',
	'SY',
	'TW',
	'TJ',
	'TZ',
	'TH',
	'TL',
	'TG',
	'TK',
	'TO',
	'TT',
	'TN',
	'TR',
	'TM',
	'TC',
	'TV',
	'UG',
	'UA',
	'AE',
	'GB',
	'UM',
	'US',
	'UY',
	'UZ',
	'VU',
	'VE',
	'VN',
	'VG',
	'VI',
	'WF',
	'EH',
	'YE',
	'ZM',
	'ZW',
	'AX',
] as const;

export const documentCategories = ['HISTORICAL_TRANSACTIONS', 'IDENTIFICATION', 'LICENSE'] as const;

export const entitySubtypes = ['INDIVIDUAL', 'ORGANIZATION'] as const;

export const entityTypes = [
	'BENEFICIARY',
	'BORROWER',
	'INTERMEDIARY',
	'PIU',
	'SUB_PIU',
	'SUPPLIER',
] as const;

export const errorIds = [
	'ACCOUNT_DUPLICATE',
	'ACCOUNT_ID_NON_EXISTENT',
	'ACCOUNT_INVALID_ENTITY_TRANSACTIONS',
	'ACCOUNT_INVALID_RELATIONSHIP',
	'ACCOUNT_INVALID_TYPE',
	'ACCOUNT_NON_EXISTENT',
	'ACCOUNT_OWNER_ASSOCIATE',
	'ACCOUNT_OWNER_NON_EXISTENT',
	'ACCOUNT_PROJECT_NON_EXISTENT',
	'ACCOUNT_UNAUTHORIZED_GET',
	'ACCOUNT_UNAUTHORIZED_PATCH',
	'ACCOUNT_UNAUTHORIZED_POST',
	'ACTIVITY_INVALID_TYPE',
	'ACTIVITY_UNAUTHORIZED_GET',
	'BLOCKCHAIN_TRANSACTION_FAILED',
	'BULK_UPLOAD_FIELD_EMPTY',
	'BULK_UPLOAD_INCOMPLETE',
	'CONTRACT_DUPLICATE',
	'CONTRACT_ID_NON_EXISTENT',
	'CONTRACT_NON_EXISTENT',
	'CONTRACT_SUPPLIER_EMPTY',
	'CONTRACT_UNAUTHORIZED_GET',
	'CONTRACT_UNAUTHORIZED_POST',
	'COUNTRY_CODE_DUPLICATE',
	'COUNTRY_CODE_INVALID',
	'COUNTRY_DUPLICATE',
	'DOCUMENT_FILE_NON_EXISTENT',
	'DOCUMENT_INSERT_FAIL',
	'DOCUMENT_INVALID_CONTENT_TYPE',
	'DOCUMENT_INVALID_MIME_TYPE',
	'DOCUMENT_TRANSACTION_NON_EXISTENT',
	'DOCUMENT_UNAUTHORIZED_GET',
	'DOCUMENT_UNAUTHORIZED_POST',
	'DOCUMENT_TYPE_NAME_NON_EXISTENT',
	'DOCUMENT_TYPE_UNAUTHORIZED_POST',
	'ENTITY_DUPLICATE',
	'ENTITY_INVALID_TYPE',
	'ENTITY_KYC_UNVERIFIED',
	'ENTITY_LOANS_IDENTICAL',
	'ENTITY_PARENT_NON_EXISTENT',
	'ENTITY_UNAUTHORIZED_GET',
	'ENTITY_UNAUTHORIZED_PATCH',
	'ENTITY_UNAUTHORIZED_POST',
	'ENTITY_WALLET_NON_EXISTENT',
	'ENTITY_WALLET_RELATION_NON_EXISTENT',
	'EXPENSE_TYPE_NAME_NON_EXISTENT',
	'EXPENSE_TYPE_UNAUTHORIZED_POST',
	'FINANCING_SOURCE_NOT_SELECTED',
	'FORM_FIELD_CHECKBOX_INVALID',
	'FORM_FIELD_DATE_INVALID',
	'FORM_FIELD_NUMBER_INVALID',
	'FORM_FIELD_SELECT_INVALID',
	'GRAPH_UNAUTHORIZED',
	'GRAPH_USER_NOT_FOUND',
	'INVALID_COLUMN',
	'LOAN_DUPLICATE',
	'LOAN_ENTITY_DUPLICATE',
	'LOAN_INVALID_STATUS',
	'LOAN_NON_EXISTENT',
	'LOAN_UNAUTHORIZED_GET',
	'LOAN_UNAUTHORIZED_PATCH',
	'LOAN_UNAUTHORIZED_POST',
	'ME_UNAUTHORIZED_GET',
	'NON_EXISTENT_CURRENCY',
	'PROJECT_ALREADY_ONBOARDED',
	'PRACTICE_DUPLICATE',
	'PROJECT_ACCOUNT_NON_EXISTENT',
	'PROJECT_DUPLICATE',
	'PROJECT_ENTITY_NON_EXISTENT',
	'PROJECT_LOAN_DUPLICATE',
	'PROJECT_LOAN_NON_EXISTENT',
	'PROJECT_UNAUTHORIZED_GET',
	'PROJECT_UNAUTHORIZED_POST',
	'QUEUE_FAILURE',
	'REGION_DUPLICATE',
	'REPORT_INVALID_TYPE',
	'TRANSACTION_ACCOUNTS_SAME',
	'TRANSACTION_AMOUNT_NON_EXISTENT',
	'TRANSACTION_DOCUMENTS_NON_EXISTENT',
	'TRANSACTION_ENTITY_NOT_FOUND',
	'TRANSACTION_INSUFFICIENT_FUNDS',
	'TRANSACTION_INVALID_TYPE',
	'TRANSACTION_RECEIVED_AMOUNT_NON_EXISTENT',
	'TRANSACTION_UNAUTHORIZED_GET',
	'TRANSACTION_UNAUTHORIZED_POST',
	'TRANSACTION_PATCH_INCOMPLETE',
	'UNKNOWN',
	'USER_DUPLICATE',
	'USER_EMAIL_NON_EXISTENT',
	'USER_FIRST_NAME_NON_EXISTENT',
	'USER_GROUP_INVALID',
	'USER_INVALID_ROLE',
	'USER_LAST_NAME_NON_EXISTENT',
	'USER_PERMISSION_NON_EXISTENT',
	'USER_UNAUTHORIZED_GET',
	'USER_UNAUTHORIZED_PATCH',
	'USER_UNAUTHORIZED_POST',
	'WALLET_CREATION',
	'WALLET_INACTIVE',
	'WALLET_UPDATE',
	'WITHDRAWAL_APPLICATION_ID_DUPLICATE',
] as const;

export const exportResources = ['CASH_BENEFIT', 'OPEX', 'PAYMENT', 'TRANSFER'] as const;

export const filterTypes = ['AUTOCOMPLETE', 'CURRENCY', 'INPUT', 'SELECT'] as const;

export const financingSources = ['LOAN', 'GOVERNMENT', 'OTHER'] as const;

export const formFieldTypes = [
	'CHECKBOX',
	'DATE',
	'EMAIL',
	'NUMBER',
	'SELECT',
	'TEXT',
	'TIME',
	'URL',
] as const;

export const formNames = ['BENEFICIARY_INDIVIDUAL', 'BENEFICIARY_ORGANIZATION'] as const;

export const loanStatuses = ['DISABLED', 'ENABLED'] as const;

export const orders = ['ASC', 'DESC'] as const;

export const paymentStatuses = ['DONE', 'FAILED', 'STARTED'] as const;

export const periods = ['MONTH', 'QUARTER', 'YEAR'] as const;

export const projectStatuses = ['ENABLED', 'PENDING'] as const;

export const reportTypes = ['BENEFICIARIES', 'FUND_ALLOCATION', 'SOE', 'SUPPLIERS'] as const;

export const transactionStatuses = ['COMPLETED', 'FAILED', 'IN_PROGRESS', 'WAITING'] as const;

export const transactionTypes = [
	'CASH_BENEFIT',
	'INITIAL',
	'OPEX',
	'PAYMENT',
	'REPLENISHMENT',
	'TRANSFER',
] as const;

export const userRoles = ['GENERAL', 'LIAISON', 'STAFF', 'TTL', 'WFA'] as const;

export const userStatuses = ['ACTIVE', 'CREATED', 'DEACTIVATED', 'INVITED'] as const;

export const walletStatuses = ['ACTIVE', 'INACTIVE'] as const;
