// react
import { ReactNode, useEffect, useMemo, useState } from 'react';

// types
import { Option } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	Select as MuiSelect,
	SelectChangeEvent,
	SelectProps as MuiSelectProps,
} from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// props
export type SelectProps = {
	IconCircleProps?: IconCircleProps;
	hasAllOption?: boolean;
	hasNoneOption?: boolean;
	hasSearchParam?: boolean;
	helperText?: string;
	options?: Option[];
} & MuiSelectProps;

export const Select = ({
	defaultValue,
	disabled,
	fullWidth = true,
	helperText,
	hasAllOption = false,
	hasNoneOption = true,
	hasSearchParam = false,
	IconCircleProps,
	id,
	label,
	multiple = false,
	name,
	onChange,
	options = [],
	required,
	size = 'small',
	value,
	variant = 'outlined',
	...props
}: SelectProps) => {
	// hooks
	const { t } = useTranslation();

	// add none option
	if (!required && hasNoneOption && !multiple) {
		if (!options.find((option) => option.label === t('none'))) {
			options.unshift({
				label: t('none'),
				value: '',
			});
		}
	}

	// defaults
	const defaultSelected = useMemo(() => {
		let valueNew: unknown = multiple ? [] : '';

		if (defaultValue) {
			valueNew = defaultValue;
		} else if (value) {
			valueNew = value;
		}

		return valueNew;
	}, [defaultValue, multiple, value]);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [selected, setSelected] = useState<unknown | unknown[]>(defaultSelected);

	// vars
	const isSelectedArray = Array.isArray(selected);
	const isAllOptionsDisabled = options.filter((o) => o.disabled).length === options.length;
	const isAllSelected = isSelectedArray && options.length > 0 && selected.length === options.length;
	const optionsEnabled = options.filter((option) => !option.disabled);

	// handlers
	const handleChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
		const { value } = e.target;
		const param = String(name);

		let selectedNew;

		// if value is array
		if (Array.isArray(value)) {
			const isValueAll = value[value.length - 1] === 'all';

			selectedNew = value;

			if (isValueAll) {
				// check or uncheck all enabled options
				if (isSelectedArray && selected.length === optionsEnabled.length) {
					selectedNew = [];
				} else {
					selectedNew = optionsEnabled.map((option) => option.value);
				}
			}
		} else {
			selectedNew = value as string;
		}

		if (hasSearchParam) {
			// if value is empty or all are selected
			if (!selectedNew.length || selectedNew.length === options.length) {
				// delete search param
				searchParams.delete(param);
			} else if (Array.isArray(selectedNew)) {
				// set search param as comma separated list
				searchParams.set(param, selectedNew.join(','));
			} else {
				// set search param
				searchParams.set(param, String(selectedNew));
			}

			// set search params
			setSearchParams(searchParams);
		}

		// set selected
		setSelected(selectedNew);

		// redefine value as selected
		e.target.value = selectedNew;

		if (onChange) {
			onChange(e, child);
		}
	};

	// render
	const renderValue = (val: unknown) => {
		// if value is array
		if (Array.isArray(selected)) {
			if (hasAllOption && isAllSelected) {
				return t('all');
			}

			// create array of labels using values
			const labels = [];

			// for each value
			for (let v = 0; v < selected.length; v++) {
				// find option
				const option = options.find((o) => o.value === selected[v]);

				// add option
				if (option) {
					labels.push(option.label);
				}
			}

			// set value as comma separated list
			return labels.join(', ');
		} else {
			if (selected === 'all') {
				return t('all');
			}

			// find option
			const option = options.find((o) => o.value === val);

			// set value as option label
			if (option) {
				return option.label;
			}
		}
	};

	// define conditional props
	const selectProps: MuiSelectProps = {};

	if (IconCircleProps) {
		selectProps.startAdornment = (
			<InputAdornment position="start" sx={{ marginRight: `6px !important` }}>
				<IconCircle size="xs" {...IconCircleProps} />
			</InputAdornment>
		);
	}

	// set selected when value changes
	useEffect(() => {
		if (value) {
			setSelected(value);
		}
	}, [value]);

	return (
		<FormControl sx={{ width: '100%' }}>
			{label && (
				<InputLabel
					disabled={disabled}
					htmlFor={id}
					required={required}
					shrink={false}
					sx={{ position: 'static', transform: 'none' }}>
					{label}
				</InputLabel>
			)}

			<MuiSelect
				defaultValue={defaultValue}
				disabled={disabled}
				fullWidth={fullWidth}
				id={id}
				multiple={multiple}
				name={name}
				onChange={handleChange}
				renderValue={renderValue}
				required={required}
				size={size}
				value={value !== undefined ? value : selected}
				variant={variant}
				{...selectProps}
				{...props}>
				{hasAllOption && (
					<MenuItem dense={true} disabled={isAllOptionsDisabled} disableRipple={true} value="all">
						{multiple && (
							<Checkbox
								checked={isAllSelected}
								indeterminate={
									isSelectedArray && selected.length > 0 && selected.length < options.length
								}
							/>
						)}
						<ListItemText
							primary={t('all')}
							primaryTypographyProps={{
								color: 'brand.grey.800',
							}}
						/>
					</MenuItem>
				)}
				{options.map((option, i) => {
					return (
						<MenuItem
							dense={true}
							disabled={option.disabled}
							disableRipple={true}
							key={`menu-item-${option.value}`}
							value={option.value}>
							{multiple && <Checkbox checked={(selected as unknown[]).includes(option.value)} />}
							<ListItemText
								primary={option.label}
								primaryTypographyProps={{
									color: 'brand.grey.800',
								}}
								secondary={option.secondary}
								secondaryTypographyProps={{
									color: 'brand.grey.500',
									variant: 'caption',
								}}
							/>
						</MenuItem>
					);
				})}
			</MuiSelect>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
