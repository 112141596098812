// react
import { ReactNode, useContext, useMemo, useState } from 'react';

// types
import { ActionButton, Entity, Pagination, Transaction } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// context
import { ContextProject } from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// ui
import { BtnExport } from '../../../ui/BtnExport/BtnExport';
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { FilterChangeEvent } from '../../../ui/Filters/Filters';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardTransaction } from '../../../cards/CardTransaction/CardTransaction';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { PaidOutlined as IconPaid } from '@mui/icons-material';

// utils
import { translateType } from '../../../../utils/translations';

// props
export interface TableOpExProps {
	isLoading?: boolean;
	pagination: Pagination;
	refetchTransactions?: () => void;
	transactions: Transaction[];
}

export const TableOpEx = ({
	isLoading,
	pagination,
	refetchTransactions,
	transactions,
}: TableOpExProps) => {
	// context
	const { project } = useContext(ContextProject);

	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramEntity = searchParams.get('entity');
	const paramFromEntity = searchParams.get('fromEntity');
	const paramQ = searchParams.get('q');

	// defaults
	const defaultFiltersValues = {
		fromEntity: 'all',
	};

	// state
	const [entities, setEntities] = useState<Entity[]>([]);
	const [filtersValues, setFiltersValues] = useState({
		fromEntity: paramFromEntity || defaultFiltersValues.fromEntity,
	});

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch entities
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,type&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// vars
	const idFormFilters = 'form-opex-filters';

	const headers = [
		{
			label: t('date'),
			lg: 2,
			value: 'date',
		},
		{
			label: t('expenseType'),
			lg: 1.5,
			value: 'expenseType',
		},
		{
			label: t('entity'),
			lg: 2,
			value: 'entity',
		},
		{
			label: t('vendor'),
			lg: 1.5,
			value: 'vendor',
		},
		{
			label: t('status'),
			lg: 1.5,
			value: 'status',
		},
		{
			label: t('account'),
			lg: 1,
			value: 'account',
		},
		{
			label: t('amount'),
			lg: 1.5,
			value: 'amount',
		},
	];

	// options
	const optionsEntities = entities.map((entity) => {
		return {
			label: entity.name,
			secondary: translateType(entity.type, t),
			value: String(entity.id),
		};
	});

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('recordOpEx'),
					onClick: () => {
						searchParams.set('dialog', 'recordOpEx');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.opex.content') },
			IconCircleProps: { icon: (<IconPaid />) as ReactNode },
			title: { children: t('empty.opex.title') },
		};

		if (paramEntity || paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramEntity, paramQ, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoading || !transactions.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{transactions.map((transaction, i) => {
					return (
						<Grid item key={`entity-${i}`} xs={12}>
							<CardTransaction
								refetchTransactions={refetchTransactions}
								transaction={transaction}
							/>
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, isLoading, refetchTransactions, transactions]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={true}>
						<FormSearch />
					</Grid>
					<Grid item xs={6} md="auto">
						<BtnExport resource="OPEX" />
					</Grid>
					<Grid item xs={6} md="auto">
						<BtnFilters
							filters={[
								{
									hasAllOption: true,
									hasNoneOption: false,
									hasSearchParam: true,
									id: `${idFormFilters}-entity`,
									label: t('entity'),
									name: 'fromEntity',
									onChange: handleChangeFilterValue,
									options: optionsEntities,
									type: 'SELECT',
									value: filtersValues.fromEntity,
								},
							]}
							onReset={() => {
								setFiltersValues(defaultFiltersValues);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={transactions.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
