// react
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';

// types
import {
	AccountBalancePayload,
	AccountPayload,
	EntityLoanPayload,
	EntityPayload,
	ErrorCause,
	Option,
	Project,
	ProjectPayload,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Box, Grid, SxProps, Typography } from '@mui/material';

// fields
import { Select } from '../../fields/Select/Select';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// forms
import { EntityOnboard } from './EntityOnboard/EntityOnboard';

// props
export interface FormOnboardProjectProps {
	onClose: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLFormElement>) => void;
	onSuccess: (e: MouseEvent<HTMLFormElement>, project: Project) => void;
	project: ProjectPayload;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormOnboardProject = ({
	onClose,
	onSuccess,
	project,
	setIsLoading,
}: FormOnboardProjectProps) => {
	// hooks
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// defaults
	const accounts: AccountPayload[] = [];
	const entities: EntityPayload[] = [];

	// define unique accounts and entities
	for (let l = 0; l < project.loans.length; l++) {
		for (let a = 0; a < project.loans[l].accounts.length; a++) {
			const account = project.loans[l].accounts[a];
			const accountExisting = accounts.find((acc) => acc.idWbg === account.idWbg);
			if (!accountExisting) {
				accounts.push({
					...account,
					balances: [],
				});
			}
		}

		for (let e = 0; e < project.loans[l].entities.length; e++) {
			const entity = project.loans[l].entities[e];
			const entityExisting = entities.find((ent) => ent.idWbg === entity.idWbg);
			if (!entityExisting) {
				entity.loans = [];
				entities.push(entity);
			}
		}
	}

	// state
	const [entitiesSelected, setEntitiesSelected] = useState<EntityPayload[]>(entities);
	const [error, setError] = useState<string | null>(null);

	// GET country
	const { fetchRequest: getCountry } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/countries?name=${project?.country?.name}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
	});

	// GET region
	const { fetchRequest: getRegion } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/regions?name=${project?.region?.name}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
	});

	// GET practice
	const { fetchRequest: getPractice } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/practices?name=${project?.practice?.name}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
	});

	// GET projects
	const { fetchRequest: getProject } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/projects?idWbg=${project.idWbg}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
	});

	// POST country
	const { fetchRequest: postCountry } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/countries`,
		options: {
			method: 'post',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify(project.country),
		},
	});

	// POST region
	const { fetchRequest: postRegion } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/regions`,
		options: {
			method: 'post',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify(project.region),
		},
	});

	// POST practice
	const { fetchRequest: postPractice } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/practices`,
		options: {
			method: 'post',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify(project.practice),
		},
	});

	// vars
	const optionsEntities: Option[] = [];

	// define entities options
	for (let i = 0; i < entitiesSelected.length; i++) {
		const entity = entitiesSelected[i];

		optionsEntities.push({
			label: String(entity?.name),
			value: String(entity?.idWbg),
		});
	}

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// start loader
		setIsLoading(true);

		// hide error
		setError(null);

		try {
			const form = document.querySelector('#form-onboard-project') as HTMLFormElement;
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());
			const entityMap: Record<string, number> = {};

			let country;
			let practice;
			let projectNew;
			let region;

			// check if loans exist
			if (!project.loans.length) {
				throw new Error(`The project must be associated with loans.`, {
					cause: {
						id: 'PROJECT_LOAN_NON_EXISTENT',
					},
				});
			}

			// check if entities exist
			if (!entitiesSelected.length) {
				throw new Error(`The project must be associated with at least one entity.`, {
					cause: {
						id: 'PROJECT_ENTITY_NON_EXISTENT',
					},
				});
			}

			// check if accounts exist
			if (!accounts.length) {
				throw new Error(`The project must be associated with at least one account.`, {
					cause: {
						id: 'PROJECT_ACCOUNT_NON_EXISTENT',
					},
				});
			}

			// for each selected entity
			for (let e = 0; e < entitiesSelected.length; e++) {
				// define entity
				const entity = entitiesSelected[e];

				// check if the users have required fields
				if (entity.users) {
					for (const user of entity.users) {
						// check if first name exists
						if (!user.firstName) {
							throw new Error(`The user with the email ${user.email} is missing a first name.`, {
								cause: {
									id: 'USER_FIRST_NAME_NON_EXISTENT',
									details: {
										email: user.email,
										entity: entity.name,
									},
								},
							});
						}

						// check if last name exists
						if (!user.lastName) {
							throw new Error(`The user with the email ${user.email} is missing a last name.`, {
								cause: {
									id: 'USER_LAST_NAME_NON_EXISTENT',
									details: {
										email: user.email,
										entity: entity.name,
									},
								},
							});
						}

						// check if email exists
						if (!user.email) {
							const userFullName = `${user.firstName} ${user.lastName}`;

							throw new Error(`The user ${userFullName} is missing an email.`, {
								cause: {
									id: 'USER_EMAIL_NON_EXISTENT',
									details: {
										name: userFullName,
										entity: entity.name,
									},
								},
							});
						}
					}
				}
			}

			// GET or POST country
			if (project.country) {
				const resGetCountry = await getCountry();

				if (resGetCountry.error) {
					throw new Error(resGetCountry.error.message, {
						cause: {
							id: resGetCountry.error.id,
						},
					});
				}

				if (resGetCountry.data.length) {
					country = resGetCountry.data[0];
				} else {
					const resPostCountry = await postCountry();

					if (resPostCountry.error) {
						throw new Error(resPostCountry.error.message, {
							cause: {
								id: resPostCountry.error.id,
							},
						});
					}

					if (resPostCountry.data) {
						country = resPostCountry.data;
					}
				}
			}

			// GET or POST region
			if (project.region) {
				const resGetRegion = await getRegion();

				if (resGetRegion.error) {
					throw new Error(resGetRegion.error.message, {
						cause: {
							id: resGetRegion.error.id,
						},
					});
				}

				if (resGetRegion.data.length) {
					region = resGetRegion.data[0];
				} else if (project.region?.name) {
					const resPostRegion = await postRegion();

					if (resPostRegion.error) {
						throw new Error(resPostRegion.error.message, {
							cause: {
								id: resPostRegion.error.id,
							},
						});
					}

					if (resPostRegion.data) {
						region = resPostRegion.data;
					}
				}
			}

			// GET or POST practice
			if (project.practice) {
				const resGetPractice = await getPractice();

				if (resGetPractice.error) {
					throw new Error(resGetPractice.error.message, {
						cause: {
							id: resGetPractice.error.id,
						},
					});
				}

				if (resGetPractice.data.length) {
					practice = resGetPractice.data[0];
				} else if (project.practice?.name) {
					const resPostPractice = await postPractice();

					if (resPostPractice.error) {
						throw new Error(resPostPractice.error.message, {
							cause: {
								id: resPostPractice.error.id,
							},
						});
					}

					if (resPostPractice.data) {
						practice = resPostPractice.data;
					}
				}
			}

			// GET project
			const resGetProject = await getProject();

			if (resGetProject.error) {
				throw new Error(resGetProject.error.message, {
					cause: {
						id: resGetProject.error.id,
					},
				});
			}

			// if project exists
			if (resGetProject.data.length) {
				// define new project
				projectNew = resGetProject.data[0];
			} else {
				// otherwise POST project
				const fetchPostProject = await fetch(`${process.env.REACT_APP_API_URL}/projects`, {
					method: 'post',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
					body: JSON.stringify({
						idWbg: project.idWbg,
						country: country.id,
						name: project.name,
						practice: practice?.id,
						region: region?.id,
						status: 'ENABLED',
					}),
				});
				const resPostProject = await fetchPostProject.json();

				// if error
				if (resPostProject.error) {
					throw new Error(resPostProject.error.message, {
						cause: {
							id: resPostProject.error.id,
						},
					});
				}

				// define new project
				projectNew = resPostProject.data;
			}

			// for each project user
			for (let i = 0; i < project.users.length; i++) {
				const user = project.users[i];

				// GET user
				const fetchGetUser = await fetch(
					`${process.env.REACT_APP_API_URL}/users?firstName=${user.firstName}&lastName=${user.lastName}&role=${user.role}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
					}
				);

				const resGetUser = await fetchGetUser.json();

				if (resGetUser.data.length) {
					console.log(
						`A user named ${user.firstName} ${user.lastName} with the role ${user.role} already exists.`
					);
				} else {
					// POST user
					const fetchPostUser = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
						method: 'post',
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
						body: JSON.stringify({
							firstName: user.firstName,
							lastName: user.lastName,
							project: projectNew.id,
							role: user.role,
						}),
					});

					const resPostUser = await fetchPostUser.json();

					// if error
					if (resPostUser.error) {
						throw new Error(resPostUser.error.message, {
							cause: {
								id: resPostUser.error.id,
							},
						});
					}
				}
			}

			// for each loan
			for (let l = 0; l < project.loans.length; l++) {
				// define loan
				const loan = project.loans[l];

				// GET loan
				const fetchGetLoan = await fetch(
					`${process.env.REACT_APP_API_URL}/loans?idWbg=${loan.idWbg}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
					}
				);
				const resGetLoan = await fetchGetLoan.json();

				let idLoan: number;

				// if loan exists
				if (resGetLoan.data.length) {
					console.log(`A loan with the idWbg ${loan.idWbg} already exists.`);

					// define loan id
					idLoan = resGetLoan.data[0].id;
				} else {
					// otherwise POST loan
					const fetchPostLoan = await fetch(`${process.env.REACT_APP_API_URL}/loans`, {
						method: 'post',
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
						body: JSON.stringify({
							accounts: [],
							amount: loan.amount,
							currency: loan.currency,
							dateApproved: loan.dateApproved,
							dateClosing: loan.dateClosing,
							dateEffective: loan.dateEffective,
							dateSigning: loan.dateSigning,
							disbursed: loan.disbursed,
							entities: [],
							financier: loan.financier,
							idWbg: loan.idWbg,
							name: loan.name,
							num: loan.num,
							project: projectNew.id,
							status: loan.status,
							subLoanNum: loan.subLoanNum ? Number(loan.subLoanNum) : null,
							users: [],
						}),
					});
					const resPostLoan = await fetchPostLoan.json();

					// if error
					if (resPostLoan.error) {
						throw new Error(resPostLoan.error.message, {
							cause: {
								id: resPostLoan.error.id,
							},
						});
					} else {
						// otherwise define loan id
						idLoan = resPostLoan.data.id;
					}
				}

				// for each loan entity
				for (let e = 0; e < loan.entities.length; e++) {
					// define entity
					const entity = loan.entities[e];

					// find entity in selected entities
					const entityFound = entitiesSelected.find((ent) => ent.idWbg === entity.idWbg);

					if (entityFound) {
						// add loan to entity
						console.log(`Adding loan ${idLoan} to entity ${entity.idWbg}`);
						(entityFound.loans as unknown as EntityLoanPayload[]).push({
							id: idLoan,
							parent: null,
						});
					}
				}

				// for each loan account
				for (let a = 0; a < loan.accounts.length; a++) {
					// define account
					const account = loan.accounts[a];

					// find account in array of unique accounts
					const accountFound = accounts.find((acc) => acc.idWbg === account.idWbg);

					if (accountFound?.balances && account.balances) {
						// define loan account balances
						const balancesByLoan = account.balances.map((balance: AccountBalancePayload) => {
							return {
								...balance,
								loan: idLoan,
								source: 'LOAN',
							};
						});

						// concatenate loan balances with existing account balances
						accountFound.balances = accountFound.balances.concat(balancesByLoan);
					}
				}
			}

			// for each selected entity
			for (let e = 0; e < entitiesSelected.length; e++) {
				// define entity
				const entity = entitiesSelected[e];

				// GET entity
				const fetchGetEntity = await fetch(
					`${process.env.REACT_APP_API_URL}/entities?idWbg=${entity.idWbg}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
					}
				);
				const resGetEntity = await fetchGetEntity.json();

				let idEntity: number;

				// if entity exists
				if (resGetEntity.data.length) {
					console.log(`An entity with the idWbg ${resGetEntity.data[0].idWbg} already exists.`);

					// define entity id
					idEntity = resGetEntity.data[0].id;

					// add entity map
					if (entity.idWbg) {
						entityMap[entity.idWbg] = idEntity;
					}

					// for each entity loan
					if (entity.loans) {
						for (let l = 0; l < entity.loans.length; l++) {
							const entityLoan = entity.loans[l] as unknown as EntityLoanPayload;

							// POST loan entity relationship
							console.log(`Adding entity ${idEntity} to loan ${entityLoan.id}`);
							const fetchPostLoanEntity = await fetch(
								`${process.env.REACT_APP_API_URL}/loans/${entityLoan.id}/entities/${idEntity}`,
								{
									method: 'post',
									headers: {
										Authorization: `Bearer ${accessToken}`,
										User: String(idToken),
									},
								}
							);
							const resPostLoanEntity = await fetchPostLoanEntity.json();

							// if error
							if (resPostLoanEntity.error) {
								throw new Error(resPostLoanEntity.error.message, {
									cause: {
										id: resPostLoanEntity.error.id,
									},
								});
							}
						}
					}
				} else {
					// define entity type
					const entityType = entries[`entity-${entity.idWbg}-type`];

					// POST entity
					const fetchPostEntity = await fetch(`${process.env.REACT_APP_API_URL}/entities`, {
						method: 'post',
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
						body: JSON.stringify({
							...entity,
							isKycVerified: true,
							project: projectNew.id,
							type: entityType,
							users: [],
						}),
					});
					const resPostEntity = await fetchPostEntity.json();

					// if error
					if (resPostEntity.error) {
						throw new Error(resPostEntity.error.message, {
							cause: {
								id: resPostEntity.error.id,
							},
						});
					} else {
						// otherwise define entity id
						idEntity = resPostEntity.data.id;

						// add entity map
						if (entity.idWbg) {
							entityMap[entity.idWbg] = resPostEntity.data.id;
						}

						// for each entity user
						if (entity.users) {
							for (let u = 0; u < entity.users.length; u++) {
								// define user
								const user = entity.users[u];

								// GET user
								const fetchGetUser = await fetch(
									`${process.env.REACT_APP_API_URL}/users?idWbg=${user.idWbg}`,
									{
										headers: {
											Authorization: `Bearer ${accessToken}`,
											User: String(idToken),
										},
									}
								);
								const resGetUser = await fetchGetUser.json();

								if (resGetUser.data.length) {
									// TODO associate existing user with entity
									console.log(`A user with the idWbg ${user.idWbg} already exists.`);
								} else {
									// POST user
									const fetchPostUser = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
										method: 'post',
										headers: {
											Authorization: `Bearer ${accessToken}`,
											User: String(idToken),
										},
										body: JSON.stringify({
											email: user.email,
											entity: idEntity,
											firstName: user.firstName,
											lastName: user.lastName,
											idWbg: user.idWbg,
											phone: user.phone,
											project: projectNew.id,
											role: user.role,
										}),
									});
									const resPostUser = await fetchPostUser.json();

									// if error
									if (resPostUser.error) {
										throw new Error(resPostUser.error.message, {
											cause: {
												id: resPostUser.error.id,
											},
										});
									}
								}
							}
						}
					}
				}
			}

			// for each unique account
			for (let a = 0; a < accounts.length; a++) {
				// define account
				const account = accounts[a];

				// GET account
				const fetchGetAccount = await fetch(
					`${process.env.REACT_APP_API_URL}/accounts?idWbg=${account.idWbg}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
					}
				);
				const resGetAccount = await fetchGetAccount.json();

				// if account exists
				if (resGetAccount.data.length) {
					// TODO associate existing account with loan
					console.log(`An account with the idWbg ${account.idWbg} already exists.`);
				} else {
					// define account owner
					const accountOwner = String(entries[`account-${account.idWbg}-owner`]);

					const fetchPostAccount = await fetch(`${process.env.REACT_APP_API_URL}/accounts`, {
						method: 'post',
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
						body: JSON.stringify({
							balances: account.balances,
							currency: account.currency,
							entities: [
								{
									id: entityMap[accountOwner],
									relationship: 'OWNER',
								},
							],
							idWbg: account.idWbg,
							institution: account.institution,
							name: account.name,
							num: account.num,
							project: projectNew.id,
							type: 'DESIGNATED',
						}),
					});
					const resPostAccount = await fetchPostAccount.json();

					// if error
					if (resPostAccount.error) {
						throw new Error(resPostAccount.error.message, {
							cause: {
								id: resPostAccount.error.id,
							},
						});
					}
				}
			}

			if (onSuccess) {
				onSuccess(e, projectNew);
			}

			if (onClose) {
				onClose(e);
			}
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				switch (cause.id) {
					case 'PRACTICE_DUPLICATE':
						message = t('error.practiceDuplicate', { id: project.practice.id });
						break;

					case 'PROJECT_ACCOUNT_NON_EXISTENT':
						message = t('error.projectAccountNonExistent');
						break;

					case 'LOAN_ENTITY_DUPLICATE':
						message = t('error.loanEntityDuplicate');
						break;

					case 'PROJECT_ENTITY_NON_EXISTENT':
						message = t('error.projectEntityNonExistent');
						break;

					case 'PROJECT_LOAN_DUPLICATE':
						message = t('error.projectLoanDuplicate');
						break;

					case 'PROJECT_LOAN_NON_EXISTENT':
						message = t('error.projectLoanNonExistent');
						break;

					case 'REGION_DUPLICATE':
						message = t('error.regionDuplicate', { id: project.region.id });
						break;

					case 'USER_EMAIL_NON_EXISTENT':
						message = t('error.userEmailNonExistent', {
							entity: cause.details.entity,
							name: cause.details.name,
						});
						break;

					case 'USER_FIRST_NAME_NON_EXISTENT':
						message = t('error.userFirstNameNonExistent', {
							email: cause.details.email,
							entity: cause.details.entity,
						});
						break;

					case 'USER_LAST_NAME_NON_EXISTENT':
						message = t('error.userLastNameNonExistent', {
							email: cause.details.email,
							entity: cause.details.entity,
						});
						break;

					default:
						message = t('error.server');
				}
			}

			setError(message);
		} finally {
			// end loader
			setIsLoading(false);
		}
	};

	return (
		<form id="form-onboard-project" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>{t('formOnboardProject.intro')}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								borderColor: theme.palette.divider,
								borderRadius: 2,
								borderStyle: 'solid',
								borderWidth: 1,
								overflow: 'hidden',
							};
						}}>
						<Grid container>
							<Grid item xs={12}>
								<Box
									sx={(theme) => {
										return {
											backgroundColor: theme.palette.brand.grey[100],
											borderBottomColor: theme.palette.divider,
											borderBottomStyle: 'solid',
											borderBottomWidth: 1,
											padding: theme.spacing(2),
										};
									}}>
									<Grid alignItems="center" container spacing={2}>
										<Grid item xs={6}>
											<Grid alignItems="center" container spacing={1}>
												<Grid item>
													<IconCircle size="sm" type="entity" />
												</Grid>
												<Grid item flex={1}>
													<Typography fontWeight={600}>{t('entity')}</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={6}>
											<Typography fontWeight={600}>{t('type')}</Typography>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							{entities.length > 0 ? (
								entities
									.sort((a, b) => (a.name > b.name ? 1 : -1))
									.map((entity, indexEntities) => {
										const isLast = indexEntities === entities.length - 1;
										const optionEntity = optionsEntities.find((ent) => ent.label === entity.name);
										const isDisabled = optionEntity?.disabled;

										return (
											<Grid item key={`project-entity-${entity.idWbg}-${indexEntities}`} xs={12}>
												<Box
													sx={(theme) => {
														let sx: SxProps = {};

														if (!isLast) {
															sx = {
																...sx,
																borderBottomColor: theme.palette.divider,
																borderBottomStyle: 'solid',
																borderBottomWidth: 1,
															};
														}

														return sx;
													}}>
													<EntityOnboard
														disabled={isDisabled}
														entity={entity}
														error={isDisabled ? 'This entity has an error' : null}
														onChangeEntity={(e, checked, entity) => {
															// add or remove selected entity
															let entitiesSelectedNew = structuredClone(entitiesSelected);
															if (checked) {
																entitiesSelectedNew.push(entity);
															} else {
																entitiesSelectedNew = entitiesSelectedNew.filter(
																	(e) => e.idWbg !== entity.idWbg
																);
															}
															setEntitiesSelected(entitiesSelectedNew);
														}}
														onChangeUser={(e, checked, user, entity) => {
															// clone entities selected
															const entitiesSelectedNew = structuredClone(entitiesSelected);

															// find entity
															const userEntity = entitiesSelectedNew.find(
																(ent) => ent.idWbg === entity.idWbg
															);

															if (userEntity) {
																const entityUsers = userEntity.users;

																if (entityUsers) {
																	if (checked) {
																		// add user to entity
																		entityUsers.push(user);
																	} else {
																		// remove user from entity
																		userEntity.users = entityUsers.filter(
																			(u) => u.idWbg !== user.idWbg
																		);
																	}
																}
															}

															setEntitiesSelected(entitiesSelectedNew);
														}}
													/>
												</Box>
											</Grid>
										);
									})
							) : (
								<Grid item xs={12}>
									<Alert
										severity="info"
										sx={(theme) => {
											return {
												alignItems: 'center',
												borderRadius: 0,
											};
										}}>
										{t('alert.projectNoEntities')}
									</Alert>
								</Grid>
							)}
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								border: `1px ${theme.palette.divider} solid`,
								borderRadius: 2,
								overflow: 'hidden',
							};
						}}>
						<Grid container>
							<Grid item xs={12}>
								<Box
									sx={(theme) => {
										return {
											backgroundColor: theme.palette.brand.grey[100],
											borderBottom: `1px ${theme.palette.divider} solid`,
											padding: theme.spacing(2),
											minWidth: '100%',
										};
									}}>
									<Grid alignItems="center" container spacing={2}>
										<Grid item xs={6}>
											<Grid alignItems="center" container spacing={1}>
												<Grid item>
													<IconCircle size="sm" type="account" />
												</Grid>
												<Grid item flex={1}>
													<Typography fontWeight={600}>{t('account')}</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={6}>
											<Typography fontWeight={600}>{t('owner')}</Typography>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							{accounts.length > 0 ? (
								accounts.map((account, indexAccounts) => {
									const isLast = indexAccounts === accounts.length - 1;
									return (
										<Grid item key={`project-account-${account.idWbg}-${indexAccounts}`} xs={12}>
											<Box
												sx={(theme) => {
													const styles: SxProps = {
														padding: theme.spacing(2),
														minWidth: '100%',
													};

													if (!isLast) {
														styles.borderBottom = `1px ${theme.palette.divider} solid`;
													}
													return styles;
												}}>
												<Grid alignItems="center" container spacing={2}>
													<Grid item xs={6}>
														<Typography fontWeight={600}>{account.name}</Typography>
													</Grid>
													<Grid item xs={6}>
														<Select
															hasNoneOption={false}
															name={`account-${account.idWbg}-owner`}
															options={optionsEntities}
															required={true}
														/>
													</Grid>
												</Grid>
											</Box>
										</Grid>
									);
								})
							) : (
								<Grid item xs={12}>
									<Alert
										severity="info"
										sx={(theme) => {
											return {
												alignItems: 'center',
												borderRadius: 0,
											};
										}}>
										{t('alert.projectNoAccounts')}
									</Alert>
								</Grid>
							)}
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Alert
						severity="info"
						sx={(theme) => {
							return {
								alignItems: 'center',
							};
						}}>
						{t('alert.enableLoansAfterOnboard')}
					</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
