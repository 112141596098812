// types
import { EntityType } from '../../../../../../@types';
import { EntityNode, LegendItem } from '../Hierarchy';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Grid, Paper, Typography } from '@mui/material';
import { IconCircle, IconCircleProps } from '../../../../../ui/IconCircle/IconCircle';

// utils
import { translateType } from '../../../../../../utils/translations';

// props
export interface NodeProps {
	entity?: EntityNode;
	legend?: LegendItem[];
	summary?: {
		count: number;
		type: EntityType;
	};
}

export const Node = ({ entity, legend, summary }: NodeProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	let legendItem = null;

	if (legend) {
		let type: EntityType;

		if (entity) {
			type = entity.type as EntityType;
		} else if (summary) {
			type = summary.type;
		}

		legendItem = legend.find((item) => item.value === type) as LegendItem;
	}

	const iconCircleProps: IconCircleProps = {
		size: 'sm',
	};

	let title;
	let subtitle;

	if (entity) {
		title = entity.name;

		if (entity.type) {
			subtitle = translateType(entity.type, t);
		}
	}

	if (summary) {
		subtitle = t('total');
		if (summary.type === 'BENEFICIARY') {
			title = `${summary.count} ${summary.count > 1 ? t('beneficiaries') : t('beneficiary')}`;
		} else if (summary.type === 'SUPPLIER') {
			title = `${summary.count} ${summary.count > 1 ? t('suppliers') : t('supplier')}`;
		}
	}

	if (legendItem) {
		iconCircleProps.color = legendItem.color;
		iconCircleProps.type = legendItem.type;
	}

	return (
		<Paper
			sx={(theme) => {
				return {
					display: 'inline-flex',
					padding: theme.spacing(2),
					textAlign: 'center',
				};
			}}>
			<Grid
				alignItems="center"
				direction="column"
				justifyContent="center"
				container
				rowSpacing={1}
				sx={{
					minHeight: 120,
					width: 120,
				}}>
				<Grid item>{entity?.icon || <IconCircle {...iconCircleProps} />}</Grid>
				<Grid item>
					<Typography fontWeight={600}>{title}</Typography>
					{subtitle && (
						<Typography color="brand.grey.500" variant="caption">
							{subtitle}
						</Typography>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};
