import { ReactNode } from 'react';

// material
import { ClassNameMap } from '@mui/material';

// icons
import {
	AccountBalanceOutlined as IconAccountBalance,
	AssignmentOutlined as IconAssignment,
	CheckOutlined as IconCheck,
	CloudUploadOutlined as IconCloudUpload,
	CommentOutlined as IconComment,
	CreateOutlined as IconCreate,
	DescriptionOutlined as IconDescription,
	FlagOutlined as IconFlag,
	FolderOutlined as IconFolder,
	InfoOutlined as IconInfo,
	InsertDriveFileOutlined as IconInsertDriveFile,
	LocalAtmOutlined as IconLocalAtm,
	PaidOutlined as IconPaid,
	PaymentsOutlined as IconPayments,
	PeopleOutlineOutlined as IconPeople,
	PersonOutlined as IconPerson,
	ReplayOutlined as IconReplenishment,
	SearchOutlined as IconSearch,
	StoreOutlined as IconStore,
	SwapHorizOutlined as IconTransfer,
	ViewListOutlined as IconList,
} from '@mui/icons-material';

// img
import { LogoWorldBankMark } from '../../img/LogoWorldBankMark';

// styles
import useStyles from './IconCircle.styles';

// props
export const iconCircleColors = [
	'blue',
	'green',
	'grey',
	'magenta',
	'orange',
	'purple',
	'teal',
	'yellow',
];
export const iconCircleSizes = ['xs', 'sm', 'md', 'lg'];
export const iconCircleTypes = [
	'account',
	'cashBenefit',
	'check',
	'contract',
	'default',
	'document',
	'edit',
	'entity',
	'form',
	'individual',
	'loan',
	'local',
	'opex',
	'organization',
	'payment',
	'project',
	'replenishment',
	'search',
	'supplier',
	'transfer',
	'upload',
	'user',
];

export type IconCircleColor = (typeof iconCircleColors)[number];
export type IconCircleSize = (typeof iconCircleSizes)[number];
export type IconCircleType = (typeof iconCircleTypes)[number];

export interface IconCircleVariant {
	color: IconCircleColor;
	icon: ReactNode;
}

export interface IconCircleProps {
	color?: IconCircleColor;
	icon?: ReactNode;
	size?: IconCircleSize;
	type?: IconCircleType;
}

export const IconCircle = ({ color, icon, size = 'md', type = 'default' }: IconCircleProps) => {
	// hooks
	const styles: ClassNameMap = useStyles();

	// vars
	const classes = [styles.circle, styles[size]];

	const customSvgSize: Record<string, number> = {
		xs: 16,
		sm: 20,
		md: 20,
		lg: 30,
	};

	const types: Record<string, IconCircleVariant> = {
		account: {
			color: 'blue',
			icon: <LogoWorldBankMark style={{ width: customSvgSize[size] }} />,
		},
		cashBenefit: {
			color: 'blue',
			icon: <IconPaid />,
		},
		check: {
			color: 'green',
			icon: <IconCheck />,
		},
		contract: {
			color: 'yellow',
			icon: <IconAssignment />,
		},
		default: {
			color: 'grey',
			icon: <IconInfo />,
		},
		document: {
			color: 'green',
			icon: <IconInsertDriveFile />,
		},
		edit: {
			color: 'grey',
			icon: <IconCreate />,
		},
		entity: {
			color: 'magenta',
			icon: <IconAccountBalance />,
		},
		form: {
			color: 'magenta',
			icon: <IconList />,
		},
		individual: {
			color: 'magenta',
			icon: <IconPerson />,
		},
		loan: {
			color: 'teal',
			icon: <IconPayments />,
		},
		local: {
			color: 'blue',
			icon: <IconFlag />,
		},
		opex: {
			color: 'blue',
			icon: <IconLocalAtm />,
		},
		organization: {
			color: 'magenta',
			icon: <IconPeople />,
		},
		payment: {
			color: 'blue',
			icon: <IconPaid />,
		},
		project: {
			color: 'purple',
			icon: <IconFolder />,
		},
		replenishment: {
			color: 'blue',
			icon: <IconReplenishment />,
		},
		search: {
			color: 'grey',
			icon: <IconSearch />,
		},
		supplier: {
			color: 'magenta',
			icon: <IconStore />,
		},
		transfer: {
			color: 'blue',
			icon: <IconTransfer />,
		},
		upload: {
			color: 'grey',
			icon: <IconCloudUpload />,
		},
		user: {
			color: 'orange',
			icon: <IconPerson />,
		},
		comment: {
			color: 'blue',
			icon: <IconComment />,
		},
		description: {
			color: 'blue',
			icon: <IconDescription />,
		},
	};

	if (color) {
		classes.push(styles[color]);
	} else {
		classes.push(types[type] ? styles[types[type].color] : styles.grey);
	}

	return <div className={classes.join(' ')}>{icon || types[type].icon}</div>;
};
