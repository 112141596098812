// react
import { useContext } from 'react';

// context
import { ContextOpEx } from '../../../Project/Project.context';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableOpEx } from '../../../../tables/cards/TableOpEx/TableOpEx';

export const OperatingExpenses = () => {
	// context
	const { isLoadingOpEx, opEx, paginationOpEx, refetchOpEx } = useContext(ContextOpEx);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableOpEx
						isLoading={isLoadingOpEx}
						pagination={paginationOpEx}
						refetchTransactions={refetchOpEx}
						transactions={opEx}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
