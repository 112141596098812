// react
import { MouseEvent } from 'react';

// types
import { ExportResource } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Button } from '@mui/material';

// icons
import { DownloadOutlined as IconDownload } from '@mui/icons-material';

// utils
import { DialogExport } from '../../dialogs/DialogExport/DialogExport';

// props
export interface BtnExportProps {
	hasLabel?: boolean;
	resource: ExportResource;
}

export const BtnExport = ({ hasLabel = true, resource }: BtnExportProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');

	return (
		<>
			<Button
				aria-label={hasLabel ? undefined : t('export')}
				color="primary"
				fullWidth
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					searchParams.set('dialog', 'export');
					setSearchParams(searchParams);
				}}
				startIcon={hasLabel ? <IconDownload /> : undefined}
				variant="outlined">
				{hasLabel ? t('export') : <IconDownload />}
			</Button>
			<DialogExport
				isOpen={paramDialog === 'export'}
				onClose={() => {
					searchParams.delete('dialog');
					setSearchParams(searchParams);
				}}
				resource={resource}
			/>
		</>
	);
};
