// react
import { SyntheticEvent, useCallback, useContext, useState } from 'react';

// types
import { Contract, Entity, Frame, Loan } from '../../../@types';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// ui
import { BlockchainHash } from '../../ui/BlockchainHash/BlockchainHash';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// dialogs
import { DialogEditEntity } from '../../dialogs/DialogEditEntity/DialogEditEntity';
import { DialogUpdateEntityStatus } from '../../dialogs/DialogUpdateEntityStatus/DialogUpdateEntityStatus';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// tables
import { TableBasicContracts } from '../../tables/basic/TableBasicContracts/TableBasicContracts';

// icons
import {
	AssignmentOutlined as IconAssignment,
	DoDisturbOutlined as IconDoDisturb,
	ModeEditOutlined as IconModeEdit,
	PaidOutlined as IconPaid,
	PowerSettingsNewOutlined as IconPowerSettingsNew,
} from '@mui/icons-material';

// props
export interface CardSupplierProps {
	frame?: Frame;
	supplier: Entity;
	refetchEntities?: () => void;
}

export const CardSupplier = ({ frame, supplier, refetchEntities }: CardSupplierProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState('contracts');

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogSupplier = searchParams.get('dialogSupplier');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// handlers
	const handleOpenDialogEditSupplier = useCallback(() => {
		searchParams.set('dialog', 'editSupplier');
		searchParams.set('dialogSupplier', String(supplier.id));
		setSearchParams(searchParams);
	}, [supplier, searchParams, setSearchParams]);

	const handleOpenDialogUpdateSupplier = useCallback(() => {
		searchParams.set('dialog', 'updateSupplierStatus');
		searchParams.set('dialogSupplier', String(supplier.id));
		setSearchParams(searchParams);
	}, [supplier, searchParams, setSearchParams]);

	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const btnMenuItems: MenuItemProps[] = [];
	const isFrameSupplier = frame === 'SUPPLIER';
	const tabs = [
		{
			label: t('contracts'),
			value: 'contracts',
		},
	];

	if (user?.permissions?.contract?.create) {
		btnMenuItems.push({
			children: t('addContract'),
			component: RouterLink,
			disabled: project?.status === 'PENDING',
			icon: <IconAssignment />,
			to: `/project/${project?.idWbg}/contracts?dialog=addContract`,
		});
	}

	if (user?.permissions?.transaction?.create) {
		btnMenuItems.push({
			children: t('recordPayment'),
			component: RouterLink,
			divider: true,
			icon: <IconPaid />,
			to: `/project/${
				project?.idWbg
			}/transactions/payments?dialog=recordPayment&dialogToEntity=${String(supplier.id)}`,
		});
	}

	if (user?.permissions?.entity?.update) {
		btnMenuItems.push({
			children: t('edit'),
			icon: <IconModeEdit />,
			onClick: handleOpenDialogEditSupplier,
		});
	}

	if (user?.permissions?.entity?.update) {
		let btnLabel = t('deactivate');
		let btnIcon = <IconDoDisturb color="error" />;

		if (supplier.wallet?.status === 'INACTIVE') {
			btnLabel = t('activate');
			btnIcon = <IconPowerSettingsNew color="success" />;
		}

		btnMenuItems.push({
			children: btnLabel,
			icon: btnIcon,
			onClick: handleOpenDialogUpdateSupplier,
		});
	}

	const parentEntity = loans?.[0]?.entities?.[0].parent;

	// fetch loans
	useFetch({
		isEnabled: Boolean(supplier),
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${supplier.id}&fields=id,entities&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch contracts
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/contracts?supplier=${supplier?.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setContracts(res.data);
			}
		},
	});

	return (
		<>
			<Paper sx={{ position: 'relative' }}>
				<Box
					sx={(theme) => {
						return { padding: theme.spacing(2) };
					}}>
					<Grid alignItems="center" container spacing={2}>
						<Grid item xs={12} lg={2}>
							<Grid alignItems="center" container spacing={2} wrap="nowrap">
								<Grid item>
									<IconCircle type="supplier" />
								</Grid>
								<Grid item>
									{supplier.idExternal && (
										<Typography
											color="brand.grey.500"
											component="div"
											fontWeight={600}
											variant="caption">
											{supplier.idExternal}
										</Typography>
									)}
									<LinkEntity entity={supplier} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('wallet')}
								value={
									supplier.wallet?.address ? (
										<BlockchainHash hash={supplier.wallet.address} />
									) : (
										t('na')
									)
								}
							/>
						</Grid>
						{!isFrameSupplier && (
							<Grid item xs={12} lg={2}>
								<RowKeyValue
									label={t('entity')}
									value={
										<Link
											component={RouterLink}
											to={`/project/${project?.idWbg}/entity/${parentEntity?.id}`}
											underline="hover">
											{parentEntity?.name}
										</Link>
									}
								/>
							</Grid>
						)}

						<Grid item xs={12} lg={isFrameSupplier ? 3 : 1}>
							<RowKeyValue
								label={t('created')}
								value={<Time date={new Date(supplier.created)} />}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('status')}
								value={<ChipStatus status={supplier.wallet?.status || 'ACTIVE'} />}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue label={t('contracts')} value={contracts.length} />
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('traceability')}
								value={<ChipTraceability traceability={supplier.traceability} />}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							lg={true}
							sx={(theme) => {
								return {
									position: 'absolute',
									top: theme.spacing(-1),
									right: theme.spacing(1),
									[theme.breakpoints.up('lg')]: {
										position: 'relative',
										top: 'auto',
										right: 'auto',
									},
								};
							}}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid item>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideAccounts') : t('seeAccounts')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="contracts">
							<TableBasicContracts contracts={contracts} />
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogUpdateEntityStatus
				entity={supplier}
				isOpen={
					paramDialog === 'updateSupplierStatus' && paramDialogSupplier === String(supplier.id)
				}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogSupplier');
					setSearchParams(searchParams);
				}}
				refetchEntities={refetchEntities}
			/>
			<DialogEditEntity
				entity={supplier}
				isOpen={paramDialog === 'editSupplier' && paramDialogSupplier === String(supplier.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogSupplier');
					setSearchParams(searchParams);
				}}
				type="SUPPLIER"
			/>
		</>
	);
};
