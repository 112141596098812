// i18n
import { useTranslation } from 'react-i18next';

// types
import { User } from '../../../@types';

// mui
import { Grid, Typography, Paper } from '@mui/material';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { Loading } from '../../ui/Loading/Loading';

// props
interface CardUsersProps {
	isLoading?: boolean;
	title?: string;
	users: User[];
}

export const CardUsers = ({ isLoading = false, title, users = [] }: CardUsersProps) => {
	// hooks
	const { t } = useTranslation();

	const renderContent = () => {
		if (isLoading) {
			return (
				<Grid item xs={12}>
					<Loading />
				</Grid>
			);
		} else if (users.length) {
			return users.map((user) => {
				return (
					<Grid item key={`user-${user.firstName}-${user.lastName}`} xs={12}>
						<Grid container spacing={1} alignItems={'center'}>
							<Grid item>
								<IconCircle size="sm" type="user" />
							</Grid>
							<Grid item flex={1}>
								{user.firstName} {user.lastName}
							</Grid>
						</Grid>
					</Grid>
				);
			});
		} else {
			return (
				<Grid item xs={12}>
					<Typography>{t('na')}</Typography>
				</Grid>
			);
		}
	};

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
					},
					height: '100%',
				};
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3">{title || t('users')}</Typography>
				</Grid>
				{renderContent()}
			</Grid>
		</Paper>
	);
};
