// react
import {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

// context
import { ContextBanner, ContextUser } from '../../../App.context';
import { ContextBeneficiaries, ContextProject } from '../../pages/Project/Project.context';

// types
import {
	CsvField,
	CsvRow,
	Entity,
	EntityLoanPayload,
	EntityPayload,
	EntitySubtype,
	ErrorCause,
	FormField,
	Loan,
	MetadataField,
	UserPayload,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

// icons
import {
	PeopleOutlineOutlined as IconPeople,
	PersonOutlined as IconPerson,
} from '@mui/icons-material';

// forms
import { FormTableFields } from '../FormTableFields/FormTableFields';

// fields
import { RadioButtons } from '../../fields/RadioButtons/RadioButtons';
import { InputFile } from '../../fields/InputFile/InputFile';
import { Select } from '../../fields/Select/Select';

// ui
import { BannerProps } from '../../ui/Banner/Banner';

// util
import { parseCsv } from '../../../utils/files';

// props
export interface FormUploadBeneficiariesProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormUploadBeneficiaries = ({
	onClose,
	setIsLoading,
}: FormUploadBeneficiariesProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { refetchBeneficiaries } = useContext(ContextBeneficiaries);
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// vars
	const formId = 'form-upload-beneficiaries';
	const isWfa = user?.role === 'WFA';
	const userEntity = user?.entity ? (user.entity as Entity) : null;
	const userEntityId = typeof user?.entity === 'number' ? user?.entity : user?.entity?.id;

	// state
	const [beneficiaryType, setBeneficiaryType] = useState<EntitySubtype>('INDIVIDUAL');
	const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
	const [csvRows, setCsvRows] = useState<CsvRow[]>([]);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [entity, setEntity] = useState('');
	const [error, setError] = useState<{ message: string; errors: string[] } | null>(null);
	const [fields, setFields] = useState<CsvField[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);

	// fetch entities
	useFetch({
		isEnabled: Boolean(!userEntity),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&type=PIU,SUB_PIU&fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// fetch entity loans
	let urlLoans = `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,idWbg,status`;

	if (isWfa && entity) {
		urlLoans += `&entity=${entity}`;
	}

	const { fetchRequest: fetchLoans } = useFetch({
		isLazy: true,
		url: urlLoans,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// options
	const optionsEntities = entities.map((entity) => {
		return {
			label: entity.name,
			value: String(entity.id),
		};
	});

	const optionsLoans = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.idWbg,
			value: loan.id,
		};
	});

	const optionsHeaders = csvHeaders.map((header: string) => {
		return {
			label: header,
			value: header,
		};
	});

	// inputs
	const radioInputs = [
		{
			icon: <IconPerson />,
			label: t('individual'),
			value: 'INDIVIDUAL',
		},
		{
			icon: <IconPeople />,
			label: t('organization'),
			value: 'ORGANIZATION',
		},
	];

	// fetch form
	const { fetchRequest: fetchForm } = useFetch({
		isLazy: true,
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/forms?entity=${
			userEntityId || entity
		}&name=BENEFICIARY_${beneficiaryType}&limit=1&orderBy=modified&populate=user&status=PUBLISHED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data[0]) {
				const fieldsUser: CsvField[] = [
					{
						examples: [],
						header: null,
						isEditable: false,
						isRequired: true,
						label: t('firstName'),
						name: 'user-firstName',
						type: 'TEXT',
					},
					{
						examples: [],
						header: null,
						isEditable: false,
						isRequired: false,
						label: t('lastName'),
						name: 'user-lastName',
						type: 'TEXT',
					},
					{
						examples: [],
						header: null,
						isEditable: false,
						isRequired: true,
						label: t('email'),
						name: 'user-email',
						type: 'TEXT',
					},
					{
						examples: [],
						header: null,
						isEditable: false,
						isRequired: false,
						label: t('phone'),
						name: 'user-phone',
						type: 'TEXT',
					},
				];

				const fields: CsvField[] = res.data[0].fields.map((field: FormField) => {
					const fieldsDefault = ['name', 'idExternal'];

					let name = field.name;

					if (!fieldsDefault.includes(field.name)) {
						name = `metadata-${field.name}`;
					}

					return {
						...field,
						examples: [],
						header: null,
						name,
					};
				});

				setFields([...fieldsUser, ...fields]);
			}
		},
	});

	// handlers
	const handleChangeBeneficiaryType = (
		e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
		value: string
	) => {
		setBeneficiaryType(value as EntitySubtype);
	};

	const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files?.length === 1) {
			const file = e.target.files[0];
			const reader = new FileReader();

			// process csv
			reader.onload = handleCsv;
			reader.readAsText(file);
		}
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		try {
			// define form data
			const form = document.querySelector(`#${formId}`) as HTMLFormElement;
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());

			// define beneficiaries
			const beneficiaries = [];

			// define loans
			const beneficiaryLoans: EntityLoanPayload[] = [];
			const entryLoans = (entries.loans as string).split(',').filter((val) => val !== 'all');

			// for each loan entry
			for (let i = 0; i < entryLoans.length; i++) {
				// add loan
				beneficiaryLoans.push({
					id: Number(entryLoans[i]),
					parent: Number(userEntityId || entity),
				});
			}

			// for each row
			for (let i = 0; i < csvRows.length; i++) {
				const row = csvRows[i];
				const beneficiary: Partial<EntityPayload> = {
					isKycVerified: true,
					loans: beneficiaryLoans,
					metadata: [],
					project: project?.id,
					subtype: beneficiaryType,
					type: 'BENEFICIARY',
					users: [],
				};

				const beneficiaryUser: UserPayload = {
					email: '',
					firstName: '',
					lastName: '',
					project: project?.id,
					role: 'GENERAL',
				};

				// for each field
				for (let f = 0; f < fields.length; f++) {
					const field = fields[f];

					if (!field.header && field.required) {
						throw new Error('Select corresponding fields for all the required fields.', {
							cause: {
								id: 'BULK_UPLOAD_FIELD_EMPTY',
							},
						});
					}

					if (field.header) {
						let value = row[field.header];

						if (value === '') {
							value = null;
						}

						let key: string;

						// field type validations
						if (value) {
							// validate SELECT
							if (field?.type === 'SELECT') {
								const validOptions = field?.options?.map((option) => option.value);
								if (validOptions && !validOptions.includes(value as string)) {
									throw new Error(
										`The value for the ${
											field.label
										} field must be one of the following: ${validOptions.join(', ')}`,
										{
											cause: {
												id: 'FORM_FIELD_SELECT_INVALID',
												details: {
													label: field.label,
													options: validOptions.join(', '),
												},
											},
										}
									);
								}
							}

							// validate NUMBER
							if (field?.type === 'NUMBER') {
								// remove non numerical values
								value = String(value).replace(/[^\d.]/g, '');

								if (value === '') {
									throw new Error(`The value for the ${field.label} field must be a number`, {
										cause: {
											id: 'FORM_FIELD_NUMBER_INVALID',
											details: {
												label: field.label,
											},
										},
									});
								}

								// define value as number
								value = Number(value);
							}

							// validate CHECKBOX
							if (field?.type === 'CHECKBOX') {
								const trueCheckboxValues = [1, 'true', 'TRUE'];
								const falseCheckboxValues = [0, 'false', 'FALSE'];
								const acceptedCheckboxValues = trueCheckboxValues.concat(falseCheckboxValues);
								const validCheckboxValues = acceptedCheckboxValues.join(', ');

								if (!acceptedCheckboxValues.includes(value as string | number)) {
									throw new Error(
										`The value for ${field.label} must be one of the following: ${validCheckboxValues}`,
										{
											cause: {
												id: 'FORM_FIELD_CHECKBOX_INVALID',
												details: {
													label: field.label,
													options: validCheckboxValues,
												},
											},
										}
									);
								}

								// define value as boolean
								value = Boolean(trueCheckboxValues.includes(value as string));
							}

							// validate DATE
							if (field?.type === 'DATE') {
								// define value as date
								value = new Date(value as Date | string);

								if (value.toString() === 'Invalid Date') {
									throw new Error(`The value for ${field.label} must be a date`, {
										cause: {
											id: 'FORM_FIELD_DATE_INVALID',
											details: {
												label: field.label,
											},
										},
									});
								}
							}

							// validate country
							if (
								field.name === 'metadata-country' &&
								field.isRequired &&
								String(value).length !== 2
							) {
								throw new Error(
									'Countries must be formatted using two-letter ISO 3166-1 alpha-2 codes.',
									{
										cause: {
											id: 'COUNTRY_CODE_INVALID',
										},
									}
								);
							}
						}

						if (field.name.includes('metadata-')) {
							// group metadata
							key = field.name.replace('metadata-', '');
							(beneficiary.metadata as MetadataField[]).push({
								isProtected: Boolean(field.isProtected),
								label: field.label,
								name: key,
								type: field.type,
								value,
							});
						} else if (field.name.includes('user-')) {
							// group user
							key = field.name.replace('user-', '');
							beneficiaryUser[key as keyof UserPayload] = value;
						} else {
							// set beneficiary key and value
							key = field.name;
							beneficiary[key] = value;
						}
					}
				}

				// add beneficiary user
				if (beneficiary.users && beneficiaryUser.email !== '') {
					beneficiary.users.push(beneficiaryUser);
				}

				// define name for individuals
				if (beneficiaryType === 'INDIVIDUAL') {
					beneficiary.name = `${beneficiaryUser.firstName} ${beneficiaryUser.lastName}`;
				}

				// add beneficiary
				beneficiaries.push(beneficiary);
			}

			const fetchPostBeneficiaries = await fetch(`${process.env.REACT_APP_API_URL}/entities`, {
				method: 'post',
				body: JSON.stringify(beneficiaries),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resPostBeneficiaries = await fetchPostBeneficiaries.json();

			if (resPostBeneficiaries.error) {
				throw new Error(resPostBeneficiaries.error.message, {
					cause: {
						id: resPostBeneficiaries.error.id,
					},
				});
			}

			const failedLength = resPostBeneficiaries.data.failed.length;
			const succeededLength = resPostBeneficiaries.data.succeeded.length;

			const banner: BannerProps = {
				hasClose: true,
				message: t('alert.beneficiariesUploadedPartial', {
					failed: failedLength,
					succeeded: succeededLength,
				}),
				severity: 'info',
			};

			const allSucceed = failedLength === 0;
			const allFailed = failedLength === beneficiaries.length;

			// refetch
			refetchBeneficiaries();

			if (resPostBeneficiaries.data.failed.length) {
				let msg = t('alert.beneficiariesUploadedError');
				if (allFailed) {
					msg = t('alert.beneficiariesUploadedError');
				} else if (succeededLength === 1 && failedLength === 1) {
					msg = t('alert.beneficiariesUploadedPartialOneSucceededOneFailed');
				} else if (succeededLength === 1) {
					msg = t('alert.beneficiariesUploadedPartialOneSucceeded', {
						failed: failedLength,
					});
				} else if (failedLength === 1) {
					msg = t('alert.beneficiariesUploadedPartialOneFailed', {
						succeeded: succeededLength,
					});
				} else {
					msg = t('alert.beneficiariesUploadedPartial', {
						failed: failedLength,
						succeeded: succeededLength,
					});
				}
				throw new Error(msg, {
					cause: {
						id: 'BULK_UPLOAD_INCOMPLETE',
						res: resPostBeneficiaries,
					},
				});
			}

			if (allSucceed) {
				banner.message = t('alert.beneficiariesUploadedSuccess');
				banner.severity = 'success';
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// set banner
			setBanner(banner);
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;
			const errors = [];

			let message = t('error.default');

			if (cause.id === 'BULK_UPLOAD_FIELD_EMPTY') {
				message = t('error.bulkUploadFieldEmpty');
			}

			if (cause.id === 'BULK_UPLOAD_INCOMPLETE') {
				message = err.message;
				for (const err of cause.res.data.failed) {
					if (err.error.id === 'ENTITY_DUPLICATE') {
						errors.push(t('error.entityDuplicate', { id: err.payload.idExternal }));
					}
				}
			}

			if (cause.id === 'COUNTRY_CODE_INVALID') {
				message = t('error.countryCodeInvalid');
			}

			if (cause.id === 'FORM_FIELD_SELECT_INVALID') {
				message = t('error.formFieldSelectInvalid', {
					label: cause.details.label,
					options: cause.details.options,
				});
			}

			if (cause.id === 'FORM_FIELD_NUMBER_INVALID') {
				message = t('error.formFieldNumberInvalid', { label: cause.details.label });
			}

			if (cause.id === 'FORM_FIELD_CHECKBOX_INVALID') {
				message = t('error.formFieldCheckboxInvalid', {
					label: cause.details.label,
					options: cause.details.options,
				});
			}

			if (cause.id === 'FORM_FIELD_DATE_INVALID') {
				message = t('error.formFieldDateInvalid', { label: cause.details.label });
			}

			// set error
			setError({ message, errors });
		} finally {
			setIsLoading(false);
		}
	};

	const handleCsv = (e: ProgressEvent<FileReader>) => {
		// define result as string
		const result = e.target?.result as string;

		if (result) {
			const { rows, headers } = parseCsv({ string: result, separator: ',' });

			// set csv headers and rows
			setCsvHeaders(headers);
			setCsvRows(rows);
		}
	};

	// fetch form and loans when beneficiary type changes
	useEffect(() => {
		if (!isWfa || (isWfa && entity)) {
			fetchLoans();
			fetchForm();
		}
	}, [beneficiaryType, entity, fetchForm, fetchLoans, isWfa]);

	return (
		<form id={formId} name="formUploadBeneficiaries" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={isWfa ? 4 : 8}>
					<InputFile
						helperText={t('acceptedFileFormats', { formats: '.csv' })}
						id={`${formId}-file-picker`}
						inputProps={{ accept: '.csv' }}
						label={t('selectFile')}
						onChange={handleSelectFile}
						required={true}
					/>
				</Grid>
				{isWfa && (
					<Grid item xs={12} lg={4}>
						<Select
							id={`${formId}-entity`}
							label={t('entity')}
							name="entity"
							onChange={(e) => {
								const { value } = e.target as HTMLInputElement;
								setEntity(value);
							}}
							options={optionsEntities}
							required={true}
							value={entity}
						/>
					</Grid>
				)}
				<Grid item xs={12} lg={4}>
					<Select
						disabled={isWfa && !entity}
						hasAllOption={true}
						id={`${formId}-loans`}
						label={t('financingSources')}
						multiple={true}
						name="loans"
						options={optionsLoans}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<RadioButtons
						id={`${formId}-type`}
						defaultValue={beneficiaryType}
						inputs={radioInputs}
						label={t('type')}
						name="type"
						onChange={handleChangeBeneficiaryType}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTableFields
						fields={fields}
						formId={formId}
						options={optionsHeaders}
						rows={csvRows}
						setFields={setFields}
						titleTotal={t('totalBeneficiaries')}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={true}
								defaultValue="on"
								id={`${formId}-is-kyc-verified`}
								name="isKycVerified"
								required={true}
							/>
						}
						label={t('formUploadBeneficiaries.kycAml')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error" sx={{ maxHeight: 300, overflowY: 'auto' }}>
							<Typography fontWeight={600}>{error.message}</Typography>
							{error.errors.length > 0 && (
								<Box component="ul">
									{error.errors.map((err) => {
										return <Box component="li">{err}</Box>;
									})}
								</Box>
							)}
						</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
