// react
import { FC, useEffect, useState } from 'react';

// types
import { User } from './@types';

// context
import { ContextBanner, ContextUser } from './App.context';

// router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// pages
import { PageEntity } from './components/pages/Entity/Entity';
import { PageLogin } from './components/pages/Login/Login';
import { PageLoginPersona } from './components/pages/LoginPersona/LoginPersona';
import { PageOnboard } from './components/pages/Onboard/Onboard';
import { PageProject } from './components/pages/Project/Project';
import { PageProjects } from './components/pages/Projects/Projects';
import { PageSupplier } from './components/pages/Supplier/Supplier';
import { PageTheme } from './components/pages/Theme/Theme';

// projects pages
import { Onboard as ProjectsOnboard } from './components/pages/Projects/Onboard/Onboard';
import { Table as ProjectsTable } from './components/pages/Projects/Table/Table';

// project pages
import { Accounts as ProjectAccounts } from './components/pages/Project/Accounts/Accounts';
import { Beneficiaries as ProjectBeneficiaries } from './components/pages/Project/Beneficiaries/Beneficiaries';
import { Contracts as ProjectContracts } from './components/pages/Project/Contracts/Contracts';
import { Entities as ProjectEntities } from './components/pages/Project/Entities/Entities';
import { FundFlow as ProjectFundFlow } from './components/pages/Project/FundFlow/FundFlow';
import { Overview as ProjectOverview } from './components/pages/Project/Overview/Overview';
import { Reports as ProjectReports } from './components/pages/Project/Reports/Reports';
import { Settings as ProjectSettings } from './components/pages/Project/Settings/Settings';
import { Suppliers as ProjectSuppliers } from './components/pages/Project/Suppliers/Suppliers';
import { Transactions as ProjectTransactions } from './components/pages/Project/Transactions/Transactions';

// project entities pages
import { Details as EntitiesDetails } from './components/pages/Project/Entities/Details/Details';
import { Hierarchy as EntitiesHierarchy } from './components/pages/Project/Entities/Hierarchy/Hierarchy';

// project transaction pages
import { CashBenefits as TransactionsCashBenefits } from './components/pages/Project/Transactions/CashBenefits/CashBenefits';
import { OperatingExpenses as TransactionsOperatingExpenses } from './components/pages/Project/Transactions/OperatingExpenses/OperatingExpenses';
import { Payments as TransactionsPayments } from './components/pages/Project/Transactions/Payments/Payments';
import { HistoricalTransactions as TransactionsHistorical } from './components/pages/Project/Transactions/HistoricalTransactions/HistoricalTransactions';
import { Transfers as TransactionsTransfers } from './components/pages/Project/Transactions/Transfers/Transfers';

// project suppliers pages
import { Details as SuppliersDetails } from './components/pages/Project/Suppliers/Details/Details';
import { Documents as SuppliersDocuments } from './components/pages/Project/Suppliers/Documents/Documents';

// project beneficiaries pages
import { Details as BeneficiariesDetails } from './components/pages/Project/Beneficiaries/Details/Details';
import { Documents as BeneficiariesDocuments } from './components/pages/Project/Beneficiaries/Documents/Documents';

// project settings pages
import { Forms as ProjectSettingsForms } from './components/pages/Project/Settings/Forms/Forms';
import { Permissions as ProjectSettingsPermissions } from './components/pages/Project/Settings/Permissions/Permissions';
import { FormBuilder as ProjectSettingsFormBuilder } from './components/pages/Project/Settings/FormBuilder/FormBuilder';
import { ExpenseTypes as ProjectSettingsExpenseTypes } from './components/pages/Project/Settings/ExpenseTypes/ExpenseTypes';

// entity page
import { Payments as EntityPayments } from './components/pages/Entity/Payments/Payments';
import { Suppliers as EntitySuppliers } from './components/pages/Entity/Suppliers/Suppliers';
import { Beneficiaries as EntityBeneficiaries } from './components/pages/Entity/Beneficiaries/Beneficiaries';
import { Users as EntityUsers } from './components/pages/Entity/Users/Users';

// supplier page
import { Contracts as SupplierContracts } from './components/pages/Supplier/Contracts/Contracts';
import { Documents as SupplierDocuments } from './components/pages/Supplier/Documents/Documents';
import { Payments as SupplierPayments } from './components/pages/Supplier/Payments/Payments';
import { Users as SupplierUsers } from './components/pages/Supplier/Users/Users';

// Report Page
import { Soe as ReportsSoe } from './components/pages/Project/Reports/Soe/Soe';

// ui
import { BannerProps } from './components/ui/Banner/Banner';

// styles
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// theme
import { theme } from './theme';

console.log('Environment', process.env);

// router
const router = createBrowserRouter([
	{
		path: '/',
		element: <PageLogin />,
	},
	{
		path: '/onboard',
		element: <PageOnboard />,
	},
	{
		path: '/personas',
		element: <PageLoginPersona />,
	},
	{
		path: '/projects',
		element: <PageProjects />,
		children: [
			{
				element: <ProjectsTable />,
				index: true,
			},
			{
				element: <ProjectsTable />,
				path: 'details',
			},
			{
				element: <ProjectsOnboard />,
				path: 'onboard',
			},
		],
	},
	{
		path: '/project/:id?',
		element: <PageProject />,
		children: [
			{
				element: <ProjectOverview />,
				index: true,
			},
			{
				element: <ProjectOverview />,
				path: 'overview',
			},
			{
				element: <ProjectAccounts />,
				path: 'accounts',
			},
			{
				element: <ProjectContracts />,
				path: 'contracts',
			},
			{
				path: 'entity/:idEntity',
				element: <PageEntity />,
				children: [
					{
						element: <EntityPayments />,
						index: true,
					},
					{
						element: <EntityPayments />,
						path: 'payments',
					},
					{
						element: <EntityUsers />,
						path: 'users',
					},
					{
						element: <EntitySuppliers />,
						path: 'suppliers',
					},
					{
						element: <EntityBeneficiaries />,
						path: 'beneficiaries',
					},
				],
			},
			{
				element: <ProjectEntities />,
				path: 'entities',
				children: [
					{
						element: <EntitiesDetails />,
						index: true,
					},
					{
						element: <EntitiesDetails />,
						path: 'details',
					},
					{
						element: <EntitiesHierarchy />,
						path: 'hierarchy',
					},
				],
			},
			{
				element: <ProjectTransactions />,
				path: 'transactions',
				children: [
					{
						element: <TransactionsPayments />,
						index: true,
					},
					{
						element: <TransactionsPayments />,
						path: 'payments',
					},
					{
						element: <TransactionsCashBenefits />,
						path: 'benefits',
					},
					{
						element: <TransactionsOperatingExpenses />,
						path: 'opex',
					},
					{
						element: <TransactionsTransfers />,
						path: 'transfers',
					},
					{
						element: <TransactionsHistorical />,
						path: 'historical',
					},
				],
			},
			{
				path: 'supplier/:idSupplier',
				element: <PageSupplier />,
				children: [
					{
						element: <SupplierPayments />,
						index: true,
					},
					{
						element: <SupplierPayments />,
						path: 'payments',
					},
					{
						element: <SupplierUsers />,
						path: 'users',
					},
					{
						element: <SupplierContracts />,
						path: 'contracts',
					},
					{
						element: <SupplierDocuments />,
						path: 'documents',
					},
				],
			},
			{
				element: <ProjectSuppliers />,
				path: 'suppliers',
				children: [
					{
						element: <SuppliersDetails />,
						index: true,
					},
					{
						element: <SuppliersDetails />,
						path: 'details',
					},
					{
						element: <SuppliersDocuments />,
						path: 'documents',
					},
				],
			},
			{
				element: <ProjectBeneficiaries />,
				path: 'beneficiaries',
				children: [
					{
						element: <BeneficiariesDetails />,
						index: true,
					},
					{
						element: <BeneficiariesDetails />,
						path: 'details',
					},
					{
						element: <BeneficiariesDocuments />,
						path: 'documents',
					},
				],
			},
			{
				element: <ProjectFundFlow />,
				path: 'flow',
			},
			{
				path: 'reports',
				children: [
					{
						element: <ProjectReports />,
						index: true,
					},
					{
						element: <ReportsSoe />,
						path: 'soe',
					},
				],
			},
			{
				element: <ProjectSettings />,
				path: 'settings',
				children: [
					{
						element: <ProjectSettingsExpenseTypes />,
						index: true,
					},
					{
						element: <ProjectSettingsPermissions />,
						path: 'permissions',
					},
					{
						element: <ProjectSettingsForms />,
						path: 'forms',
					},
					{
						element: <ProjectSettingsFormBuilder />,
						path: 'form/:idForm',
					},
					{
						element: <ProjectSettingsExpenseTypes />,
						path: 'expense-types',
					},
				],
			},
		],
	},
	{
		path: '/theme',
		element: <PageTheme />,
	},
	{
		path: '/login',
		element: <PageLogin />,
	},
]);

export const App: FC = () => {
	// state
	const [banner, setBanner] = useState<BannerProps | null>(null);
	const [user, setUser] = useState<User | null>(null);

	// set user
	useEffect(() => {
		const sessionUser = sessionStorage.getItem('user');
		const user = sessionUser ? JSON.parse(sessionUser) : null;

		if (user) {
			setUser(user);
		}
	}, []);

	// scroll to top when banner changes
	useEffect(() => {
		if (banner) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [banner]);

	return (
		<ContextUser.Provider value={{ user, setUser }}>
			<ContextBanner.Provider value={{ banner, setBanner }}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<RouterProvider router={router} />
				</ThemeProvider>
			</ContextBanner.Provider>
		</ContextUser.Provider>
	);
};
