// react
import { SyntheticEvent, useContext, useState } from 'react';

// types
import { Account, Contract, Entity, ExpenseType, Frame, Transaction } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// mui
import { Alert, Box, Grid, Link, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// ui
import { Amount } from '../../ui/Amount/Amount';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { LineItem } from '../../ui/LineItem/LineItem';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// tables
import { TableBasicDocs } from '../../tables/basic/TableBasicDocs/TableBasicDocs';
import { TableBasicPayments } from '../../tables/basic/TableBasicPayments/TableBasicPayments';

// dialogs
import { DialogUploadDocuments } from '../../dialogs/DialogUploadDocuments/DialogUploadDocuments';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// icons
import { InsertDriveFileOutlined as IconInsertDriveFile } from '@mui/icons-material';

// theme
import { theme } from '../../../theme';

// props
export interface CardTransactionProps {
	frame?: Frame;
	transaction: Transaction;
	refetchTransactions?: () => void;
}

export const CardTransaction = ({
	frame,
	transaction,
	refetchTransactions,
}: CardTransactionProps) => {
	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramDialog = searchParams.get('dialog');
	const paramDialogTransaction = searchParams.get('dialogTransaction');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [tabValue, setTabValue] = useState('financing');

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const isCashBenefit = transaction.type === 'CASH_BENEFIT';
	const isOpEx = transaction.type === 'OPEX';
	const isPayment = transaction.type === 'PAYMENT';
	const transactionFromAccount = transaction.fromAccount as Account;
	const transactionContract = transaction.contract as Contract;
	const transactionFromEntity = transaction.fromEntity as Entity;
	const transactionToEntity = transaction.toEntity as Entity;
	const tabs = [
		{
			label: t('financing'),
			value: 'financing',
		},
		{
			label: t('documents'),
			value: 'documents',
		},
		{
			label: t('comments'),
			value: 'comments',
		},
	];
	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions?.document?.create && user?.permissions?.transaction?.update) {
		btnMenuItems.push({
			icon: <IconInsertDriveFile />,
			children: t('uploadDocuments'),
			onClick: () => {
				searchParams.set('dialog', 'uploadDocuments');
				searchParams.set('dialogTransaction', String(transaction.id));
				setSearchParams(searchParams);
			},
		});
	}

	const iconCircleProps: IconCircleProps = {
		type: 'payment',
	};

	if (isCashBenefit) {
		iconCircleProps.type = 'cashBenefit';
	}

	if (isOpEx) {
		iconCircleProps.type = 'opex';
	}

	const rowAmount = (
		<RowKeyValue
			label={t('amount')}
			value={<Amount currency={transaction.currency} value={transaction.amount} />}
		/>
	);

	const rowDate = (
		<Box
			sx={{
				margin: theme.spacing(0, 0, 2, 0),
				[theme.breakpoints.up('lg')]: {
					margin: 0,
				},
			}}>
			<Grid alignItems="center" container spacing={2} wrap="nowrap">
				<Grid item>
					<IconCircle {...iconCircleProps} />
				</Grid>
				<Grid item>
					{transaction.date ? (
						<Time date={new Date(transaction.date)} />
					) : (
						<Typography>{t('na')}</Typography>
					)}
				</Grid>
			</Grid>
		</Box>
	);

	const rowExpenseType = (
		<RowKeyValue
			label={t('expenseType')}
			value={
				<Typography color="brand.grey.500">
					{transaction.expenseType ? (transaction.expenseType as ExpenseType).name : t('na')}
				</Typography>
			}
		/>
	);

	const rowFromAccount = (
		<RowKeyValue
			label={t('account')}
			value={<Typography>{transactionFromAccount.name}</Typography>}
		/>
	);

	const rowFromEntity = (
		<RowKeyValue
			label={t('entity')}
			value={
				transaction.fromEntity ? (
					<Link
						component={RouterLink}
						fontWeight={600}
						to={`/project/${project?.idWbg}/entity/${transactionFromEntity.id}`}
						underline="hover">
						{transactionFromEntity.name}
					</Link>
				) : (
					<Typography>{t('na')}</Typography>
				)
			}
		/>
	);

	const rowStatus = (
		<RowKeyValue label={t('status')} value={<ChipStatus status={transaction.status} />} />
	);

	const rowTraceability = (
		<RowKeyValue
			label={t('traceability')}
			value={<ChipTraceability traceability={transaction.traceability} />}
		/>
	);

	const renderRow = () => {
		if (isCashBenefit) {
			return (
				<>
					<Grid item xs={12} lg={2}>
						{rowDate}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowExpenseType}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowFromEntity}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						<RowKeyValue
							label={t('beneficiary')}
							value={
								transaction.toEntity ? (
									<LinkEntity entity={transactionToEntity} />
								) : (
									<Typography>{t('na')}</Typography>
								)
							}
						/>
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowStatus}
					</Grid>
					<Grid item xs={12} lg={1}>
						{rowTraceability}
					</Grid>
					<Grid item xs={12} lg={1}>
						{rowFromAccount}
					</Grid>
					<Grid item xs={12} lg={1}>
						{rowAmount}
					</Grid>
				</>
			);
		}

		if (isOpEx) {
			return (
				<>
					<Grid item xs={12} lg={2}>
						{rowDate}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowExpenseType}
					</Grid>
					<Grid item xs={12} lg={2}>
						{rowFromEntity}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						<RowKeyValue
							label={t('vendor')}
							value={
								transaction?.metadata?.vendor ? (
									<Typography>{transaction?.metadata?.vendor}</Typography>
								) : (
									<Typography>{t('na')}</Typography>
								)
							}
						/>
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowStatus}
					</Grid>
					<Grid item xs={12} lg={1}>
						{rowFromAccount}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowAmount}
					</Grid>
				</>
			);
		}

		if (isPayment) {
			return (
				<>
					<Grid item xs={12} lg={2}>
						{rowDate}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowExpenseType}
					</Grid>
					<Grid item xs={12} lg={1}>
						<RowKeyValue
							label={t('contract')}
							value={
								transaction.contract ? (
									<Link
										component={RouterLink}
										fontWeight={600}
										to={`/project/${project?.idWbg}/contracts`}
										underline="hover">
										{transactionContract.num}
									</Link>
								) : (
									<Typography>{t('na')}</Typography>
								)
							}
						/>
					</Grid>
					{frame === 'SUPPLIER' ? (
						<Grid item xs={12} lg={1.5}>
							{rowFromEntity}
						</Grid>
					) : (
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('supplier')}
								value={
									transaction.toEntity ? (
										<LinkEntity entity={transactionToEntity} />
									) : (
										<Typography>{t('na')}</Typography>
									)
								}
							/>
						</Grid>
					)}
					<Grid item xs={12} lg={1.5}>
						{rowStatus}
					</Grid>
					<Grid item xs={12} lg={1}>
						{rowTraceability}
					</Grid>
					<Grid item xs={12} lg={1}>
						{rowFromAccount}
					</Grid>
					<Grid item xs={12} lg={1.5}>
						{rowAmount}
					</Grid>
				</>
			);
		}
	};

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, lg: 2 }}>
						{renderRow()}
						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid
										item
										sx={{
											position: 'absolute',
											top: 0,
											right: theme.spacing(1),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="financing">
							<TableBasicPayments payments={transaction.payments} transaction={transaction} />
						</TabPanel>
						<TabPanel value="documents">
							<TableBasicDocs transaction={transaction} />
						</TabPanel>
						<TabPanel value="comments">
							{transaction?.metadata?.comments ? (
								<LineItem
									content={transaction?.metadata?.comments}
									IconCircleProps={{ size: 'sm', type: 'comment' }}></LineItem>
							) : (
								<Alert severity="info">{t('alert.empty')}</Alert>
							)}
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogUploadDocuments
				isOpen={
					paramDialog === 'uploadDocuments' && paramDialogTransaction === String(transaction.id)
				}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogTransaction');
					setSearchParams(searchParams);
				}}
				refetchTransactions={refetchTransactions}
				transaction={transaction}
			/>
		</>
	);
};
