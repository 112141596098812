// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// context
import { ContextLoans, ContextProject } from '../../pages/Project/Project.context';
import { ContextBanner } from '../../../App.context';

// types
import { Account, AccountSource, ErrorCause, Loan, PaymentPayload } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Amounts } from '../../fields/Amounts/Amounts';

// props
export interface FormAddAccountBalanceProps {
	account: Account;
	onClose?: () => void;
	refetchAccounts?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddAccountBalance = ({
	account,
	onClose,
	refetchAccounts,
	setIsLoading,
}: FormAddAccountBalanceProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<string | null>(null);

	// context
	const { loans } = useContext(ContextLoans);
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// define default sources as all loans
	let sources: AccountSource[] = loans.map((loan) => {
		return {
			loan,
			source: 'LOAN',
		};
	});

	// add other and government sources
	sources.push({
		loan: null,
		source: 'OTHER',
	});

	if (account.type === 'LOCAL') {
		sources.push({
			loan: null,
			source: 'GOVERNMENT',
		});
	}

	// redefine sources if account has sources
	if (account.sources.length) {
		sources = account.sources;
	}

	const sourcesLoans = sources.filter((s) => s.loan).map((s) => s.loan as Loan);
	const formId = 'form-add-account-balance';

	const balance = account.balances.reduce((total, account) => {
		return total + account.balance;
	}, 0);

	// check if from account has disabled loans
	let hasDisabledLoans = false;

	for (let i = 0; i < sourcesLoans.length; i++) {
		if (sourcesLoans[i]?.status === 'DISABLED') {
			hasDisabledLoans = true;
			break;
		}
	}

	// handlers
	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		// define balances using sources
		const payments: PaymentPayload[] = [];
		let total = 0;

		for (let s = 0; s < sources.length; s++) {
			const amount = Number(entries[`source-${s}`]);
			if (amount > 0) {
				const source = sources[s];
				const loan = source.loan as Loan;

				total += amount;

				payments.push({
					amount,
					currency: account.currency,
					loan: loan?.id,
					source: source.source,
				});
			}
		}

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (total === 0) {
				throw new Error('The amount needs to be greater than 0.', {
					cause: {
						id: 'TRANSACTION_AMOUNT_NON_EXISTENT',
					},
				});
			}

			// add metadata
			const metadata = {
				uuid: crypto.randomUUID(),
			};

			const body = {
				metadata,
				payments,
				project: project?.id,
				toAccount: account.id,
				fromAccount: account.id, //Add this to account for balance check
				type: 'REPLENISHMENT',
			};

			const fetchRecordTransaction = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resRecordTransaction = await fetchRecordTransaction.json();

			if (resRecordTransaction.error) {
				throw new Error(resRecordTransaction.error.message, {
					cause: {
						id: resRecordTransaction.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch accounts
			if (refetchAccounts) {
				refetchAccounts();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.accountBalanceUpdated', { name: account.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'ACCOUNT_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}

				if (cause.id === 'TRANSACTION_AMOUNT_NON_EXISTENT') {
					message = t('error.transactionAmountNonExistent');
				}

				if (cause.id === 'WALLET_INACTIVE') {
					message = t('error.walletInactive');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={formId} name="formAddAccountBalance" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{sources.length > 0 && (
					<Grid item xs={12}>
						<Amounts
							currency={account.currency}
							formId={formId}
							hasCurrency={false}
							loans={loans}
							sources={sources}
							summaries={[
								{
									operator: '+',
									title: t('newBalance'),
									value: balance,
								},
							]}
							title={t('fundsToAdd')}
						/>
					</Grid>
				)}
				{hasDisabledLoans && (
					<Grid item xs={12}>
						<Alert severity="info">{t('alert.financingSourcesDisabled')}</Alert>
					</Grid>
				)}
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
