// react
import { ReactNode, useContext, useMemo, useState } from 'react';

// types
import { ActionButton, Entity, Pagination } from '../../../../@types';

// i18n
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardBeneficiary } from '../../../cards/CardBeneficiary/CardBeneficiary';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { PeopleOutlineOutlined as IconPeople } from '@mui/icons-material';
import { FilterChangeEvent, FilterProps } from '../../../ui/Filters/Filters';

// props
export interface TableBeneficiariesProps {
	isLoading?: boolean;
	pagination: Pagination;
	beneficiaries: Entity[];
	refetchEntities?: () => void;
}

export const TableBeneficiaries = ({
	isLoading,
	pagination,
	beneficiaries,
	refetchEntities,
}: TableBeneficiariesProps) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramHasTransactions = searchParams.get('hasTransactions');
	const paramQ = searchParams.get('q');
	const paramSubtype = searchParams.get('subtype');

	// defaults
	const defaultFiltersValues = {
		subtype: 'all',
		hasTransactions: 'all',
	};

	// state
	const [filtersValues, setFiltersValues] = useState({
		hasTransactions: paramHasTransactions || defaultFiltersValues.hasTransactions,
		subtype: paramSubtype || defaultFiltersValues.subtype,
	});

	// context
	const { user } = useContext(ContextUser);

	// vars
	const formId = 'form-beneficiaries-filters';

	// headers
	const headers = [
		{
			label: t('name'),
			lg: 2,
			value: 'name',
		},
		{
			label: t('wallet'),
			lg: 2,
			value: 'wallet',
		},
		{
			label: t('type'),
			lg: 1,
			value: 'type',
		},
		{
			label: t('entity'),
			lg: 2,
			value: 'parent',
		},

		{
			label: t('status'),
			lg: 1,
			value: 'status',
		},
		{
			label: t('paid'),
			lg: 1,
			value: 'paid',
		},
		{
			label: t('totalBenefits'),
			lg: 1,
			value: 'totalBenefits',
		},
		{
			label: t('traceability'),
			lg: 1,
			value: 'traceability',
		},
	];

	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	// filters
	const filters: FilterProps[] = [
		{
			label: t('type'),
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-subtype`,
			name: 'subtype',
			onChange: handleChangeFilterValue,
			options: [
				{
					label: t('organization'),
					value: 'ORGANIZATION',
				},
				{
					label: t('individual'),
					value: 'INDIVIDUAL',
				},
			],
			type: 'SELECT',
			value: filtersValues.subtype,
		},
		{
			label: t('paid'),
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-hasTransactions`,
			name: 'hasTransactions',
			onChange: handleChangeFilterValue,
			options: [
				{
					label: t('yes'),
					value: 'true',
				},
				{
					label: t('no'),
					value: 'false',
				},
			],
			type: 'SELECT',
			value: filtersValues.hasTransactions,
		},
	];

	// memo
	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [],
			content: { children: t('empty.beneficiary.content') },
			IconCircleProps: { icon: (<IconPeople />) as ReactNode },
			title: { children: t('empty.beneficiary.title') },
		};

		if (user?.entity && user?.permissions?.entity?.create && emptyProps.actions) {
			emptyProps.actions.push(
				{
					children: t('addBeneficiary'),
					onClick: () => {
						searchParams.set('dialog', 'addBeneficiary');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
				{
					children: t('uploadBeneficiaries'),
					onClick: () => {
						searchParams.set('dialog', 'uploadBeneficiaries');
						setSearchParams(searchParams);
					},
					variant: 'outlined' as ActionButton['variant'],
				}
			);
		}

		if (paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ, searchParams, setSearchParams, user]);

	const content = useMemo(() => {
		if (isLoading || !beneficiaries.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{beneficiaries.map((beneficiary, i) => {
					return (
						<Grid item key={`beneficiary-${i}`} xs={12}>
							<CardBeneficiary beneficiary={beneficiary} refetchEntities={refetchEntities} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, isLoading, beneficiaries, refetchEntities]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
					<Grid item container xs={'auto'}>
						<BtnFilters
							filters={filters}
							onReset={() => {
								setFiltersValues(defaultFiltersValues);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={beneficiaries.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
