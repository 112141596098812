// react
import { Dispatch, FormEvent, SetStateAction, useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import {
	Account,
	Entity,
	ExportResource,
	ExpenseType,
	Transaction,
	WithdrawalApplication,
} from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { RadioBoxes } from '../../fields/RadioBoxes/RadioBoxes';

// utils
import { formatDate } from '../../../utils/dates';
import { downloadFile, jsonToCsv } from '../../../utils/files';
import { translateStatus, translateTransactionType } from '../../../utils/translations';
import { ContextBanner } from '../../../App.context';

// props
export interface FormExportProps {
	onClose?: () => void;
	resource: ExportResource;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormExport = ({ onClose, resource, setIsLoading }: FormExportProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// state
	const [searchParams] = useSearchParams();

	// params
	const paramContract = searchParams.get('contract');
	const paramExpenseType = searchParams.get('expenseType');
	const paramFromAccount = searchParams.get('fromAccount');
	const paramLoan = searchParams.get('loan');
	const paramStatus = searchParams.get('status');
	const paramToAccount = searchParams.get('toAccount');
	const paramToEntity = searchParams.get('toEntity');

	// vars
	const dateToday = formatDate({ date: new Date(), format: 'YYYY-MM-DD' });
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		User: String(idToken),
	};
	const idForm = `form-export`;

	const optionsRadioBoxes = [
		{
			description: {
				children: t('allData.description'),
			},
			id: `${idForm}-scope-all`,
			label: {
				children: t('allData.label'),
			},
			value: 'ALL',
		},
		{
			description: {
				children: t('filteredData.description'),
			},
			id: `${idForm}-scope-filtered`,
			label: {
				children: t('filteredData.label'),
			},
			value: 'FILTERED',
		},
	];

	// fetch CASH_BENEFIT transactions
	const { fetchRequest: fetchCashBenefit } = useFetch({
		isLazy: true,
		onSuccess: (res) => {
			let data = [
				{
					[t('id')]: null,
					[t('type')]: null,
					[t('date')]: null,
					[t('fromEntity')]: null,
					[t('fromAccount')]: null,
					[t('toEntity')]: null,
					[t('amount')]: null,
					[t('currency')]: null,
					[t('expenseType')]: null,
					[t('withdrawalApplication')]: null,
					[t('status')]: null,
					[t('traceability')]: null,
					[t('created')]: null,
					[t('modified')]: null,
				},
			];

			if (res.data) {
				data = res.data.map((transaction: Transaction) => {
					const expenseType = transaction.expenseType as ExpenseType;
					const fromAccount = transaction.fromAccount as Account;
					const fromEntity = transaction.fromEntity as Entity;
					const toEntity = transaction.toEntity as Entity;
					const wa = transaction.withdrawalApplication as WithdrawalApplication;

					return {
						[t('id')]: transaction.id,
						[t('type')]: translateTransactionType(transaction.type, t),
						[t('date')]: transaction.date
							? formatDate({ date: new Date(transaction.date), format: 'YYYY-MM-DD' })
							: '',
						[t('fromEntity')]: fromEntity?.name,
						[t('fromAccount')]: fromAccount?.name,
						[t('toEntity')]: toEntity?.name,
						[t('amount')]: transaction.amount,
						[t('currency')]: transaction.currency,
						[t('expenseType')]: expenseType?.name,
						[t('withdrawalApplication')]: wa?.idExternal,
						[t('status')]: translateStatus(transaction.status, t),
						[t('traceability')]: transaction.traceability.value,
						[t('created')]: transaction.created
							? formatDate({ date: new Date(transaction.created), format: 'YYYY-MM-DD' })
							: '',
						[t('modified')]: transaction.modified
							? formatDate({ date: new Date(transaction.modified), format: 'YYYY-MM-DD' })
							: '',
					};
				});

				const csv = jsonToCsv({ array: data });

				downloadFile({
					data: csv,
					name: `transactions-beneficiaries-${dateToday}.csv`,
					type: 'text/csv',
				});
			}
		},
	});

	// fetch OPEX transactions
	const { fetchRequest: fetchOpEx } = useFetch({
		isLazy: true,
		onSuccess: (res) => {
			let data = [
				{
					[t('id')]: null,
					[t('type')]: null,
					[t('date')]: null,
					[t('fromEntity')]: null,
					[t('fromAccount')]: null,
					[t('amount')]: null,
					[t('currency')]: null,
					[t('expenseType')]: null,
					[t('withdrawalApplication')]: null,
					[t('status')]: null,
					[t('created')]: null,
					[t('modified')]: null,
				},
			];

			if (res.data) {
				data = res.data.map((transaction: Transaction) => {
					const expenseType = transaction.expenseType as ExpenseType;
					const fromAccount = transaction.fromAccount as Account;
					const fromEntity = transaction.fromEntity as Entity;
					const wa = transaction.withdrawalApplication as WithdrawalApplication;

					return {
						[t('id')]: transaction.id,
						[t('type')]: translateTransactionType(transaction.type, t),
						[t('date')]: transaction.date
							? formatDate({ date: new Date(transaction.date), format: 'YYYY-MM-DD' })
							: '',
						[t('fromEntity')]: fromEntity?.name,
						[t('fromAccount')]: fromAccount?.name,
						[t('amount')]: transaction.amount,
						[t('currency')]: transaction.currency,
						[t('expenseType')]: expenseType?.name,
						[t('withdrawalApplication')]: wa?.idExternal,
						[t('status')]: translateStatus(transaction.status, t),
						[t('created')]: transaction.created
							? formatDate({ date: new Date(transaction.created), format: 'YYYY-MM-DD' })
							: '',
						[t('modified')]: transaction.modified
							? formatDate({ date: new Date(transaction.modified), format: 'YYYY-MM-DD' })
							: '',
					};
				});

				const csv = jsonToCsv({ array: data });

				downloadFile({
					data: csv,
					name: `transactions-opex-${dateToday}.csv`,
					type: 'text/csv',
				});
			}
		},
	});

	// fetch PAYMENT transactions
	const { fetchRequest: fetchPayments } = useFetch({
		isLazy: true,
		onSuccess: (res) => {
			let data = [
				{
					[t('id')]: null,
					[t('type')]: null,
					[t('date')]: null,
					[t('fromEntity')]: null,
					[t('fromAccount')]: null,
					[t('toEntity')]: null,
					[t('amount')]: null,
					[t('currency')]: null,
					[t('expenseType')]: null,
					[t('withdrawalApplication')]: null,
					[t('status')]: null,
					[t('traceability')]: null,
					[t('created')]: null,
					[t('modified')]: null,
				},
			];

			if (res.data?.length) {
				data = res.data.map((transaction: Transaction) => {
					const expenseType = transaction.expenseType as ExpenseType;
					const fromAccount = transaction.fromAccount as Account;
					const fromEntity = transaction.fromEntity as Entity;
					const toEntity = transaction.toEntity as Entity;
					const wa = transaction.withdrawalApplication as WithdrawalApplication;

					return {
						[t('id')]: transaction.id,
						[t('type')]: translateTransactionType(transaction.type, t),
						[t('date')]: transaction.date
							? formatDate({ date: new Date(transaction.date), format: 'YYYY-MM-DD' })
							: '',
						[t('fromEntity')]: fromEntity?.name,
						[t('fromAccount')]: fromAccount?.name,
						[t('toEntity')]: toEntity?.name,
						[t('amount')]: transaction.amount,
						[t('currency')]: transaction.currency,
						[t('expenseType')]: expenseType?.name,
						[t('withdrawalApplication')]: wa?.idExternal,
						[t('status')]: translateStatus(transaction.status, t),
						[t('traceability')]: transaction.traceability.value,
						[t('created')]: transaction.created
							? formatDate({ date: new Date(transaction.created), format: 'YYYY-MM-DD' })
							: '',
						[t('modified')]: transaction.modified
							? formatDate({ date: new Date(transaction.modified), format: 'YYYY-MM-DD' })
							: '',
					};
				});
			}

			const csv = jsonToCsv({ array: data });

			downloadFile({
				data: csv,
				name: `transactions-suppliers-${dateToday}.csv`,
				type: 'text/csv',
			});
		},
	});

	// fetch TRANSFER transactions
	const { fetchRequest: fetchTransfers } = useFetch({
		isLazy: true,
		onSuccess: (res) => {
			let data = [
				{
					[t('id')]: null,
					[t('type')]: null,
					[t('date')]: null,
					[t('fromEntity')]: null,
					[t('fromAccount')]: null,
					[t('amount')]: null,
					[t('currency')]: null,
					[t('toEntity')]: null,
					[t('toAccount')]: null,
					[t('amountReceived')]: null,
					[t('currencyReceived')]: null,
					[t('status')]: null,
					[t('created')]: null,
					[t('modified')]: null,
				},
			];

			if (res.data?.length) {
				data = res.data.map((transaction: Transaction) => {
					const fromAccount = transaction.fromAccount as Account;
					const fromEntity = transaction.fromEntity as Entity;
					const toAccount = transaction.toAccount as Account;
					const toEntity = transaction.toEntity as Entity;

					return {
						[t('id')]: transaction.id,
						[t('type')]: translateTransactionType(transaction.type, t),
						[t('date')]: transaction.date
							? formatDate({ date: new Date(transaction.date), format: 'YYYY-MM-DD' })
							: '',
						[t('fromEntity')]: fromEntity?.name,
						[t('fromAccount')]: fromAccount?.name,
						[t('amount')]: transaction.amount,
						[t('currency')]: transaction.currency,
						[t('toEntity')]: toEntity?.name,
						[t('toAccount')]: toAccount?.name,
						[t('amountReceived')]: transaction.amount,
						[t('currencyReceived')]: transaction.currency,
						[t('status')]: translateStatus(transaction.status, t),
						[t('created')]: transaction.created
							? formatDate({ date: new Date(transaction.created), format: 'YYYY-MM-DD' })
							: '',
						[t('modified')]: transaction.modified
							? formatDate({ date: new Date(transaction.modified), format: 'YYYY-MM-DD' })
							: '',
					};
				});
			}

			const csv = jsonToCsv({ array: data });

			downloadFile({
				data: csv,
				name: `transactions-transfers-${dateToday}.csv`,
				type: 'text/csv',
			});
		},
	});

	const handleExport = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// set loading
		setIsLoading(true);

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());
		const params = new URLSearchParams();

		let resFetch;

		if (project) {
			params.set('project', String(project.id));
		}

		if (entries.dateStart) {
			params.set('dateStart', String(entries.dateStart));
		}

		if (entries.dateEnd) {
			params.set('dateEnd', String(entries.dateEnd));
		}

		// add search params if scope is FILTERED
		if (entries.scope === 'FILTERED') {
			if (paramContract) {
				params.set('contract', String(paramContract));
			}

			if (paramExpenseType) {
				params.set('expenseType', String(paramExpenseType));
			}

			if (paramFromAccount) {
				params.set('fromAccount', String(paramFromAccount));
			}

			if (paramLoan) {
				params.set('loan', String(paramLoan));
			}

			if (paramStatus) {
				params.set('status', String(paramStatus));
			}

			if (paramToAccount) {
				params.set('toAccount', String(paramToAccount));
			}

			if (paramToEntity) {
				params.set('toEntity', String(paramToEntity));
			}
		}

		if (['CASH_BENEFIT', 'OPEX', 'PAYMENT', 'TRANSFER'].includes(resource)) {
			const urlTransactions = `${process.env.REACT_APP_API_URL}/transactions`;

			params.set('type', resource);

			if (['CASH_BENEFIT', 'PAYMENT'].includes(resource)) {
				params.set(
					'populate',
					'contract,expenseType,fromAccount,fromEntity,toEntity,withdrawalApplication'
				);

				if (resource === 'CASH_BENEFIT') {
					resFetch = await fetchCashBenefit(`${urlTransactions}?${params}`, {
						headers,
					});
				} else if (resource === 'PAYMENT') {
					resFetch = await fetchPayments(`${urlTransactions}?${params}`, {
						headers,
					});
				}
			} else if (resource === 'OPEX') {
				params.set('populate', 'expenseType,fromAccount,fromEntity,withdrawalApplication');

				resFetch = await fetchOpEx(`${urlTransactions}?${params}`, {
					headers,
				});
			} else if (resource === 'TRANSFER') {
				params.set('populate', 'fromAccount,fromEntity,toAccount,toEntity');

				resFetch = await fetchTransfers(`${urlTransactions}?${params}`, {
					headers,
				});
			}
		}

		// clear loading
		setIsLoading(false);

		// close dialog
		if (onClose) {
			onClose();
		}

		let message = t('alert.exportCreated');

		if (resFetch?.pagination?.total) {
			message = t('alert.exportCreatedRows', { rows: resFetch?.pagination?.total });
		}

		// set banner
		setBanner({
			hasClose: true,
			message,
			severity: 'success',
		});
	};

	return (
		<form id={idForm} onSubmit={handleExport}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RadioBoxes options={optionsRadioBoxes} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('startDate')}
						name="dateStart"
						type="date"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('endDate')}
						name="dateEnd"
						type="date"
					/>
				</Grid>
			</Grid>
		</form>
	);
};
