// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject, ContextUsersPermissions } from '../../pages/Project/Project.context';

// mui
import { Alert, Box, Checkbox, Divider, Grid, InputLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// fields
import { Input } from '../../fields/Input/Input';

export const FormManageUserPermissions = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);
	const { refetchUsersPermissions } = useContext(ContextUsersPermissions);

	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	// vars
	const formId = `form-user-permissions`;

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		// define form data
		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			if (!entries.permissionBeneficiaries) {
				throw new Error('At least one permission must be set.', {
					cause: {
						id: 'USER_PERMISSION_NON_EXISTENT',
					},
				});
			}

			// get user
			const fetchGetUser = await fetch(
				`${process.env.REACT_APP_API_URL}/users?email=${entries['email']}&fields=id`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				}
			);
			const resGetUser = await fetchGetUser.json();

			// if user exists
			if (resGetUser.data?.length) {
				// patch user
				const user = resGetUser.data[0];
				const fetchPatchUser = await fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
					body: JSON.stringify({
						permissions: {
							beneficiary: {
								read: true,
							},
						},
						project: project?.id,
					}),
				});
				const resPatchUser = await fetchPatchUser.json();

				if (resPatchUser.error) {
					throw new Error(resPatchUser.error.message, {
						cause: {
							id: resPatchUser.error.id,
						},
					});
				}
			} else {
				const fetchPostUser = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
					method: 'post',
					body: JSON.stringify({
						email: entries['email'],
						permissions: {
							beneficiary: {
								read: true,
							},
						},
						project: project?.id,
						role: 'GENERAL',
					}),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});
				const resPostUser = await fetchPostUser.json();

				if (resPostUser.error) {
					throw new Error(resPostUser.error.message, {
						cause: {
							id: resPostUser.error.id,
						},
					});
				}
			}

			// refetch users permissions
			refetchUsersPermissions();

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.userPermissionsGranted', { email: entries['email'] }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'GRAPH_UNAUTHORIZED') {
					message = t('error.graphUnauthorized');
				}

				if (['GRAPH_USER_NOT_FOUND', 'USER_GROUP_INVALID'].includes(cause.id)) {
					message = t('error.userGroupInvalid');
				}

				if (cause.id === 'USER_PERMISSION_NON_EXISTENT') {
					message = t('error.userPermissionNonExistent');
				}
			}

			// set error
			setError(message);
		} finally {
			// clear loading
			setIsLoading(false);
		}
	};

	return (
		<form id={formId} onSubmit={handleSubmit}>
			<Box
				sx={(theme) => {
					return {
						border: `1px ${theme.palette.divider} solid`,
						borderRadius: 2,
					};
				}}>
				<Grid container>
					<Grid item xs={12}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
								};
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography fontWeight={600}>{t('setUserPermissions')}</Typography>
								</Grid>

								<Grid item xs={12}>
									<Input
										id={`${formId}-email`}
										label={t('email')}
										name="email"
										placeholder={`${t('email.placeholder')}...`}
										required={true}
										type="email"
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid alignContent="center" container justifyContent="space-between" spacing={2}>
										<Grid item>
											<InputLabel htmlFor={`${formId}-permission-beneficiaries`}>
												{t('permissions.readBeneficiary.label')}
											</InputLabel>
											<Typography color="brand.grey.500">
												{t('permissions.readBeneficiary.description')}
											</Typography>
										</Grid>
										<Grid item>
											<Checkbox
												defaultChecked={true}
												id={`${formId}-permission-beneficiaries`}
												name="permissionBeneficiaries"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Divider />
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
								};
							}}>
							<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
								<Grid item xs={12} md={true}>
									<Typography color="brand.grey.500" variant="caption">
										{t('formUserPermissions.helper')}
									</Typography>
								</Grid>
								<Grid item xs={12} md="auto">
									<LoadingButton
										fullWidth={true}
										loading={isLoading}
										type="submit"
										variant="contained">
										{t('setUserPermissions')}
									</LoadingButton>
								</Grid>
								{error && (
									<Grid item xs={12}>
										<Alert severity="error">{error}</Alert>
									</Grid>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
