// react
import { useContext } from 'react';

// context
import { ContextEntityPayments } from '../Entity.context';

// mui
import { Container, Grid } from '@mui/material';

// payments
import { TablePayments } from '../../../tables/cards/TablePayments/TablePayments';

export const Payments = () => {
	// context
	const { isLoadingPayments, paginationPayments, payments, refetchPayments } =
		useContext(ContextEntityPayments);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TablePayments
						isLoading={isLoadingPayments}
						pagination={paginationPayments}
						refetchTransactions={refetchPayments}
						transactions={payments}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
