// react
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

// context
import { ContextLoans, ContextProject } from '../../pages/Project/Project.context';

// types
import {
	Account,
	Contract,
	DocumentType,
	Entity,
	ErrorCause,
	ExpenseType,
	FilePayload,
	Loan,
	Option,
	PaymentPayload,
	TransactionType,
	TransactionMetadata,
	TransactionPayload,
} from '../../../@types';

// router
import { useParams, useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner, ContextUser } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { Amounts } from '../../fields/Amounts/Amounts';
import { FilePicker } from '../../fields/FilePicker/FilePicker';
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';
import { SelectCurrency } from '../../fields/SelectCurrency/SelectCurrency';

// ui
import { DocsTraceability } from '../../ui/DocsTraceability/DocsTraceability';

// utils
import { formatDate } from '../../../utils/dates';
import { toKebabCase } from '../../../utils/strings';

// props
export interface FormAddTransactionProps {
	onClose?: () => void;
	refetchTransactions?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	type?: TransactionType;
}

export const FormAddTransaction = ({
	onClose,
	setIsLoading,
	refetchTransactions,
	type = 'PAYMENT',
}: FormAddTransactionProps) => {
	// hooks
	const { idSupplier } = useParams();
	const { t } = useTranslation();

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [docTypesSelected, setDocTypesSelected] = useState<string[]>([]);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [entity, setEntity] = useState('');
	const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
	const [expenseType, setExpenseType] = useState('');
	const [error, setError] = useState<string | null>(null);
	const [files, setFiles] = useState<File[]>([]);
	const [fromAccount, setFromAccount] = useState<string | null>('');
	const [isContractSelected, setIsContractSelected] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const [supplier, setSupplier] = useState('');
	const [suppliers, setSuppliers] = useState<Entity[]>([]);

	// context
	const { loans } = useContext(ContextLoans);
	const { project, refetchProject } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// params
	const paramToEntity = searchParams.get('dialogToEntity');

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// vars
	const expenseTypeSelected = expenseTypes.find((ex) => String(ex.id) === expenseType);
	const docTypesForExpenseTypeSelected = expenseTypeSelected
		? (expenseTypeSelected?.documentTypes as DocumentType[])
		: [];
	const docTypesRequired = docTypesForExpenseTypeSelected.filter((docType) => docType.isRequired);
	const hasRequiredDocs = docTypesRequired.length > 0;
	const formId = 'form-add-payment';
	const fromAccountSelected = accounts.find((a) => String(a.id) === fromAccount);
	const fromAccountLoans: Loan[] = fromAccountSelected
		? fromAccountSelected.sources.filter((s) => s.loan).map((s) => s.loan as Loan)
		: [];
	const isCashBenefit = type === 'CASH_BENEFIT';
	const isOpEx = type === 'OPEX';
	const isPayment = type === 'PAYMENT';

	// check if from account has disabled loans
	let hasDisabledLoans = false;

	for (let i = 0; i < fromAccountLoans.length; i++) {
		if (fromAccountLoans[i]?.status === 'DISABLED') {
			hasDisabledLoans = true;
			break;
		}
	}

	// helper functions
	const getSupplierContracts = useCallback(
		(supplier: string) => {
			const url = `${process.env.REACT_APP_API_URL}/contracts?supplier=${supplier}&fields=id,num`;
			const asyncFetchSupplierContracts = async () => {
				const fetchSupplierContracts = await fetch(url, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});

				return await fetchSupplierContracts.json();
			};
			return asyncFetchSupplierContracts();
		},
		[accessToken, idToken]
	);

	// fetch expense types
	let paramEntity = 'null';

	if (user?.entity) {
		const userEntity = user.entity as Entity;
		paramEntity += `,${userEntity.id}`;
	}

	// fetch expense types
	useFetch({
		isEnabled: Boolean(project?.id && user),
		url: `${process.env.REACT_APP_API_URL}/expense-types?project=${project?.id}&entity=${paramEntity}&populate=documentTypes`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setExpenseTypes(res.data);
			}
		},
	});

	// fetch accounts
	useFetch({
		isEnabled: Boolean(!isOpEx && project?.id),
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&fields=id,currency,institution,name,sources,status&populate=sources.loan`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// fetch suppliers
	useFetch({
		isEnabled: Boolean(isPayment && project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=SUPPLIER`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSuppliers(res.data);
			}
		},
	});

	// fetch beneficiaries
	useFetch({
		isEnabled: Boolean(isCashBenefit && project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=BENEFICIARY`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiaries(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(isOpEx && project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,wallet&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU&populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// options
	const optionsAccounts = accounts.map((account) => {
		return {
			disabled: account.status === 'DEACTIVATED',
			label: account.name,
			secondary: account.institution,
			value: String(account.id),
		};
	});

	const optionsBeneficiaries = beneficiaries.map((beneficiary) => {
		return {
			label: beneficiary.name,
			value: beneficiary.id,
		};
	});

	let optionsDocTypes: Option[] = [];

	if (expenseTypeSelected) {
		optionsDocTypes = (expenseTypeSelected?.documentTypes as DocumentType[]).map((docType) => {
			return {
				label: docType.name,
				value: docType.id,
			};
		});
	}

	const optionsContracts = contracts.map((contract) => {
		return {
			label: contract.num,
			value: contract.id,
		};
	});

	const optionsExpenseTypes = expenseTypes.map((expenseType) => {
		return {
			label: expenseType.name,
			value: String(expenseType.id),
		};
	});

	const optionsSuppliers = suppliers.map((supplier) => {
		return {
			label: supplier.name,
			value: supplier.id,
		};
	});

	const optionsEntities = entities.map((entity) => {
		return {
			disabled: entity.wallet?.status === 'INACTIVE',
			label: entity.name,
			value: entity.id,
		};
	});

	const handleChangeEntity = async (e: SelectChangeEvent<unknown>) => {
		const url = `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&entity=${e.target.value}&fields=id,currency,institution,name,sources,status&populate=sources.loan`;
		const fetchEntityAccounts = await fetch(url, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		});

		const resEntityAccounts = await fetchEntityAccounts.json();

		// set entity
		setEntity(e.target.value as string);

		// reset account
		setFromAccount('');

		// set accounts
		if (resEntityAccounts.data) {
			setAccounts(resEntityAccounts.data);
		}
	};

	const handleChangeSupplier = async (e: SelectChangeEvent<unknown>) => {
		const supplier = e.target.value as string;
		const resSupplierContracts = await getSupplierContracts(supplier);

		// set supplier
		setSupplier(supplier);

		// set contracts
		if (resSupplierContracts.data) {
			setContracts(resSupplierContracts.data);
		}
	};

	const handleChangeAccountFrom = async (e: SelectChangeEvent<unknown>) => {
		setFromAccount(String(e.target.value));
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());
		const documents: FilePayload[] = [];

		// define documents using files
		if (files.length) {
			for (let i = 0; i < files.length; i++) {
				// add document
				documents.push({
					file: files[i],
					type: entries[`file-${i}-doc-type`] ? String(entries[`file-${i}-doc-type`]) : null,
				});

				// delete entry
				delete entries[`file-${i}-doc-type`];
			}
		}

		// define payments using sources
		const payments: PaymentPayload[] = [];
		let total = 0;

		if (fromAccountSelected) {
			for (let s = 0; s < fromAccountSelected.sources.length; s++) {
				const source = fromAccountSelected.sources[s];
				const amount = Number(entries[`source-${s}`]) || 0;

				total += amount;

				if (amount > 0) {
					payments.push({
						amount,
						currency: fromAccountSelected.currency,
						loan: source.loan ? (source.loan as Loan).id : null,
						source: source.source,
					});
				}
			}
		}

		const metadata: TransactionMetadata = {
			comments: entries.comments as string,
			uuid: crypto.randomUUID(),
		};

		if (entries.invoiceAmount) {
			metadata.invoiceAmount = Number(entries.invoiceAmount);
		}

		if (entries.invoiceCurrency) {
			metadata.invoiceCurrency = entries.invoiceCurrency as string;
		}

		if (entries.vendor) {
			metadata.vendor = entries.vendor as string;
		}

		// delete unused entries
		if (fromAccountSelected) {
			for (let i = 0; i < fromAccountSelected.sources.length; i++) {
				delete entries[`source-${i}`];
			}
		}

		delete entries.comments;
		delete entries.files;
		delete entries.invoiceAmount;
		delete entries.invoiceCurrency;
		delete entries.vendor;

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (!total || total === 0) {
				throw new Error('The payment amount needs to be greater than 0.', {
					cause: {
						id: 'TRANSACTION_AMOUNT_NON_EXISTENT',
					},
				});
			}

			if ((isPayment || isCashBenefit) && hasRequiredDocs && documents.length === 0) {
				throw new Error('At least one document needs to be uploaded', {
					cause: {
						id: 'TRANSACTION_DOCUMENTS_NON_EXISTENT',
					},
				});
			}

			const body: TransactionPayload = {
				...entries,
				contract: entries.contract ? Number(entries.contract) : null,
				date: formatDate({ date: new Date(entries.date as string), format: 'YYYY-MM-DD' }),
				expenseType: entries.expenseType ? Number(entries.expenseType) : null,
				fromAccount: Number(entries.fromAccount),
				fromEntity: entries.fromEntity ? Number(entries.fromEntity) : null,
				payments,
				metadata,
				project: project?.id,
				status: isOpEx || !hasRequiredDocs ? 'COMPLETED' : 'WAITING',
				toEntity: Number(idSupplier || paramToEntity || entries.toEntity),
				type,
			};

			const fetchAddTransaction = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resAddTransaction = await fetchAddTransaction.json();

			if (resAddTransaction.error) {
				throw new Error(resAddTransaction.error.message, {
					cause: {
						id: resAddTransaction.error.id,
					},
				});
			}

			const transaction = resAddTransaction.data;

			// for each document
			for (let i = 0; i < documents.length; i++) {
				// define doc
				const doc = documents[i];

				// define form data
				const formData = new FormData();

				const pathType = isOpEx
					? 'opex'
					: `payments/${toKebabCase(String(expenseTypeSelected?.name))}`;
				const dateToday = new Date();
				const year = dateToday.getFullYear();
				const month = new Intl.DateTimeFormat('en-US', {
					month: '2-digit',
				}).format(dateToday);

				// append form data
				formData.append(
					'path',
					`bldt/projects/${project?.id}/transactions/${pathType}/${transaction.id}/${year}/${month}/`
				);
				formData.append('file', doc.file);
				formData.append('transaction', String(transaction.id));

				if (project) {
					formData.append('project', String(project.id));
				}

				if (doc.type) {
					formData.append('type', doc.type);
				}

				// POST document
				const fetchPostDoc = await fetch(`${process.env.REACT_APP_API_URL}/documents`, {
					method: 'post',
					body: formData,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});

				const resPostDoc = await fetchPostDoc.json();

				if (resPostDoc.error) {
					throw new Error(resPostDoc.error.message, {
						cause: {
							id: resPostDoc.error.id,
						},
					});
				}
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch
			if (refetchTransactions) {
				refetchTransactions();
			}

			if (refetchProject) {
				refetchProject();
			}

			let message = t('alert.paymentRecorded');

			if (isCashBenefit) {
				message = t('alert.cashBenefitRecorded');
			}

			if (isOpEx) {
				message = t('alert.opExRecorded');
			}

			// set success banner
			setBanner({
				hasClose: true,
				message,
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'DOCUMENT_FILE_NON_EXISTENT') {
					message = t('error.documentFileNonExistent');
				}

				if (cause.id === 'DOCUMENT_INVALID_MIME_TYPE') {
					message = t('error.documentInvalidMimeType');
				}

				if (cause.id === 'DOCUMENT_TRANSACTION_NON_EXISTENT') {
					message = t('error.documentTransactionNonExistent');
				}

				if (cause.id === 'TRANSACTION_AMOUNT_NON_EXISTENT') {
					message = t('error.transactionAmountNonExistent');
				}

				if (cause.id === 'TRANSACTION_DOCUMENTS_NON_EXISTENT') {
					message = t('error.documentsNonExistent');
				}

				if (cause.id === 'TRANSACTION_INSUFFICIENT_FUNDS') {
					message = t('error.transactionInsufficientFunds');
				}

				if (cause.id === 'TRANSACTION_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}

				if (cause.id === 'WALLET_INACTIVE') {
					message = t('error.walletInactive');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	const docTypesChecked = [...new Set(docTypesSelected)];

	// set supplier and contracts if idSupplier or paramToEntity exist
	useEffect(() => {
		const id = idSupplier || paramToEntity;

		if (type === 'PAYMENT' && id) {
			// set supplier
			setSupplier(id);

			// set contracts
			const asyncSetContracts = async () => {
				const resSupplierContracts = await getSupplierContracts(id);

				if (resSupplierContracts.data) {
					setContracts(resSupplierContracts.data);
				}
			};
			asyncSetContracts();
		}
	}, [idSupplier, paramToEntity, getSupplierContracts, type]);

	return (
		<form id={formId} name="formAddTransaction" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Select
						id={`${formId}-expense-type`}
						label={t('expenseType')}
						name="expenseType"
						onChange={(e) => {
							setExpenseType(e.target.value as string);
							setDocTypesSelected([]);
						}}
						options={optionsExpenseTypes}
						required={true}
						value={expenseType}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						id={`${formId}-date`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('transactionDate')}
						name="date"
						required={true}
						type="date"
					/>
				</Grid>
				{isOpEx && (
					<>
						<Grid item xs={12}>
							<Input id={`${formId}-vendor`} label={t('vendor')} name="vendor" />
						</Grid>
						<Grid item xs={12} lg={6}>
							<Select
								helperText={
									fromAccount === ''
										? t('formAddTransaction.opex.sendingAccount.helper')
										: undefined
								}
								id={`${formId}-from-entity`}
								label={t('entity')}
								name="fromEntity"
								onChange={handleChangeEntity}
								options={optionsEntities}
								required={true}
								value={entity}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Select
								disabled={!entity || !optionsAccounts.length}
								id={`${formId}-account`}
								label={t('account')}
								name="fromAccount"
								onChange={handleChangeAccountFrom}
								options={optionsAccounts}
								required={true}
								value={fromAccount}
							/>
						</Grid>
					</>
				)}
				{isCashBenefit && !paramToEntity && (
					<Grid item xs={12}>
						<Select
							id={`${formId}-to-entity`}
							label={t('beneficiary')}
							name="toEntity"
							options={optionsBeneficiaries}
							required={true}
						/>
					</Grid>
				)}
				{isPayment && !idSupplier && !paramToEntity && (
					<Grid item xs={12}>
						<Select
							id={`${formId}-to-entity`}
							label={t('supplier')}
							name="toEntity"
							onChange={handleChangeSupplier}
							options={optionsSuppliers}
							required={true}
							value={supplier}
						/>
					</Grid>
				)}
				<>
					{isPayment && (
						<>
							<Grid item xs={12}>
								<Select
									disabled={supplier === ''}
									helperText={
										supplier === '' ? t('formAddTransaction.payment.contract.helper') : undefined
									}
									id={`${formId}-contract`}
									label={t('contract')}
									name="contract"
									options={optionsContracts}
									onChange={(e) => {
										setIsContractSelected(Boolean(e.target.value));
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Input
									id={`${formId}-invoice-amount`}
									label={t('invoiceAmount')}
									name="invoiceAmount"
									type="number"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectCurrency
									id={`${formId}-invoice-currency`}
									label={t('invoiceCurrency')}
									name="invoiceCurrency"
								/>
							</Grid>
						</>
					)}

					{!isOpEx && (
						<Grid item xs={12} lg={12}>
							<Select
								helperText={
									fromAccount === ''
										? t('formAddTransaction.payment.sendingAccount.helper')
										: undefined
								}
								id={`${formId}-account`}
								label={t('sendingAccount')}
								name="fromAccount"
								onChange={handleChangeAccountFrom}
								options={optionsAccounts}
								required={true}
								value={fromAccount}
							/>
						</Grid>
					)}
				</>

				{fromAccountSelected && fromAccountSelected.sources.length > 0 && (
					<>
						<Grid item xs={12}>
							<Amounts
								currency={fromAccountSelected.currency}
								formId={formId}
								hasCurrency={false}
								loans={loans}
								sources={fromAccountSelected.sources}
								title={t('financingSources')}
							/>
						</Grid>
						{hasDisabledLoans && (
							<Grid item xs={12}>
								<Alert severity="info">{t('alert.financingSourcesDisabled')}</Alert>
							</Grid>
						)}
					</>
				)}

				<Grid item xs={12}>
					<FilePicker
						onChange={(e, filesSelected) => {
							if (optionsDocTypes.length) {
								const defaultDocType = String(optionsDocTypes[0].value);

								if (!docTypesSelected.includes(defaultDocType)) {
									setDocTypesSelected([...docTypesSelected, defaultDocType]);
								}
							}

							setFiles(filesSelected);
						}}
						onChangeDocType={(e, i) => {
							// clone doc types selected
							const docTypesSelectedNew = [...docTypesSelected];

							// define new doc type
							const docTypeNew = String(e.target.value);

							// replace or add doc type
							if (docTypesSelectedNew[i]) {
								docTypesSelectedNew[i] = docTypeNew;
							} else {
								docTypesSelectedNew.push(docTypeNew);
							}

							setDocTypesSelected(docTypesSelectedNew);
						}}
						onRemove={(e, i) => {
							// clone doc types selected
							const docTypesSelectedNew = [...docTypesSelected];

							// remove selected doc by index
							docTypesSelectedNew.splice(i, 1);

							setDocTypesSelected(docTypesSelectedNew);
						}}
						optionsDocTypes={optionsDocTypes}
						required={!isOpEx}
					/>
				</Grid>
				{!isOpEx && expenseType && (
					<Grid item xs={12}>
						<DocsTraceability
							docTypes={docTypesForExpenseTypeSelected}
							docTypesChecked={docTypesChecked}
						/>
					</Grid>
				)}

				<Grid item xs={12}>
					<Input
						id={`${formId}-comments`}
						label={t('comments')}
						minRows={2}
						multiline={true}
						name="comments"
					/>
				</Grid>

				{isPayment && !isContractSelected && (
					<Grid item xs={12}>
						<Alert severity="warning">{t('alert.selectContract')}</Alert>
					</Grid>
				)}

				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
