// react
import { useContext, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Project, User } from '../../../../../@types';

// context
import { ContextProject } from '../../Project.context';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// mui
import { Container, Grid, Typography } from '@mui/material';

// pages
import { CardDetails } from './CardDetails/CardDetails';

// cards
import { CardUsers } from '../../../../cards/CardUsers/CardUsers';
import { CardTraceability } from '../../../../cards/CardTraceability/CardTraceability';

// props
export interface DetailsProps {
	project: Project;
}

export const Details = ({ project }: DetailsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { isOnboarded } = useContext(ContextProject);

	// defaults
	let usersDefault: User[] = [];

	if (!isOnboarded && project.users.length) {
		usersDefault = project.users.filter((u) => u.role === 'TTL');
	}

	// state
	const [users, setUsers] = useState<User[]>(usersDefault);

	// fetch TTL users
	const { isLoading: isLoadingUsers } = useFetch({
		isEnabled: Boolean(isOnboarded && project),
		url: `${process.env.REACT_APP_API_URL}/users?project=${project.id}&role=TTL`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}
		},
	});

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('overview')}</Typography>
				</Grid>

				<Grid item xs={12} lg={6}>
					<CardDetails project={project} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<CardUsers isLoading={isLoadingUsers} title={t('taskTeamLeader')} users={users} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<CardTraceability traceability={project.traceability} />
				</Grid>
			</Grid>
		</Container>
	);
};
