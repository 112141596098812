// i18n
import { TFunction } from 'i18next';

export const getOptionsEntityTypes = (t: TFunction) => {
	return [
		{
			label: t('borrower'),
			value: 'BORROWER',
		},
		{
			label: t('intermediary'),
			value: 'INTERMEDIARY',
		},
		{
			label: t('piu'),
			value: 'PIU',
		},
		{
			label: t('subPiu'),
			value: 'SUB_PIU',
		},
	];
};
