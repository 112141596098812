// react
import { SyntheticEvent, useContext, useEffect, useState } from 'react';

// types
import { ActionButton, Entity, Loan, Pagination, Transaction, User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import {
	Link as RouterLink,
	Outlet,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// context
import {
	ContextEntityBeneficiaries,
	ContextEntityPayments,
	ContextEntitySuppliers,
	ContextEntityUsers,
} from './Entity.context';
import { ContextProject } from '../Project/Project.context';
import { ContextUser } from '../../../App.context';

// mui
import { Grid } from '@mui/material';

// pages
import { Details } from './Details/Details';

// dialogs
import { DialogAddEntity } from '../../dialogs/DialogAddEntity/DialogAddEntity';
import { DialogAddUser } from '../../dialogs/DialogAddUser/DialogAddUser';

// ui
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';
import { Empty } from '../../ui/Empty/Empty';
import { Loading } from '../../ui/Loading/Loading';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Title } from '../../ui/Title/Title';

// defaults
import { defaultPagination } from '../../../utils/defaults';

export const PageEntity = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const location = useLocation();
	const { id, idEntity } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	// params
	const paramContract = searchParams.get('contract');
	const paramDialog = searchParams.get('dialog');
	const paramExpenseType = searchParams.get('expenseType');
	const paramLoan = searchParams.get('loan');
	const paramPage = Number(searchParams.get('page') || 1);
	const paramQ = searchParams.get('q');
	const paramStatus = searchParams.get('status');
	const paramSupplier = searchParams.get('supplier');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// state
	const [loans, setLoans] = useState<Loan[]>([]);
	const [entity, setEntity] = useState<Entity | null>(null);
	const [paginationBeneficiaries, setPaginationBeneficiaries] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [paginationPayments, setPaginationPayments] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [paginationSuppliers, setPaginationSuppliers] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [paginationUsers, setPaginationUsers] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
	const [payments, setPayments] = useState<Transaction[]>([]);
	const [suppliers, setSuppliers] = useState<Entity[]>([]);
	const [tabValue, setTabValue] = useState('payments');
	const [users, setUsers] = useState<User[]>([]);

	// fetch entity
	const { fetchRequest: fetchEntity, isLoading: isLoadingEntity } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/entities/${idEntity}?populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntity(res.data[0]);
			}
		},
	});

	// fetch entity loans
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&entity=${idEntity}&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res) {
				setLoans(res.data);
			}
		},
	});

	// fetch entity suppliers
	const {
		fetchRequest: fetchSuppliers,
		isLoading: isLoadingSuppliers,
		refetch: refetchSuppliers,
	} = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&parent=${idEntity}&type=SUPPLIER`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSuppliers(res.data);
			}

			if (res.pagination) {
				setPaginationSuppliers(res.pagination);
			}
		},
	});

	// fetch entity beneficiaries
	const {
		fetchRequest: fetchBeneficiaries,
		isLoading: isLoadingBeneficiaries,
		refetch: refetchBeneficiaries,
	} = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&parent=${idEntity}&type=BENEFICIARY`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiaries(res.data);
			}

			if (res.pagination) {
				setPaginationBeneficiaries(res.pagination);
			}
		},
	});

	// fetch entity payments
	let fetchPaymentsUrl = `${process.env.REACT_APP_API_URL}/transactions?project=${project?.id}&fromEntity=${idEntity}&type=PAYMENT&page=${paramPage}&limit=${paginationUsers.perPage}&populate=contract,expenseType,fromAccount,payments.loan,toEntity`;

	if (paramExpenseType) {
		fetchPaymentsUrl += `&expenseType=${paramExpenseType}`;
	}

	if (paramContract) {
		fetchPaymentsUrl += `&contract=${paramContract}`;
	}

	if (paramSupplier) {
		fetchPaymentsUrl += `&supplier=${paramSupplier}`;
	}

	if (paramStatus) {
		fetchPaymentsUrl += `&status=${paramStatus}`;
	}

	if (paramLoan) {
		fetchPaymentsUrl += `&loan=${paramLoan}`;
	}

	if (paramQ) {
		fetchPaymentsUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchPayments,
		isLoading: isLoadingPayments,
		refetch: refetchPayments,
	} = useFetch({
		url: fetchPaymentsUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setPayments(res.data);
			}

			if (res.pagination) {
				setPaginationPayments(res.pagination);
			}
		},
	});

	// fetch entity users
	let fetchUsersUrl = `${process.env.REACT_APP_API_URL}/users?entity=${idEntity}&page=${paramPage}&limit=${paginationUsers.perPage}`;

	if (paramQ) {
		fetchUsersUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchUsers,
		isLoading: isLoadingUsers,
		refetch: refetchUsers,
	} = useFetch({
		isLazy: true,
		url: fetchUsersUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}

			if (res.pagination) {
				setPaginationUsers(res.pagination);
			}
		},
	});

	const tabs = [
		{
			component: RouterLink,
			label: t('payments'),
			to: `/project/${id}/entity/${idEntity}/payments`,
			value: 'payments',
		},
		{
			component: RouterLink,
			label: t('users'),
			to: `/project/${id}/entity/${idEntity}/users`,
			value: 'users',
		},
		{
			component: RouterLink,
			label: t('suppliers'),
			to: `/project/${id}/entity/${idEntity}/suppliers`,
			value: 'suppliers',
		},
		{
			component: RouterLink,
			label: t('beneficiaries'),
			to: `/project/${id}/entity/${idEntity}/beneficiaries`,
			value: 'beneficiaries',
		},
	];

	// title actions
	const titleActions: ActionButton[] = [];
	if (tabValue === 'users' && user?.permissions?.user?.create) {
		titleActions.push({
			children: t('addUser'),
			onClick: () => {
				searchParams.set('dialog', 'addUser');
				setSearchParams(searchParams);
			},
			variant: 'contained',
		});
	}
	if (tabValue === 'suppliers' && user?.permissions?.entity?.create) {
		titleActions.push({
			children: t('addSupplier'),
			onClick: () => {
				searchParams.set('dialog', 'addSupplier');
				setSearchParams(searchParams);
			},
			variant: 'contained',
		});
	}

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	let template;

	if (isLoadingEntity) {
		template = <Loading variant="page" />;
	} else {
		if (entity) {
			template = (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Breadcrumbs
								crumbs={[
									{
										children: t('projects'),
										to: '/projects',
									},
									{
										children: project?.idWbg,
										to: `/project/${id}`,
									},
									{
										children: t('entities'),
										to: `/project/${id}/entities`,
									},
								]}
								page={entity.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Title
								actions={titleActions}
								color="magenta"
								overline={t('entity')}
								title={entity.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Details loans={loans} entity={entity} />
						</Grid>
						<Grid item xs={12}>
							<Tabs onChange={handleChangeTab} tabs={tabs} value={tabValue} hasBorder={false} />
						</Grid>
						<Grid item xs={12}>
							<Outlet />
						</Grid>
					</Grid>
					<DialogAddUser
						entity={entity}
						isOpen={paramDialog === 'addUser'}
						onClose={() => {
							searchParams.delete('dialog');
							setSearchParams(searchParams);
						}}
						refetchUsers={refetchUsers}
					/>
					<DialogAddEntity
						isOpen={paramDialog === 'addSupplier'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								return params;
							});
						}}
						onSuccess={() => {
							refetchSuppliers();
						}}
						type="SUPPLIER"
					/>
				</>
			);
		} else {
			template = (
				<Empty
					actions={[
						{
							children: t('entities'),
							component: RouterLink,
							to: `/project/${project?.id}/entities`,
							variant: 'contained',
						},
					]}
					content={{ children: t('empty.entity.content') }}
					title={{ children: t('empty.entity.title') }}
					IconCircleProps={{ type: 'entity' }}
					variant="page"
				/>
			);
		}
	}

	// fetch entity when idEntity changes
	useEffect(() => {
		fetchEntity();
	}, [fetchEntity, idEntity]);

	// fetch users when page changes
	useEffect(() => {
		if (tabValue === 'payments') {
			fetchPayments();
		} else if (tabValue === 'suppliers') {
			fetchSuppliers();
		} else if (tabValue === 'users') {
			fetchUsers();
		} else if (tabValue === 'beneficiaries') {
			fetchBeneficiaries();
		}
	}, [
		fetchBeneficiaries,
		fetchPayments,
		fetchSuppliers,
		fetchUsers,
		paramContract,
		paramExpenseType,
		paramLoan,
		paramPage,
		paramQ,
		paramStatus,
		paramSupplier,
		tabValue,
	]);

	// set active tab
	useEffect(() => {
		let tabValueNew = 'payments';

		if (location.pathname.includes('suppliers')) {
			tabValueNew = 'suppliers';
		} else if (location.pathname.includes('users')) {
			tabValueNew = 'users';
		} else if (location.pathname.includes('beneficiaries')) {
			tabValueNew = 'beneficiaries';
		}
		setTabValue(tabValueNew);
	}, [location]);

	return (
		<ContextEntityPayments.Provider
			value={{
				isLoadingPayments,
				paginationPayments,
				payments,
				refetchPayments,
				setPayments,
				setPaginationPayments,
			}}>
			<ContextEntitySuppliers.Provider
				value={{
					isLoadingSuppliers,
					paginationSuppliers,
					refetchSuppliers,
					setSuppliers,
					setPaginationSuppliers,
					suppliers,
				}}>
				<ContextEntityUsers.Provider
					value={{
						isLoadingUsers,
						paginationUsers,
						refetchUsers,
						setUsers,
						setPaginationUsers,
						users,
					}}>
					<ContextEntityBeneficiaries.Provider
						value={{
							isLoadingBeneficiaries,
							paginationBeneficiaries,
							refetchBeneficiaries,
							setBeneficiaries,
							setPaginationBeneficiaries,
							beneficiaries,
						}}>
						{template}
					</ContextEntityBeneficiaries.Provider>
				</ContextEntityUsers.Provider>
			</ContextEntitySuppliers.Provider>
		</ContextEntityPayments.Provider>
	);
};
