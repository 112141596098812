// react
import { ChangeEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';

// mui
import { Box, Grid, InputLabel, SelectChangeEvent, Typography } from '@mui/material';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// i18n
import { useTranslation } from 'react-i18next';

// fields
import { Input, InputProps } from '../Input/Input';

// theme
import { theme } from '../../../theme';

// utils
import { constructAmountInputs } from './Amounts.utils';
import { formatCurrency } from '../../../utils/currency';
import { SelectCurrency } from '../SelectCurrency/SelectCurrency';

// types
import { AccountSource, Loan } from '../../../@types';
import { SxProps } from '@mui/system';

// styling
const sxStyles = {
	boxContainer: { border: `1px ${theme.palette.divider} solid`, borderRadius: '5px' },
	boxRow: {
		padding: theme.spacing(2),
		borderBottom: `1px ${theme.palette.divider} solid`,
		minWidth: '100%',
	},
};

// props
export interface AmountsSummary {
	operator?: '+' | '-' | '*';
	title: string;
	value: number;
}

export interface AmountsProps {
	currency?: string;
	formId: string;
	hasCurrency?: boolean;
	inputs?: AmountsInputProps[];
	isRequired?: boolean;
	loans?: Loan[];
	sources?: AccountSource[];
	summaries?: AmountsSummary[];
	title: string;
	titleTotal?: string;
}
export type AmountsInputProps = {
	icon: ReactNode | null;
} & InputProps;

export const Amounts = ({
	currency: defaultCurrency = 'USD',
	formId,
	hasCurrency = true,
	inputs,
	isRequired = true,
	loans,
	sources,
	summaries = [],
	title,
	titleTotal,
}: AmountsProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [currency, setCurrency] = useState<string>(defaultCurrency);
	const [total, setTotal] = useState<number>(0);

	// vars
	let amountsInputs: AmountsInputProps[] = [];

	if (inputs) {
		amountsInputs = inputs;
	} else if (loans && sources) {
		amountsInputs = constructAmountInputs({ sources, loans, t });
	}

	const summaryLineItems: AmountsSummary[] = [
		{
			title: titleTotal || t('totalAmount'),
			value: total,
		},
	].concat(summaries);

	// handlers
	const handleChangeSourceValue = (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		const totalBalance = amountsInputs.reduce(
			(total: number, input: AmountsInputProps, i: number) =>
				total + (Number(entries[`source-${i}`]) || 0),
			0
		);

		setTotal(totalBalance);
	};

	// clear inputs when sources change
	useEffect(() => {
		if (sources) {
			for (let i = 0; i < sources.length; i++) {
				const input = document.getElementsByName(`source-${i}`)[0] as HTMLInputElement;
				input.value = '';
			}
			setTotal(0);
		}
	}, [sources]);

	// update currency when defaultCurrency changes
	useEffect(() => {
		setCurrency(defaultCurrency);
	}, [defaultCurrency]);

	return (
		<>
			<InputLabel required={isRequired}>{title}</InputLabel>
			<Box sx={sxStyles.boxContainer}>
				{hasCurrency && (
					<Box sx={sxStyles.boxRow}>
						<Grid container alignItems={'center'}>
							<Grid item xs={8}>
								<Typography fontWeight={600}>{t('financingSource')}</Typography>
							</Grid>

							<Grid item xs={4}>
								<SelectCurrency
									defaultValue={'USD'}
									hasLabel={false}
									id={`${formId}-currency`}
									name="currency"
									onChange={(e: SelectChangeEvent<unknown>) => {
										setCurrency(e.target.value as string);
									}}
									required={true}
								/>
							</Grid>
						</Grid>
					</Box>
				)}
				<Grid container>
					{amountsInputs.map((input: AmountsInputProps, i: number) => {
						return (
							<Box key={`source=${i}`} sx={sxStyles.boxRow}>
								<Grid alignItems="center" container justifyContent="space-between">
									<Grid item xs="auto">
										<Grid alignItems="center" container spacing={2}>
											<Grid item xs="auto">
												<IconCircle icon={input.icon} type={input.type} />
											</Grid>
											<Grid item xs="auto">
												<InputLabel
													disabled={input.disabled}
													htmlFor={`${formId}-source-${i}`}
													sx={{ margin: 0 }}>
													{input.label}
												</InputLabel>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={6}>
										<Input
											currency={currency}
											disabled={input.disabled}
											id={`${formId}-source-${i}`}
											name={`source-${i}`}
											onWheel={(e: MouseEvent) => {
												const target = e.target as HTMLElement;
												return target.blur();
											}}
											onChange={handleChangeSourceValue}
											type="number"
										/>
									</Grid>
								</Grid>
							</Box>
						);
					})}
					{summaryLineItems.map((summary, i) => {
						const sxBox: SxProps = { ...sxStyles.boxRow };
						const isLast = i === summaryLineItems.length - 1;

						let value = summary.value;
						let color = `brand.grey.500`;

						if (summary.operator) {
							if (summary.operator === '+') {
								value = value + total;
							} else if (summary.operator === '-') {
								value = value - total;
							} else if (summary.operator === '*') {
								value = value * total;
							}
						}

						if (isLast) {
							sxBox.border = 'none';
							color = `text.primary`;
						}

						return (
							<Box key={`summary-${i}-${summary.value}`} sx={sxBox}>
								<Grid container alignItems={'center'}>
									<Grid item xs={6}>
										<Typography color={color} fontWeight={600}>
											{summary.title}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography color={color} fontWeight={600}>
											{formatCurrency({
												currency,
												value: value,
											})}{' '}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};
