// react
import { useState } from 'react';

// types
import { ExportResource } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormExport } from '../../forms/FormExport/FormExport';

// props
export interface DialogExportProps {
	isOpen: boolean;
	onClose: () => void;
	resource: ExportResource;
}

export const DialogExport = ({ isOpen, onClose, resource }: DialogExportProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('downloadCsv'),
					loading: isLoading,
					form: 'form-export',
					type: 'submit',
					variant: 'contained',
				},
			]}
			hasClose={true}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: t('export'),
				variant: 'h3',
			}}>
			<FormExport onClose={onClose} resource={resource} setIsLoading={setIsLoading} />
		</DialogActions>
	);
};
