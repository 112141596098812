// router
import { LinkProps as RouterLinkProps } from 'react-router-dom';

// mui
import {
	Button,
	ButtonProps,
	Chip,
	ChipProps,
	Grid,
	Paper,
	PaperProps as MuiPaperProps,
	SxProps,
	Typography,
	TypographyProps,
} from '@mui/material';

// icons
import { ArrowForward as IconArrowForward } from '@mui/icons-material';

// ui
import { IconCircle, IconCircleProps as UiIconCircleProps } from '../../ui/IconCircle/IconCircle';

// theme
import { theme } from '../../../theme';

// props
export type CardActionButtonProps = ButtonProps & {
	to?: RouterLinkProps['to'];
};

export type CardActionProps = {
	action?: TypographyProps;
	ButtonProps?: CardActionButtonProps;
	ChipProps?: ChipProps | null;
	content?: TypographyProps;
	hasBreakLine?: boolean;
	IconCircleProps: UiIconCircleProps;
	PaperProps?: MuiPaperProps;
	title?: TypographyProps;
};

export const CardAction = ({
	action,
	ButtonProps = {},
	ChipProps,
	content,
	hasBreakLine = true,
	IconCircleProps = { size: 'sm' },
	PaperProps,
	title,
}: CardActionProps) => {
	// vars
	const labelArray =
		title?.children && typeof title?.children === 'string' ? title.children.split(' ') : [];
	const firstWord = labelArray.shift();

	let colorAction = theme.palette.brand.blue[500];
	let colorTitle: string | undefined = theme.palette.common.black;

	if (ButtonProps.disabled) {
		colorAction = theme.palette.brand.grey[400];
		colorTitle = theme.palette.brand.grey[500];
	}

	return (
		<Paper
			sx={(theme) => {
				const sx: SxProps = {
					minHeight: '90px',
					height: '100%',
				};

				return sx;
			}}
			{...PaperProps}>
			<Button
				color="primary"
				fullWidth={true}
				variant="outlined"
				{...ButtonProps}
				sx={{
					height: '100%',
					padding: theme.spacing(2),
					color: 'black',
					'&.Mui-disabled': {
						border: 'none',
					},
					...ButtonProps?.sx,
				}}>
				<Grid container height="100%">
					<Grid item xs={12}>
						<Grid container justifyContent="space-between" spacing={2}>
							<Grid item xs={true} lg={12}>
								<Grid container columnSpacing={1} rowSpacing={2}>
									<Grid item xs="auto">
										<IconCircle {...IconCircleProps} />
									</Grid>
									<Grid item xs={true}>
										<Typography color={colorTitle} fontWeight={600} align="left" {...title}>
											{hasBreakLine ? (
												<>
													{firstWord}
													<br />
													{labelArray.join(' ')}
												</>
											) : (
												title?.children
											)}
										</Typography>
									</Grid>
									{content && (
										<Grid item xs={12}>
											<Typography align="left" color="brand.grey.500" {...content} />
										</Grid>
									)}
								</Grid>
							</Grid>
							{ChipProps && (
								<Grid item xs="auto">
									<Chip {...ChipProps} />
								</Grid>
							)}
						</Grid>
					</Grid>

					<Grid alignSelf="flex-end" item xs={12}>
						<Grid alignItems="center" container justifyContent="flex-end" spacing={1}>
							{action && (
								<Grid item>
									<Typography color={colorAction} component="span" fontWeight={600} {...action} />
								</Grid>
							)}

							<Grid item>
								<IconArrowForward sx={{ color: colorAction }} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Button>
		</Paper>
	);
};
