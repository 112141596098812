// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// fields
import { Select } from '../Select/Select';
import { SelectProps as MuiSelectProps } from '@mui/material';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// types
import { Option } from '../../../@types';

// utils
import { defaultCurrencies } from '../../../utils/defaults';

export type SelectCurrencyProps = {
	hasLabel?: boolean;
} & MuiSelectProps;

export const SelectCurrency = ({ hasLabel = true, ...props }: SelectCurrencyProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [currencies, setCurrencies] = useState<string[]>(defaultCurrencies);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	useFetch({
		url: `${process.env.REACT_APP_API_URL}/currencies`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data.length) {
				setCurrencies(res.data);
			}
		},
	});

	const optionsCurrency: Option[] = currencies.map((currency) => {
		return {
			label: currency,
			value: currency,
		};
	});

	return (
		<Select
			aria-label={hasLabel ? undefined : t('currency')}
			label={hasLabel ? props.label || t('currency') : null}
			{...props}
			options={optionsCurrency}
		/>
	);
};
